import { RefObject, useEffect, useRef, useState } from "react";
import styles from "./SparkChart.module.scss";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Chart } from "highcharts";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";
import { negativeState, positiveState } from "@/lib/store";
import { useRecoilValue } from "recoil";

type Props = {
    data: Array<Array<number>>;
    triggerLevel?: number;
};

const SparkChart = ({ data, triggerLevel }: Props) => {
    const negativeColor = useRecoilValue(negativeState);
    const positiveColor = useRecoilValue(positiveState);

    const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement> }>(null);

    const [options, setOptions] = useState({
        chart: {
            borderWidth: 0,
            margin: 0,
            backgroundColor: "transparent",
        },
        accessibility: {
            enabled: true,
        },
        xAxis: {
            type: "datetime",
            labels: { enabled: false },
        },
        yAxis: {
            visible: false,
            labels: {
                formatter: function () {
                    return Number(this.value).toFixed(2);
                },
            },
        },
        series: [
            {
                data: [],
                name: "Price",
                lineWidth: 2,
                shadow: false,
                type: "spline",
                color: positiveColor,
                animation: false,
                showInLegend: false,
                marker: {
                    radius: 0,
                    animation: false,
                    lineColor: "transparent",
                    states: { hover: { radius: 4 } },
                },
                states: {
                    hover: {
                        halo: { size: 0 },
                        lineWidthPlus: 0,
                    },
                },
            },
            {
                data: [],
                name: "Trigger Level",
                type: "line",
                color: negativeColor,
            },
        ],
        tooltip: {
            padding: 4,
            hideDelay: 0,
            shadow: false,
            outside: true,
            borderWidth: 0,
            borderRadius: 0,
            animation: false,
            headerFormat: "",
            pointFormat: "<span style='font-size: 11px'>{point.x:%A, %b %e, %Y %H:%M}</span><br /><b>{series.name}: </b>{point.y:.2f}",
        },
        plotOptions: {
            series: {
                shadow: false,
                animation: false,
                marker: { animation: false },
            },
        },
        title: { text: "" },
        credits: { enabled: false },
        legend: { enabled: false },
        exporting: { enabled: false },
        navigation: {
            // Disable navigation buttons
            buttonOptions: {
                enabled: false,
            },
        },
    });

    useEffect(() => {
        const series = chartRef.current?.chart?.series[0];
        const filteredData = data.filter((point) => point[0] >= dayjs().subtract(24, "hour").valueOf());

        if (series?.data?.length === 0) {
            series?.setData(filteredData, false);
            chartRef.current?.chart?.redraw();
        } else {
            series?.addPoint(filteredData[0], true, true);
        }

        const triggerSeries = chartRef.current?.chart?.series[1];

        if (triggerLevel !== 0 && triggerSeries?.data?.length === 0) {
            triggerSeries.setData(
                filteredData.map((point) => [point[0], triggerLevel]),
                true
            );
        }
    }, [data, triggerLevel]);

    return (
        <div className={styles.SparkChart} style={{ height: "100%", width: "100%" }}>
            {!data?.length ? <p className={styles["no-chart"]}>N/A</p> : <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} ref={chartRef} />}
        </div>
    );
};

export default SparkChart;
