import { Button, CircularProgress, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { textColorState } from "@/lib/store";
import { useRecoilValue } from "recoil";
import { ModuleContext } from "@/lib/Context";
import { FilterState } from "@/models/datagrid";

interface PropsTypes {
    apiParamsValues?: any[];
    setParams?: any;
    setCustomFiltersModel?: any;
    cleared: boolean;
    setCleared: (cleared: boolean) => void;
    setChips?: (chips: Array<any>) => void;
    fetcher?: any;
    clear?: { setter: any; defaultValue: any };
    setFiltersState?: React.Dispatch<React.SetStateAction<FilterState>>;
    filtersState?: FilterState;
}

const ClearButton: FC<PropsTypes> = ({ apiParamsValues = [], setParams, setChips, cleared, setCleared, fetcher, setCustomFiltersModel, clear, setFiltersState, filtersState }) => {
    const textColor = useRecoilValue(textColorState);
    const [disabled, setDisabled] = useState(false);
    const { setFilterAmount } = useContext(ModuleContext);

    const handleClear = () => {
        if (clear !== undefined) {
            const { setter, defaultValue } = clear;
            if (Array.isArray(setter)) setter.forEach((set, i) => set(defaultValue[i]));
            else setter(defaultValue);
        }
        setChips && setChips([]);
        setParams({ customFilterModel: { items: [] } });
        setCustomFiltersModel && setCustomFiltersModel({ items: [] });
        setCleared(true);
        setFiltersState && setFiltersState((prev) => ({ previousState: prev.currentState, currentState: "cleared" }));
        setFilterAmount && setFilterAmount(0);

        fetcher(1, undefined, {});
    };

    // useEffect(() => {
    //     if (cleared && apiParamsValues.length === 0) {
    //         setCleared(true);
    //         setDisabled(true);
    //     } else {
    //         setDisabled(false);
    //         setCleared(false);
    //     }
    // }, [apiParamsValues]);

    return (
        <Button
            size="small"
            sx={{
                mr: "auto",
                color: textColor,
                mt: 1,
                "&:disabled": {
                    color: "grey",
                },
            }}
            disabled={!apiParamsValues.length}
            onClick={handleClear}
            endIcon={<ClearIcon color={!apiParamsValues.length ? "inherit" : "error"} />}
        >
            <Typography variant="overline">Clear</Typography>
        </Button>
    );
};

export default ClearButton;
