import { Chart, HTMLDOMElement } from "highcharts";

export function pInt(s: any, mag?: number): number {
    return parseInt(s, mag || 10);
}

export const isNumber = (n: unknown) => typeof n === "number" && !isNaN(n) && n < Infinity && n > -Infinity;

function isObject<T>(obj: T, strict?: boolean): obj is object & NonFunction<NonNullable<T>> {
    return (!!obj && typeof obj === "object" && (!strict || !isArray(obj))) as any;
}

function isClass(obj: object | undefined): obj is Utilities.Class<any> {
    const c: Function | undefined = obj && obj.constructor;
    return !!(isObject(obj, true) && !isDOMElement(obj) && c && (c as any).name && (c as any).name !== "Object");
}

function isDOMElement(obj: unknown): obj is HTMLDOMElement {
    return isObject(obj) && typeof (obj as any).nodeType === "number";
}

function isArray(obj: unknown): obj is Array<unknown> {
    const str = Object.prototype.toString.call(obj);
    return str === "[object Array]" || str === "[object Array Iterator]";
}

export function merge<T = object>(extend: true, a?: T, ...n: Array<DeepPartial<T> | undefined>): T;
export function merge<T1 extends object = object, T2 = unknown, T3 = unknown, T4 = unknown, T5 = unknown, T6 = unknown, T7 = unknown, T8 = unknown, T9 = unknown>(
    a?: T1,
    b?: T2,
    c?: T3,
    d?: T4,
    e?: T5,
    f?: T6,
    g?: T7,
    h?: T8,
    i?: T9
): T1 & T2 & T3 & T4 & T5 & T6 & T7 & T8 & T9;

/* eslint-disable valid-jsdoc */
/**
 * Utility function to deep merge two or more objects and return a third object.
 * If the first argument is true, the contents of the second object is copied
 * into the first object. The merge function can also be used with a single
 * object argument to create a deep copy of an object.
 *
 * @function Highcharts.merge<T>
 *
 * @param {boolean} extend
 *        Whether to extend the left-side object (a) or return a whole new
 *        object.
 *
 * @param {T|undefined} a
 *        The first object to extend. When only this is given, the function
 *        returns a deep copy.
 *
 * @param {...Array<object|undefined>} [n]
 *        An object to merge into the previous one.
 *
 * @return {T}
 *         The merged object. If the first argument is true, the return is the
 *         same as the second argument.
 */ /**
 * Utility function to deep merge two or more objects and return a third object.
 * The merge function can also be used with a single object argument to create a
 * deep copy of an object.
 *
 * @function Highcharts.merge<T>
 *
 * @param {T|undefined} a
 *        The first object to extend. When only this is given, the function
 *        returns a deep copy.
 *
 * @param {...Array<object|undefined>} [n]
 *        An object to merge into the previous one.
 *
 * @return {T}
 *         The merged object. If the first argument is true, the return is the
 *         same as the second argument.
 */
export function merge<T>(): T {
    /* eslint-enable valid-jsdoc */
    let i: number,
        args = arguments,
        ret = {} as T;
    const doCopy = function (copy: any, original: any): any {
        // An object is replacing a primitive
        if (typeof copy !== "object") {
            copy = {};
        }

        objectEach(original, function (value, key): void {
            // Prototype pollution (#14883)
            if (key === "__proto__" || key === "constructor") {
                return;
            }

            // Copy the contents of objects, but not arrays or DOM nodes
            if (isObject(value, true) && !isClass(value) && !isDOMElement(value)) {
                copy[key] = doCopy(copy[key] || {}, value);

                // Primitives and arrays are copied over directly
            } else {
                copy[key] = original[key];
            }
        });
        return copy;
    };

    // If first argument is true, copy into the existing object. Used in
    // setOptions.
    if (args[0] === true) {
        ret = args[1];
        args = Array.prototype.slice.call(args, 2) as any;
    }

    // For each argument, extend the return
    const len = args.length;
    for (i = 0; i < len; i++) {
        ret = doCopy(ret, args[i]);
    }

    return ret;
}

function objectEach<TObject, TContext>(obj: TObject, fn: Utilities.ObjectEachCallback<TObject, TContext>, ctx?: TContext): void {
    /* eslint-enable valid-jsdoc */
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            fn.call(ctx || (obj[key] as unknown as TContext), obj[key], key, obj);
        }
    }
}

type NonFunction<T> = T extends Function ? never : T;

type DeepPartial<T> = {
    [P in keyof T]?: T[P] | DeepPartial<T[P]>;
};

namespace Utilities {
    export type RelativeSize = number | string;
    export interface Class<T = any> extends Function {
        new (...args: Array<any>): T;
    }
    export interface ErrorMessageEventObject {
        chart?: Chart;
        code: number;
        message?: string;
        params?: Record<string, string>;
    }
    export interface EventOptions {
        order?: number;
        passive?: boolean;
    }
    export interface EventWrapperObject<T> {
        fn: EventCallback<T>;
        order: number;
    }
    export interface FindCallback<T> {
        (value: T, index: number): unknown;
    }
    export interface ObjectEachCallback<TObject, TContext> {
        (this: TContext, value: TObject[keyof TObject], key: keyof TObject, obj: TObject): void;
    }
    export interface OffsetObject {
        height: number;
        left: number;
        top: number;
        width: number;
    }
    export interface WrapProceedFunction {
        (...args: Array<any>): any;
    }
}
