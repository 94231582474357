import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { codeAppliedState, planState, subscriptionState, themeState, userDataState } from "@/lib/store";
import PaymentStripeForm from "@/components/payment-stripe-form/PaymentStripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { makePayment } from "@/controllers/subscription";
import { useDebounce, useUpdateEffect } from "react-use";

interface PaymentModalStripeProps {
    payment: {};
    setStep: Dispatch<SetStateAction<number>>;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    firstNameInvalid: boolean;
    setFirstNameInvalid: Dispatch<SetStateAction<boolean>>;
    lastNameInvalid: boolean;
    setLastNameInvalid: Dispatch<SetStateAction<boolean>>;
}

const PaymentStripe: FC<PaymentModalStripeProps> = ({
                                                        payment,
                                                        setStep,
                                                        disabled,
                                                        setDisabled,
                                                        firstNameInvalid,
                                                        setFirstNameInvalid,
                                                        lastNameInvalid,
                                                        setLastNameInvalid,
                                                    }) => {
    const theme = useRecoilValue(themeState);
    const [clientSecret, setClientSecret] = useState<string>();
    const plan = useRecoilValue(planState);
    const subscription = useRecoilValue(subscriptionState);
    const codeApplied = useRecoilValue(codeAppliedState);
    const userData = useRecoilValue(userDataState);

    const stripe = useMemo(() => loadStripe(subscription.payment_data.stripe_public_key), [subscription]);

    useEffect(() => {
        console.log(plan?.plan_id);
        if (plan?.plan_id != null) {
            makePayment({ priceId: plan?.plan_id, discount_code: codeApplied }).then((res) => {
                setClientSecret(res.clientSecret);
            });
        }
    }, [plan]);

    if (!clientSecret) return null;

    return (
        <Elements
            key={clientSecret}
            stripe={stripe}
            options={{
                clientSecret,
                appearance: { theme: theme === "dark" ? "night" : "stripe" },
            }}
        >
            <PaymentStripeForm
                payment={payment}
                setStep={setStep}
                disabled={disabled}
                setDisabled={setDisabled}
                firstNameInvalid={firstNameInvalid}
                setFirstNameInvalid={setFirstNameInvalid}
                lastNameInvalid={lastNameInvalid}
                setLastNameInvalid={setLastNameInvalid}
                plan={plan}
            />
        </Elements>
    );
};

export default PaymentStripe;
