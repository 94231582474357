import fatherStyles from "../settings/Settings.module.scss";
import { Card, CardContent, InputAdornment, Typography, Box, IconButton, TextField, Tooltip, CircularProgress, Switch, FormControlLabel, FormGroup } from "@mui/material";
import { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useRecoilValue, useRecoilState } from "recoil";
import { greyLighterState, hideTickertapeState, textColorState, tickerTapeState, userState } from "@/lib/store";
import { toast } from "react-toastify";
import { findInstruments } from "@/controllers/find-instruments";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import { getStockInfoOnClient } from "@/controllers/stock";
import { useDebounce } from "react-use";
import { authenticate, saveSettings } from "@/controllers/auth";
import { classNames } from "@/lib/utils";
import styles from "./TickertapeSettings.module.scss";
import SaveIcon from '@mui/icons-material/Save';
import { getTopBarValues } from "@/controllers/instruments";
interface PropsTypes {
    isModal?: boolean;
}

const TickertapeSettings = ({ isModal }: PropsTypes) => {
    const defaultOptions = [
        { id: 1, name: "DJIA" },
        { id: 2, name: "S&P 500" },
        { id: 3, name: "Nasdaq Comp" },
        { id: 4, name: "Nasdaq 100" },
        { id: 5, name: "OIL (WTI)" },
        { id: 7, name: "ESTOXX50" },
    ];

    const greyLighter = useRecoilValue(greyLighterState);
    const textColor = useRecoilValue(textColorState);

    const [tickerStocks, setTickerStocks] = useRecoilState(tickerTapeState);
    const [hideTickertape, setHideTickertape] = useRecoilState(hideTickertapeState);

    const [localTickerStocks, setLocalTickerStocks] = useState(tickerStocks);
    const [options, setOptions] = useState<any>(defaultOptions);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newValue, setNewValue] = useState("");
    const [user, setUser] = useRecoilState(userState);
    const index = useRef(0);
    const handleSaveSettings = () => {
        if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
            setTickerStocks(localTickerStocks);
            toast.dismiss();
            saveSettings("tickerTape", "tickertape-settings", localTickerStocks)
                .then(authenticate)
                .then((data) => {
                    setUser(data);
                })
                .then(() => {
                    toast("Tickertape settings saved", { type: "success" });
                });
        } else {
            setTickerStocks(localTickerStocks);
        }
    };

    const handleVisibilityChange = (stockName: string) => {
        const visibility = localTickerStocks[stockName].visibility;
        const newStocks = {
            ...localTickerStocks,
            [stockName]: {
                ...localTickerStocks[stockName],
                visibility: !visibility,
            },
        };

        setLocalTickerStocks(newStocks);

    };

    const removeTickerStock = (stockName: string) => {
        if (getVisibleTickerStocks() <= 5) {
            toast("You should have more than 5 stocks on the ticker carousel", { type: "error" });
            return;
        } else {
            const newStocks = {
                ...localTickerStocks,
            };

            delete newStocks[stockName];

            setLocalTickerStocks(newStocks);

        }
    };

    const getInstruments = (value: string) => {
        setLoading(true);
        findInstruments(value)
            .then((data) => {
                const newOptions = data
                    .filter((newInstruments) => !options.some((optInstruments) => optInstruments.id === newInstruments.value))
                    .map((instrument) => ({
                        key: instrument.ticker,
                        id: instrument.value,
                        name: instrument.exchange + ":" + instrument.ticker,
                    }));

                setOptions([...newOptions, ...defaultOptions]);
            })
            .then(() => {
                setLoading(false);
            });
    };

    const addTickerStock = (value: string) => {
        if (Object.entries(localTickerStocks).length === 15) {
            toast("You can only have 15 stocks on the ticker tape", { type: "error" });
            return;
        }
        getInstruments(value);
    };

    const handleChange = (event, newValue, reason) => {
        if (event.type === "keydown" && reason === "removeOption") {
            console.warn(reason, newValue);
            return;
        }
        if (newValue !== null && newValue !== undefined && localTickerStocks[newValue] === undefined) {
            if (newValue.key) {
                const stocks = Object.values(localTickerStocks).filter((el) => el.stock_id !== undefined);
                getStockInfoOnClient(newValue.id).then((data) => {
                    if (data.last_close !== undefined) {
                        const newStocks = {
                            ...localTickerStocks,
                            [newValue.name]: {
                                stock_id: data.stock_id,
                                name: data?.name,
                                visibility: true,
                                exchange: data.exchangesymbol,
                                price: data.last_close,
                                lastPrice: data.last_close,
                                lastChange: 0,
                                currency: data.currency,
                                ticker: data.ticker,
                                isin: data.isin,
                                order: stocks.length
                            },
                        };

                        setLocalTickerStocks(newStocks);

                    }
                });
            } else {
                const localEntries = Object.keys(localTickerStocks);
                if (localEntries.findIndex((el) => el === newValue.name) > 0) {
                    const newStocks = { ...localTickerStocks };
                    delete newStocks[newValue.name];
                    setLocalTickerStocks(newStocks);
                } else {
                    setNewValue(newValue.name);

                    getTopBarValues()
                        .then((res) => {
                            const newStocks = {
                                ...localTickerStocks,
                                [newValue.code]: {
                                    name: newValue.name,
                                    price: res[newValue.code].price,
                                    lastPrice: res[newValue.code].price,
                                    lastChange: res[newValue.code].price,
                                    timestamp: res[newValue.code].ts,
                                    visibility: true,
                                }
                            };
                            setLocalTickerStocks(newStocks);
                        });
                }

            }
        }
        index.current++;
    };

    useDebounce(
        () => {
            if (newValue !== "") {
                addTickerStock(newValue);
            } else {
                setOptions(defaultOptions);
            }
        },
        300,
        [newValue]
    );
    const getVisibleTickerStocks = () => {
        let count = 0;
        for (const [key, value] of Object.entries(localTickerStocks)) {
            if (value.visibility && !value.removed) {
                count++;
            }
        }
        return count;
    };

    const handleTickertapeVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHideTickertape(event.target.checked);
        const newVisibility = { isHidden: event.target.checked };

        if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
            saveSettings("tickerTape", "tickertape-visibility", newVisibility)
                .then(authenticate)
                .then((data) => {
                    setUser(data);
                });
        }
    };

    return (
        <Card className={classNames(fatherStyles.settingsCard, styles.settingsContainer)} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} elevation={0}>
            <Box>
                <div className={classNames(fatherStyles["settings-item"], isModal ? styles["settings-item"] : "")}>
                    <FormGroup sx={{ width: "fit-content" }}>
                        <FormControlLabel sx={{ width: "fit-content" }} control={<Switch checked={hideTickertape} onChange={handleTickertapeVisibilityChange} />} label={"Hide Tickertape"} labelPlacement="start" />
                    </FormGroup>
                </div>
            </Box>
            {!hideTickertape && (
                <CardContent sx={{ height: "90%", px: isModal && 0 }}>
                    <Typography variant="body1">Add new stocks to ticker</Typography>
                    <Autocomplete
                        open={open}
                        size={isModal ? "small" : "medium"}
                        onChange={handleChange}
                        clearOnBlur
                        blurOnSelect
                        value={null}
                        onClose={() => setOpen(false)}
                        noOptionsText="No labels"
                        loading={loading}
                        renderTags={() => null}
                        filterOptions={(x) => x}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.name + option.id}>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    {option.name}
                                </Box>
                            </li>
                        )}
                        isOptionEqualToValue={(option, value) => option.name === value.name || true}
                        getOptionLabel={(option) => option.name}
                        options={options}
                        disableClearable={true}
                        sx={{
                            padding: 0,
                            "& .MuiSvgIcon-root": {
                                color: textColor,
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                ref={params.InputProps.ref}
                                onChange={(event) => setNewValue(event.target.value)}
                                onFocus={() => setOpen(true)}
                                onBlur={() => {
                                    setOpen(false);
                                    setNewValue("");
                                }}
                                inputProps={params.inputProps}
                                placeholder="Search a Stock"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon htmlColor={greyLighter} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: loading && (
                                        <InputAdornment position="end">
                                            <CircularProgress color="inherit" size={20} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        clearIcon={<ClearIcon fontSize="small" sx={{ color: "red" }} />}
                    />
                    <Box sx={{ overflowY: "auto", height: "80%", p: isModal && 1 }}>
                        {Object.entries(localTickerStocks).length > 0 &&
                            Object.entries(localTickerStocks).map((stock, index) => {
                                if (stock[0] === "undefined" || stock[1]?.removed || stock[1]?.name === undefined) {
                                    return;
                                } else {
                                    return (
                                        <Box key={stock[1].name} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Typography variant="body1" sx={{ width: "60%" }}>
                                                {stock[1]?.name}
                                            </Typography>
                                            <Box className={isModal ? styles.actionBtns : ""}>
                                                <Tooltip arrow title="Toggle visibility">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleVisibilityChange(stock[0])}
                                                        sx={{
                                                            color: textColor,
                                                        }}
                                                        disabled={stock[1]?.visibility && getVisibleTickerStocks() <= 5}
                                                    >
                                                        {stock[1]?.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip arrow title="Delete">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => removeTickerStock(stock[0])}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        sx={{
                                                            color: "red",
                                                        }}
                                                        disabled={getVisibleTickerStocks() <= 5}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    );
                                }
                            })}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        <Button onClick={handleSaveSettings} variant="contained" sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-around", color: "#fff" }}>
                            Save settings
                            <SaveIcon sx={{ marginLeft: 0.5 }} />
                        </Button>
                    </Box>
                </CardContent>
            )}
        </Card>
    );
};

export default TickertapeSettings;