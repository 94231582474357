import styles from "./SignUpDiscountForm.module.scss";
import { ChangeEvent, ChangeEventHandler, FormEventHandler, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { darkerBackgroundColorState, greyLighterState, lighterBackgroundColorState, negativeState, textColorState, universesState, userState } from "@/lib/store";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { authenticate, register } from "@/controllers/auth";
import { getUniverses, getUserUniverses } from "@/controllers/universes";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";
import Checkbox from "@/components/checkbox/Checkbox";
import CountdownBanner from "@/components/countdown-banner/CountdownBanner";
import Confetti from "@/components/confetti/Confetti";

const SignUpDiscountForm = () => {
    const negative = useRecoilValue(negativeState);
    const textColor = useRecoilValue(textColorState);
    const greyLighter = useRecoilValue(greyLighterState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const setUniverses = useSetRecoilState(universesState);
    const setUser = useSetRecoilState(userState);

    const { replace } = useRouter();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [essentialEmails, setEssentialEmails] = useState(false);
    const [marketingEmails, setMarketingEmails] = useState(false);
    const [tosPolicy, setTosPolicy] = useState(false);

    const checkField: ChangeEventHandler<HTMLInputElement> = e => {
        const { id, value } = e.target;
        switch (id) {
            case "firstName":
                setFirstName(value);
                if (!value) setFirstNameError("First name is required");
                else if (!/^[^!\u0022#¤%&/()={}@~#'\[\]`|]+$/.test(value)) setFirstNameError("No special characters accepted");
                else setFirstNameError("");
                break;
            case "lastName":
                setLastName(value);
                if (!value) setLastNameError("Last Name is required");
                else if (!/^[^!\u0022#¤%&/()={}@~#'\[\]`|]+$/.test(value)) setLastNameError("No special characters accepted");
                else setLastNameError("");
                break;
            case "email":
                setEmail(value);
                if (!value) setEmailError("Email is required");
                else if (!/^[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?\.)+[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?$/.test(value)) setEmailError("Invalid email");
                else setEmailError("");
                break;
            case "password":
                setPassword(value);
                if (!value) setPasswordError("Password is required");
                else if (value.length < 8) setPasswordError("Password must be at least 8 characters");
                else setPasswordError("");
                break;
        }
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
        setLoading(true);
        register({ email, password, first_name: firstName, last_name: lastName, essential_emails: essentialEmails, marketing_emails: marketingEmails, tos_policy: tosPolicy })
            .then(res => {
                if (res["msg"]?.includes("successfully")) {
                    Promise.all([authenticate(), process.env.NEXT_PUBLIC_VERCEL_ENV ? getUserUniverses() : getUniverses()])
                        .then(([user, universes]) => {
                            setUser(user);
                            setUniverses(universes);
                            window.Appcues?.identify(user.email, user);
                            replace("/markets-overview?message=welcome");
                        })
                        .finally(() => setLoading(false));
                } else {
                    setErrors(true);
                    setLoading(false);
                    setEmailError("An account with this email address already exists");
                }
            })
            .catch(() => setLoading(false));
    };

    const onInvalid: FormEventHandler = e => {
        e.preventDefault();
        checkField(e as ChangeEvent<HTMLInputElement>);
        setErrors(true);
    };

    return (
        <>
            <Confetti duration={3000} />
            <div className={styles.countdownContainer}>
                <CountdownBanner ortexFree />
            </div>
            <div className={styles.SignUpDiscountForm}>
                <style jsx>{`
                    input {
                        color: ${textColor};
                    }
                    input::placeholder {
                        color: ${greyLighter};
                    }
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-text-fill-color: ${textColor};
                        box-shadow: 0 0 0 10px ${darkerBackgroundColor} inset;
                        -webkit-box-shadow: 0 0 0 10px ${darkerBackgroundColor} inset !important;
                    }
                    @media screen and (hover: hover) {
                        form > a:hover {
                            background-color: ${lighterBackgroundColor};
                        }
                    }
                `}</style>
                <div>
                    <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo" width={150} height={30} priority />
                </div>
                <form onSubmit={handleSubmit} onInvalid={onInvalid}>
                    <h2>Create an account</h2>
                    <div className={styles.fields}>
                        <div className={styles["form-field"]} style={{ borderBottomColor: errors && firstNameError ? negative : greyLighter }}>
                            <input type="text" id="firstName" value={firstName} placeholder="First Name" autoCorrect="off" onChange={checkField} pattern="^[^!\u0022#¤%&/()={}@~#'\[\]`|]+$" required />
                            {errors && firstNameError && <p className="negative">{firstNameError}</p>}
                        </div>
                        <div className={styles["form-field"]} style={{ borderBottomColor: errors && lastNameError ? negative : greyLighter }}>
                            <input type="text" id="lastName" value={lastName} placeholder="Last Name" autoCorrect="off" onChange={checkField} pattern="^[^!\u0022#¤%&/()={}@~#'\[\]`|]+$" required />
                            {errors && lastNameError && <p className="negative">{lastNameError}</p>}
                        </div>
                    </div>
                    <div className={styles["form-field"]} style={{ borderBottomColor: errors && emailError ? negative : greyLighter }}>
                        <input
                            type="text"
                            id="email"
                            value={email}
                            placeholder="Email Address"
                            autoCapitalize="off"
                            autoCorrect="off"
                            onChange={checkField}
                            pattern="^[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?\.)+[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?$"
                            required
                        />
                        {errors && emailError && <p className="negative">{emailError}</p>}
                    </div>
                    <div className={styles["form-field"]} style={{ borderBottomColor: errors && passwordError ? negative : greyLighter }}>
                        <input type={showPassword ? "text" : "password"} id="password" value={password} placeholder="Password" autoCapitalize="off" autoCorrect="off" onChange={checkField} minLength={8} required />
                        {errors && passwordError && <p className="negative">{passwordError}</p>}
                        <button type="button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path
                                        fill="#939598"
                                        d="M160 256C160 185.3 217.3 128 288 128C358.7 128 416 185.3 416 256C416 326.7 358.7 384 288 384C217.3 384 160 326.7 160 256zM288 336C332.2 336 368 300.2 368 256C368 211.8 332.2 176 288 176C287.3 176 286.7 176 285.1 176C287.3 181.1 288 186.5 288 192C288 227.3 259.3 256 224 256C218.5 256 213.1 255.3 208 253.1C208 254.7 208 255.3 208 255.1C208 300.2 243.8 336 288 336L288 336zM95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6V112.6zM288 80C222.8 80 169.2 109.6 128.1 147.7C89.6 183.5 63.02 225.1 49.44 256C63.02 286 89.6 328.5 128.1 364.3C169.2 402.4 222.8 432 288 432C353.2 432 406.8 402.4 447.9 364.3C486.4 328.5 512.1 286 526.6 256C512.1 225.1 486.4 183.5 447.9 147.7C406.8 109.6 353.2 80 288 80V80z"
                                    />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path
                                        fill="#939598"
                                        d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM189.8 123.5L235.8 159.5C258.3 139.9 287.8 128 320 128C390.7 128 448 185.3 448 256C448 277.2 442.9 297.1 433.8 314.7L487.6 356.9C521.1 322.8 545.9 283.1 558.6 256C544.1 225.1 518.4 183.5 479.9 147.7C438.8 109.6 385.2 79.1 320 79.1C269.5 79.1 225.1 97.73 189.8 123.5L189.8 123.5zM394.9 284.2C398.2 275.4 400 265.9 400 255.1C400 211.8 364.2 175.1 320 175.1C319.3 175.1 318.7 176 317.1 176C319.3 181.1 320 186.5 320 191.1C320 202.2 317.6 211.8 313.4 220.3L394.9 284.2zM404.3 414.5L446.2 447.5C409.9 467.1 367.8 480 320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L120.8 191.2C102.1 214.5 89.76 237.6 81.45 255.1C95.02 286 121.6 328.5 160.1 364.3C201.2 402.4 254.8 432 320 432C350.7 432 378.8 425.4 404.3 414.5H404.3zM192 255.1C192 253.1 192.1 250.3 192.3 247.5L248.4 291.7C258.9 312.8 278.5 328.6 302 333.1L358.2 378.2C346.1 381.1 333.3 384 319.1 384C249.3 384 191.1 326.7 191.1 255.1H192z"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox checked={essentialEmails} onChange={checked => setEssentialEmails(checked)}>
                            I want to be sent emails about my account and service updates
                        </Checkbox>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox checked={marketingEmails} onChange={checked => setMarketingEmails(checked)}>
                            I want to be informed occasionally by email about tips and exclusive offers from ORTEX
                        </Checkbox>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox checked={tosPolicy} onChange={checked => setTosPolicy(checked)}>
                            <p className={styles.termsPolicy}>
                                I have read and agree to the{" "}
                                <a href="https://public.ortex.com/terms-and-conditions" target="_blank" rel="noreferrer">
                                    Terms of Service
                                </a>{" "}
                                as well as{" "}
                                <a href="https://public.ortex.com/privacypolicy" target="_blank" rel="noreferrer">
                                    Privacy Policy
                                </a>
                            </p>
                        </Checkbox>
                    </div>
                    <button className={styles["sign-up"]} type="submit" disabled={!tosPolicy || (errors && (!!emailError || !!passwordError)) || loading} style={{ color: textColor }}>
                        {loading ? <LoadingSpinner size={20} borderWidth={2} /> : "Create account"}
                    </button>
                    <div className={styles.separator} style={{ color: greyLighter, border: `1px solid ${greyLighter}` }}>
                        <span style={{ backgroundColor: darkerBackgroundColor }}>or</span>
                    </div>
                    <a href={`${process.env.NEXT_PUBLIC_API_URL}/accounts/google/login/?next=/${process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "app-beta" : "app"}`} style={{ color: textColor, border: `1px solid ${greyLighter}` }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                            <path
                                fill={textColor}
                                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                            />
                        </svg>
                        Sign up with Google
                    </a>
                </form>
                <p>
                    Already have an account?{" "}
                    <Link href="/login" passHref>
                        <a>Sign in</a>
                    </Link>
                </p>
            </div>
        </>
    );
};

export default SignUpDiscountForm;
