import { useRecoilState, useRecoilValue } from "recoil";
import { ortexColorState, textColorState, userState } from "@/lib/store";
import { Settings } from "@/models/auth";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { updateUserSettings } from "@/controllers/auth";

type SaveFiltersButtonProps = {
    tableId: string;
    values: any[];
    resetOptions?: { setResetFilters: any; setChips: (chips: Array<any>) => void; reset: { setter; defaultValue }; DataGrid: boolean; setParams: (params: any) => void; fetcher; defaultValues };
};

const SaveFiltersButton: FC<SaveFiltersButtonProps> = ({ tableId, values, resetOptions }) => {
    const textColor = useRecoilValue(textColorState);
    const ortexColor = useRecoilValue(ortexColorState);
    const [user, setUser] = useRecoilState(userState);
    const [open, setOpen] = useState<Boolean>(false);
    const [dialogType, setDialogType] = useState<"save" | "delete">();
    const [hasSavedFilters, setHasSavedFilters] = useState<Boolean>();

    useEffect(() => {
        user.settings.module_settings?.["datagrid->saved-filters"] && setHasSavedFilters(user.settings.module_settings?.["datagrid->saved-filters"].hasOwnProperty(tableId));
    }, [user.settings.module_settings?.["datagrid->saved-filters"]]);

    const { setResetFilters, setChips, reset, DataGrid, setParams, fetcher, defaultValues } = resetOptions || {};

    const resetTable = () => {
        try {
            setResetFilters && setResetFilters(true);
            setChips && setChips([]);

            if (reset?.defaultValue && reset?.setter) {
                const { setter, defaultValue } = reset;
                if (Array.isArray(setter)) setter.forEach((set, i) => set(defaultValue[i]));
                else setter(defaultValue);
            }

            if (DataGrid) {
                setParams(defaultValues.apiParams);
                fetcher(1, undefined, defaultValues);
            }
        } catch (error) {
            console.log("error reseting table", error);
        }
    };

    const handleSavingFilters = (actionType: "save" | "delete") => {
        const newSavedDGFilters = { ...user.settings.module_settings?.["datagrid->saved-filters"] };

        if (actionType === "save") {
            newSavedDGFilters[tableId] = {
                customFilterModel: {
                    items: values,
                },
            };
        } else if (actionType === "delete") delete newSavedDGFilters[tableId];

        const settings: Settings = { ...user.settings, module_settings: { ...user.settings?.module_settings, ["datagrid->saved-filters"]: newSavedDGFilters } };
        setUser({ ...user, settings });

        if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
            try {
                updateUserSettings(settings);
            } catch (error) {
                console.log("error updating user settings", error);
            }
        }

        if (actionType === "delete") resetTable();
        setOpen(false);
    };

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ width: "100%", mt: 1 }}>
            <Divider />
            <Button
                onClick={() => {
                    handleClickOpen();
                    setDialogType("save");
                }}
                aria-label="save current filters"
                size="small"
                sx={{
                    /* mr: "auto", */
                    color: textColor,
                    mt: 1,
                    mr: 1,
                    "&:disabled": {
                        color: "grey",
                    },
                }}
                endIcon={<SaveOutlinedIcon sx={{ color: ortexColor }} />}
            >
                <Typography variant="overline">Save filters</Typography>
            </Button>
            <Button
                onClick={() => {
                    handleClickOpen();
                    setDialogType("delete");
                }}
                aria-label="delete saved filters"
                size="small"
                sx={{
                    /* mr: "auto", */
                    color: textColor,
                    mt: 1,
                    mr: 1,
                    "&:disabled": {
                        color: "grey",
                    },
                }}
                endIcon={<DeleteOutlineIcon color={hasSavedFilters ? "error" : "disabled"} />}
                disabled={!hasSavedFilters}
            >
                <Typography variant="overline">Delete saved filters</Typography>
            </Button>

            <Dialog open={Boolean(open)} onClose={handleClose} aria-labelledby="confirm-saved-filters-changes" aria-describedby="confirmation-description-saved-filters-changes">
                <DialogTitle id="confirm-saved-filters-changes">{`Are you sure you want to ${dialogType === "save" ? "save the current" : "delete the saved"} filters?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-description-saved-filters-changes">
                        {dialogType === "save" ? (
                            <>
                                This will <b>override</b> any other filters saved for this table.
                            </>
                        ) : (
                            <>
                                This will <b>delete</b> the saved filters and clear them from the table.
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Cancel
                    </Button>
                    <Button onClick={() => handleSavingFilters(dialogType)}>Accept</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SaveFiltersButton;
