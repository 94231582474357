import { useContext, useEffect, useRef, useState, isValidElement } from "react";
import { Badge, Box, Card, IconButton, Drawer, Tooltip, useMediaQuery } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./FiltersBtn.module.scss";
import { useRecoilValue } from "recoil";
import { greyLighterState, newsAutocompleteState, textColorState, themeState } from "@/lib/store";
import { ModuleContext } from "@/lib/Context";

interface Props {
    filters: JSX.Element;
}

const FiltersBtn = ({ filters }: Props) => {
    const textColor = useRecoilValue(textColorState);
    const greyLighter = useRecoilValue(greyLighterState);
    const newsAutocomplete = useRecoilValue(newsAutocompleteState);
    const { filteredAmount } = useContext(ModuleContext);
    const theme = useRecoilValue(themeState);

    const isMobile = useMediaQuery("(max-width: 600px)");
    const [popupFits, setPopupFits] = useState(true);

    const filtersContainerRef = useRef<HTMLDivElement>(null);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (filtersContainerRef.current) {
            const { top, height } = filtersContainerRef.current.getBoundingClientRect();

            if (top + height + 400 > window.innerHeight) {
                setPopupFits(false);
            }
        }
    }, [filtersContainerRef.current]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const popoverWidth = !popupFits && (newsAutocomplete?.data.length > 0 || newsAutocomplete?.loading) ? "950px" : isMobile ? "100%" : "unset";
    const anchorPosition = isMobile ? "bottom" : "right";

    if (!filters || !isValidElement(filters)) return null;

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Filters" arrow>
                <IconButton aria-label={filteredAmount ? `${filteredAmount} filters applied` : "no filters applied"} className={filteredAmount ? styles.filtered : styles.button} sx={{ padding: 0 }} onClick={handleClick}>
                    <Badge badgeContent={filteredAmount} color="primary">
                        <FilterListIcon style={{ color: textColor }} />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Drawer
                anchor={anchorPosition}
                id={id}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        paddingTop: "env(safe-area-inset-top)",
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "normal",
                        justifyContent: "flex-start",

                        "& .MuiSelect-icon": {
                            color: theme === "dark" && textColor,
                        },
                    },
                }}
            >
                <Card sx={{ p: 2, maxWidth: "100%", height: "100%" }} ref={filtersContainerRef}>
                    {isMobile && (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <IconButton sx={{ padding: 0 }} onClick={handleClose}>
                                <CloseIcon style={{ color: "grey" }} />
                            </IconButton>
                        </Box>
                    )}
                    {filters}
                </Card>
            </Drawer>
        </Box>
    );
};

export default FiltersBtn;
