import { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

let confettiTimeout: NodeJS.Timeout;

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 3,
};

function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

function getAnimationSettings(originXA: number, originXB: number) {
    return {
        startVelocity: 20,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: 150,
        colors: ["#dc0029", "#ffffff", "#0f55a6"],
        origin: {
            x: randomInRange(originXA, originXB),
            y: Math.random() - 0.2,
        },
    };
}

const Confetti = ({ duration }) => {
    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState<any>();

    const getInstance = useCallback(instance => {
        refAnimationInstance.current = instance;
    }, []);

    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
            refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
        }
    }, []);

    useEffect(() => {
        if (!intervalId) {
            setIntervalId(setInterval(nextTickAnimation, 400));
        }
    }, [nextTickAnimation]);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    confettiTimeout = setTimeout(() => {
        clearInterval(intervalId);
        setIntervalId(null);
    }, duration);

    return (
        <>
            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles as Object} />
        </>
    );
};

export default Confetti;
