import { FC, useLayoutEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Link from "next/link";
import { useRecoilValue } from "recoil";
import { backgroundColorState, textColorState, themeState } from "@/lib/store";
import styles from "./MenuPopup.module.scss";
import { ListItemIcon } from "@mui/material";
import { Typography } from "@mui/material";
import { useMedia } from "react-use";
import { classNames } from "@/lib/utils";

interface MenuPopupProps {
    links: {
        href: string;
        text: string;
        icon: string;
    }[];
    index: number;
    parentId?: string;
}

const MenuPopup: FC<MenuPopupProps> = ({ links, index, parentId, }) => {
    const backgroundColor = useRecoilValue(backgroundColorState);
    const textColor = useRecoilValue(textColorState);
    const ref = useRef<HTMLDivElement>(null);
    const isSmallMenu = parentId.includes("small");
    const theme = useRecoilValue(themeState);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        const currentElementHeight = ref.current.getBoundingClientRect().height;
        const parentElement = document.getElementById(parentId + index);
        if (!parentElement) {
            return;
        }

        const parentElementBoundingClientRect = parentElement.getBoundingClientRect();

        if (parentElementBoundingClientRect.top + currentElementHeight > window.innerHeight) {
            ref.current.style.top = `${parentElementBoundingClientRect.bottom - currentElementHeight}px`;
        } else {
            ref.current.style.top = `${parentElementBoundingClientRect.top}px`;
        }
        ref.current.style.left = `${isSmallMenu ? parentElementBoundingClientRect.width + 7 : parentElementBoundingClientRect.width}px`;
    });

    return (
        <div className={styles.MenuPopup} ref={ref}>
            <Paper
                square={true}
                elevation={theme === "dark" ? 0 : 3}
                variant={theme === "dark" ? "outlined" : "elevation"}
                sx={{
                    backgroundColor, borderColor: "rgb(62, 62, 62)",
                    "& .MuiListItemIcon-root": {
                        minWidth: "auto",
                    },
                }}
            >
                <List sx={{ padding: 0, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    {links.map(({ href, text, icon }, index) => (
                        <ListItem key={index} disablePadding className={styles.mainLink} sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 0 }}>
                            <Link href={href} passHref>
                                <ListItemButton className={styles["dynamic-padding"]} sx={{ width: "100%", margin: 0 }}>
                                    <ListItemIcon sx={{ minWidth: isSmallMenu ? "40px" : "45px", marginRight: 1 }}>
                                        <div className={classNames(icon, styles["dynamic-fontsize"])} style={{ color: textColor, }} />
                                    </ListItemIcon>

                                    <ListItemText sx={{ color: textColor, }}>
                                        <Typography className={styles.menuFonts} variant="h5userMenu">
                                            {" "}
                                            {text}{" "}
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </div>
    );
};

export default MenuPopup;
