import { useRef, useState } from "react";
import styles from "./Dialog.module.scss";
import { useRecoilValue } from "recoil";
import { dividerState, iconColorState, textColorState } from "@/lib/store";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Breakpoint, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

interface PropsTypes {
    backgroundColor?: string;
    content?: string;
    isOpen: boolean;
    noCloseButton?: boolean;
    onClose: (close: boolean) => void;
    title?: string;
    source_fullname?: string;
    published_at?: number;
    description?: string;
    handleSubmit?: () => void;
    disableSubmit?: boolean;
    children?: React.ReactNode;
    draggable?: boolean;
    maxWidth?: Breakpoint | false;
}

import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const sanitizeHtml = (content: string) => {
    const hasTagsRegex = /<[^>]+>/g;
    if (hasTagsRegex.test(content)) {
        const colorStyleRegex = /color\s*:\s*[^;]+;/gi;
        const html = DOMPurify.sanitize(content?.replace(colorStyleRegex, ""));
        return html;
    } else return content;
};

const DialogBasic = ({ description, published_at, source_fullname, title, backgroundColor, children, content, isOpen, noCloseButton, onClose, handleSubmit, disableSubmit, draggable, maxWidth }: PropsTypes) => {
    const textColor = useRecoilValue(textColorState);
    const contentRef = useRef<HTMLDivElement>();
    const dialogRef = useRef<HTMLDivElement>();
    const iconColor = useRecoilValue(iconColorState);
    const dividerColor = useRecoilValue(dividerState);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [fontSize, setFontSize] = useState(16);
    const sanitizedContent = sanitizeHtml(content);

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={isOpen} onClose={() => onClose(false)} className={styles.dialog} PaperComponent={draggable && PaperComponent} aria-labelledby="draggable-dialog-title" fullWidth={!!maxWidth} maxWidth={maxWidth}>
                {children ? (
                    <>
                        {title && (
                            <>
                                <DialogTitle id={draggable ? "draggable-dialog-title" : "responsive-dialog-title"} className={draggable && styles.draggableHandler}>
                                    <Typography variant="h1">{title}</Typography>
                                </DialogTitle>
                                <Divider sx={{ backgroundColor: dividerColor, width: maxWidth ? "95%" : "80%", margin: "auto" }} className={styles.firstDivider} />
                            </>
                        )}
                        <DialogContent ref={dialogRef}>
                            <DialogContentText style={{ fontSize: `${fontSize}px` }}>
                                <div style={{ color: textColor }} ref={contentRef}>
                                    {children}
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle id={draggable ? "draggable-dialog-title" : "responsive-dialog-title"} className={draggable && styles.draggableHandler}>
                            <div>
                                <Typography variant="h1">{title}</Typography>
                                <Grid justifyContent="space-between" alignItems="center" container columns={2} className={styles.title_fullname_publishedat}>
                                    <Grid item xs={1}>
                                        <Typography variant="body2">{source_fullname}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {published_at && <Typography variant="body2"> {dayjs(published_at).format("Do MMM HH:mm")}</Typography>}
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogTitle>
                        <DialogContent ref={dialogRef}>
                            {title && <Divider sx={{ backgroundColor: dividerColor }} className={styles.firstDivider} />}
                            {/* <div> {description}</div> */}
                            <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                                {description}
                            </Typography>

                            {description && <Divider sx={{ backgroundColor: dividerColor }} className={styles.secondDivider} />}
                            <DialogContentText style={{ fontSize: `${fontSize}px` }}>
                                {content && <div className={styles.content} style={{ color: textColor, whiteSpace: "pre-wrap" }} ref={contentRef} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>}
                            </DialogContentText>
                        </DialogContent>
                    </>
                )}
                <DialogActions disableSpacing={false} className={!children ? styles.font_actions : ""}>
                    {!children && (
                        <div>
                            <Tooltip title="Decrease font-size" arrow>
                                <IconButton sx={{ color: iconColor }} onClick={() => setFontSize(fontSize - 1)}>
                                    <TextDecreaseIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Increase font-size" arrow>
                                <IconButton sx={{ color: iconColor }} onClick={() => setFontSize(fontSize + 1)}>
                                    <TextIncreaseIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Reset font-size" arrow>
                                <IconButton sx={{ color: iconColor }} onClick={() => setFontSize(16)}>
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    <div>
                        <Button autoFocus onClick={() => onClose(false)}>
                            Close
                        </Button>

                        {handleSubmit && (
                            <Button onClick={!disableSubmit && handleSubmit} disabled={disableSubmit}>
                                Submit
                            </Button>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogBasic;
