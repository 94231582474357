import { ResourcesConfig } from "aws-amplify";

const rootUrl = process.env.NEXT_PUBLIC_ROOT_URL;

const awsExports: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolClientId: process.env.NEXT_PUBLIC_AWS_USER_POOL_CLIENT_ID,
            userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID,
            loginWith: {
                oauth: {
                    domain: process.env.NEXT_PUBLIC_AWS_COGNITO_DOMAIN,
                    scopes: ["email openid profile"],
                    redirectSignIn: [`${rootUrl}/auth-redirect`],
                    redirectSignOut: [`${rootUrl}/login`],
                    responseType: "code",
                },
                email: true,
            },
        },
    },
};
export default awsExports;
