import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import styles from "./StockLinks.module.scss";
import { useRecoilValue } from "recoil";
import { backgroundColorState, blackLighterState, tabLinksColorState, themeState, userState, whiteDarkerState } from "@/lib/store";
import { Stock } from "@/models/stock";
import { Button, Menu, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import Link from "next/link";
import { MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StockHeaderInfo from "../stock-header-info/StockHeaderInfo";

const getLinks = (pathname: string, isLiveFeed: string, stockType: number) => {
    let path: string;
    if (pathname.includes("/id/")) path = pathname.split("/id/[stock_id]")[1];
    else if (pathname.includes("/s/")) path = pathname.split("/s/[exchange]/[ticker]")[1];
    const links = [
        { href: "", text: "Overview", selected: true },
        { href: "fundamentals", text: "Fundamentals", selected: false },
        { href: "events", text: "Events", selected: false },
        { href: "news", text: "News", selected: false },
        { href: "corporate-actions", text: "Corp Actions", selected: false },
        { href: "analysts", text: "Analysts", selected: false },
        { href: "eps", text: "EPS Momentum", selected: false },
        { href: "holders", text: "Holders", selected: false },
        { href: "dividend", text: "Dividend", selected: false },
        { href: "options", text: "Options", selected: false },
        { href: "short-interest", text: "Shorts", selected: false },
        { href: "insiders", text: "Insiders", selected: false },
        { href: "peers", text: "Peers", selected: false },
        { href: "currency-exposure", text: "Currency Exp", selected: false },
        { href: "commodity-exposure", text: "Commodity Exp", selected: false },
        { href: "trading-signals", text: "Trading Signals", selected: false },
        { href: "stock-scores", text: "Stock Scores", selected: false },
        { href: "cds", text: "CDS", selected: false },
    ];
    let index: number;
    if ((index = links.slice(1, links.length).findIndex((link) => path.includes(link.href))) >= 0) {
        links[0].selected = false;
        links[index + 1].selected = true;
    }
    if (stockType === 3) return links.filter((link) => ["", "corporate-actions", "options", "short-interest", "trading-signals"].includes(link.href));
    else if (stockType !== 1) return links.filter((link) => link.href === "trading-signals");
    if (isLiveFeed === "EU") return links.filter((link) => link.href !== "options");
    return links;
};

const MobileMenu = ({ currentTab, linksList, isEUStock, stock, pathname }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
                <Typography>{linksList[currentTab].text || "Overview"}</Typography> <ExpandMoreIcon />
            </Button>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ maxHeight: "90%" }}>
                {linksList.map((link) =>
                    isEUStock && link.text === "Options" ? null : (
                        <MenuItem
                            value={link.text}
                            sx={{
                                "& a": {
                                    all: "unset",
                                },
                            }}
                            onClick={() => setAnchorEl(null)}
                            selected={link.text === linksList[currentTab].text}
                            key={link.text}
                        >
                            <Link href={pathname.includes("/s/") ? `/s/${stock.exchangesymbol}/${stock.ticker}/${link.href}` : `/id/${stock.stock_id}/${link.href}`} passHref className={styles.selectLink} style={{ color: "pink" }}>
                                {link.text}
                            </Link>
                        </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
};

const StockLinks: FC<{ stock: Stock }> = ({ stock }) => {
    const router = useRouter();
    const { pathname } = useRouter();

    const user = useRecoilValue(userState);
    const theme = useRecoilValue(themeState);
    const whiteDarker = useRecoilValue(whiteDarkerState);
    const blackLighter = useRecoilValue(blackLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const tabColor = useRecoilValue(tabLinksColorState);

    const [currentTab, setCurrentTab] = useState(0);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const isUSStock = stock.isLiveFeed === "NA";
    const isEUStock = stock.isLiveFeed === "EU";

    const linksList = getLinks(pathname, stock.isLiveFeed, stock.type);

    const handleTabClick = (link) => {
        const stockIdPath = `/id/${stock.stock_id}/${link.href}`;
        const stockTickerPath = `/s/${stock.exchangesymbol}/${stock.ticker}/${link.href}`;

        if (pathname.includes("/s/") && router.asPath !== stockTickerPath) {
            router.push(stockTickerPath);
        } else if (pathname.includes("/id/") && router.asPath !== stockIdPath) {
            router.push(stockIdPath);
        }
    };

    const handleChangeTab = (event, newValue) => {
        newValue && setCurrentTab(newValue);
    };

    useEffect(() => {
        const index = linksList.findIndex((link) => link.selected);
        setCurrentTab(index !== -1 ? index : 0);
    }, [linksList]);

    return (
        <div className={styles.StockLinks} style={{ visibility: user.email ? "visible" : "hidden" }}>
            <style>
                {`
                .MuiTabScrollButton-root {
                    overflow: hidden;
                    transition: width 0.5s;
                    width: 28px;
                }

                .MuiTabScrollButton-root.Mui-disabled {
                    width: 0px;
                }
                
                .${styles.stockTabs} a:hover {
                    background-color: ${theme === "dark" ? blackLighter : whiteDarker};
                }
            `}
            </style>
            {isMobile ? (
                <Box sx={{ display: "flex", alignItems: "center", mt: "5.5px", mb: "6px", mr: 1 }}>
                    <StockHeaderInfo stock={stock} />
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                        <MobileMenu currentTab={currentTab} linksList={linksList} isEUStock={isEUStock} stock={stock} pathname={pathname} />
                    </Box>
                </Box>
            ) : (
                <Box className={styles.stockTabs}>
                    <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile aria-label="scrollable auto tabs example">
                        {linksList.map((link) => (isEUStock && link.text === "Options" ? null : <Tab onClick={() => handleTabClick(link)} sx={{ color: tabColor }} key={link.text} label={<Typography variant="body3">{link.text}</Typography>} />))}
                    </Tabs>
                </Box>
            )}
        </div>
    );
};

export default StockLinks;
