import { Children, FC, ReactNode, useEffect, useRef, useState, RefObject, MutableRefObject, useContext } from "react";
import styles from "./Module.module.scss";
import { classNames, isMobileSafari } from "@/lib/utils";
import { backgroundColorState, themeState, userDataState, textColorState, greyDarkerState, whiteMediumState } from "@/lib/store";
import { useRecoilValue } from "recoil";
import Modal from "@/components/modal/Modal";
import RichTextEditor from "@/components/rich-text-editor/RichTextEditor";
import { getModuleHelp, upsertModuleHelp } from "@/controllers/module-help";
import Link from "next/link";
import { useFullscreen, useToggle } from "react-use";
import { ContentLayoutContext, ModuleContext } from "@/lib/Context";
import Image from "next/image";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button, CardHeader, Grid, Typography, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import DialogBasic from "../dialog/Dialog";
import FiltersBtn from "../filters-btn/FiltersBtn";
import { sonifyChart } from "@/lib/highcharts.src";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { Chart } from "highcharts";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "@mui/material/Tooltip";
import { NoBackgroundType, NoPaddingType } from "@/models/module";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ErrorBoundary } from "react-error-boundary";
import ModuleErrorFallback from "../error-fallback/ModuleErrorFallback";

type PropsTypes = {
    help?: {
        moduleId: string;
        modulePage: string;
    };
    href?: string;
    logoUrl?: string;
    subHeader?: ReactNode;
    modalComponent?: ReactNode;
    settingsComponent?: ReactNode;
    title?: JSX.Element | string;
    allowFullscreen?: boolean;
    obfuscated?: boolean;
    noHeader?: boolean;
    noPadding?: NoPaddingType;
    noBackground?: boolean;
    filters?: JSX.Element;
    sonification?: MutableRefObject<{
        chart: Chart;
        container: RefObject<HTMLDivElement>;
    }>;
    link?: string;
    headerButton?: { content: string; onClick: () => void; variant?: "outlined" | "text" | "contained" };
    contentLayout?: boolean;
    stockPage?: boolean;
    enableFilterProp?: boolean;
};

const Module: FC<PropsTypes> = ({
    children,
    help,
    href,
    modalComponent,
    logoUrl,
    subHeader,
    settingsComponent,
    title,
    allowFullscreen,
    obfuscated: parentObfuscated,
    noHeader,
    noPadding,
    noBackground,
    filters,
    sonification,
    link,
    headerButton,
    contentLayout,
    stockPage,
    enableFilterProp,
}) => {
    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);
    const userData = useRecoilValue(userDataState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const { disableFilters } = useContext(ContentLayoutContext);
    const [canOpen, setCanOpen] = useState(true);
    const [helpModal, setHelpModal] = useState(false);
    const [obfuscated, setObfuscated] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [content, setContent] = useState("No help found.");
    const [loading, setLoading] = useState(false);
    const customViewsTooltipRef = useRef<HTMLDivElement>(null);
    const [filteredAmount, setFilterAmount] = useState<number>(0);

    const greyDarker = useRecoilValue(greyDarkerState);
    const whiteMedium = useRecoilValue(whiteMediumState);

    const moduleRef = useRef(null);

    const [fullscreen, toggleFullscreen] = useToggle(false);

    const isFullscreen = useFullscreen(moduleRef, fullscreen, { onClose: () => toggleFullscreen(false) });

    const isMobile = useMediaQuery("(max-width: 769px)");
    const isTablet = useMediaQuery("(max-width: 1280px)");
    const zeroPadding = noPadding?.type === "all" || (noPadding?.type === "desktop" && !isMobile) || (noPadding?.type === "mobile" && isMobile) || (noPadding?.type === "tablet" && isTablet);

    const getHelp = () => {
        setCanOpen(false);
        getModuleHelp(help.moduleId, help.modulePage)
            .then(setContent)
            .finally(() => {
                setCanOpen(true);
                setHelpModal(true);
            });
    };

    const saveHelp = (markup: string) => process.env.NEXT_PUBLIC_VERCEL_ENV && upsertModuleHelp(help.moduleId, help.modulePage, markup);

    const [module_header_height, setModule_header_height] = useState(0);
    const module_header_Ref = useRef(null);

    useEffect(() => {
        if (!noHeader) setModule_header_height(module_header_Ref.current.clientHeight);
    }, []);

    useEffect(() => setObfuscated(parentObfuscated), [parentObfuscated]);

    return (
        <ModuleContext.Provider value={{ settings: { moduleId: help?.moduleId, modulePage: help?.modulePage }, isFullscreen, obfuscated, setObfuscated, filteredAmount, setFilterAmount }}>
            <ErrorBoundary
                FallbackComponent={ModuleErrorFallback}
                onError={(error: Error, info: { componentStack: string }) => {
                    console.error(error, info);
                }}
            >
                <div className={classNames(styles.Module, styles[theme])} ref={moduleRef}>
                    <Card variant={theme === "light" || contentLayout ? "elevation" : "outlined"} sx={{ height: "100%", backgroundColor: "transparent", borderColor: "rgb(62, 62, 62)" }} elevation={theme === "light" && !contentLayout ? 1 : 0}>
                        {!noHeader && (
                            <CardHeader
                                ref={module_header_Ref}
                                className={classNames(styles["title-container"], stockPage && styles["no-padding-bottom"])}
                                style={{
                                    borderBottom: !contentLayout && theme === "dark" ? `1px solid ${greyDarker}` : "0px solid transparent",
                                    boxShadow: contentLayout && theme === "light" ? "none" : theme === "light" && "rgba(49, 49, 164, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                    padding: 0,
                                    /*  margin: (title || settingsComponent || allowFullscreen || modalComponent) && 5 */
                                }}
                                sx={{
                                    backgroundColor: !contentLayout || noBackground ? backgroundColor : "transparent",
                                }}
                                title={
                                    title && (
                                        <>
                                            <Grid container className={styles.title_grid} sx={{ justifyContent: "space-between" }}>
                                                <Grid item xs={true} sx={{ display: "flex", alignItems: "center" }}>
                                                    {href ? (
                                                        <Link href={href} passHref>
                                                            <Typography variant={contentLayout ? "h1" : "h2"} style={{ color: textColor }}>
                                                                {title}
                                                            </Typography>
                                                        </Link>
                                                    ) : (
                                                        <Typography sx={{ width: "100%" }} variant={contentLayout ? "h1" : "h2"}>
                                                            {title}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                {headerButton && (
                                                    <Grid item xs={8} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                        {headerButton && (
                                                            <Button
                                                                variant={headerButton.variant ? headerButton.variant : "text"}
                                                                onClick={headerButton.onClick}
                                                                className={styles["header-button"]}
                                                                sx={{ color: headerButton.variant ? (headerButton.variant === "contained" ? "#fff" : "#000") : "inherit" }}
                                                            >
                                                                {headerButton.content}
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                )}
                                                <Grid item xs={"auto"} sx={{ display: "flex", justifyContent: "end", gap: 1 }}>
                                                    {settingsComponent && <Box sx={{ display: "flex", alignItems: "center" }}>{settingsComponent}</Box>}
                                                    {filters &&
                                                        (!disableFilters || enableFilterProp ? (
                                                            <FiltersBtn filters={filters} />
                                                        ) : (
                                                            <IconButton sx={{ padding: 0 }} disabled>
                                                                <FilterListIcon style={{ color: "grey" }} />
                                                            </IconButton>
                                                        ))}
                                                    {sonification && (
                                                        <IconButton onClick={() => sonifyChart(sonification.current.chart)} sx={{ padding: 0 }}>
                                                            <MusicNoteIcon />
                                                        </IconButton>
                                                    )}
                                                    {!isFullscreen && help && (
                                                        <Tooltip title="Help" arrow>
                                                            <IconButton aria-label="help" className={styles.button} onClick={canOpen ? getHelp : undefined}>
                                                                <InfoOutlinedIcon style={{ color: textColor }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {typeof window !== "undefined" && !isFullscreen && modalComponent && (
                                                        <Box sx={{ display: "flex" }}>
                                                            <Tooltip title="Settings" arrow>
                                                                <IconButton className={styles.button} onClick={() => setSettingsModal(true)} style={{ padding: 0 }}>
                                                                    <SettingsIcon style={{ color: textColor }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                    {allowFullscreen && !isMobileSafari() && (
                                                        <Box sx={{ display: "flex" }}>
                                                            {isFullscreen ? (
                                                                <IconButton aria-label="delete" className={styles.button} onClick={() => toggleFullscreen()}>
                                                                    <CloseFullscreenOutlinedIcon style={{ color: textColor }} />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton aria-label="delete" className={styles.button} onClick={() => toggleFullscreen()}>
                                                                    <FullscreenOutlinedIcon style={{ color: textColor }} />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    )}

                                                    {link && (
                                                        <Tooltip title={"Link to " + link} arrow>
                                                            <IconButton className={styles.button}>
                                                                <Link href={link} passHref>
                                                                    <OpenInNewIcon style={{ color: textColor }} />
                                                                </Link>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                                avatar={
                                    logoUrl && (
                                        <div className={styles.logo}>
                                            <Image unoptimized src={logoUrl} alt={`logo`} layout="fill" objectFit="contain" />
                                        </div>
                                    )
                                }
                                subheader={subHeader}
                            />
                        )}
                        <CardContent
                            className={classNames(["both", "body"].includes(noPadding?.source) && zeroPadding ? styles["no-padding"] : styles["content-container"], parentObfuscated && "obfuscated")}
                            sx={{
                                height: `calc(100% - ${module_header_height}px)`,
                                backgroundColor: fullscreen ? (theme === "dark" ? "black" : "white") : "transparent",
                            }}
                        >
                            <div className={styles.child_cardContent}>{children && Children.map(children, (child) => child)}</div>
                        </CardContent>

                        {userData && (
                            <DialogBasic isOpen={helpModal} onClose={setHelpModal}>
                                <div className={styles.modal}>{userData.is_staff || userData.is_superuser ? <RichTextEditor markup={content} onSave={saveHelp} /> : <div dangerouslySetInnerHTML={{ __html: content }}></div>}</div>
                            </DialogBasic>
                        )}
                        <DialogBasic isOpen={settingsModal} onClose={setSettingsModal}>
                            {modalComponent}
                        </DialogBasic>
                    </Card>
                </div>
            </ErrorBoundary>
        </ModuleContext.Provider>
    );
};

export default Module;
