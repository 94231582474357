import { useEffect } from "react";
import dayjs from "dayjs";
import { FilterType } from "@/models/datagrid";

const useDateFilter = (filters: Array<FilterType>, localParams: any, setLocalParams: (params: any) => void) => {
    useEffect(() => {
        if (filters?.find((filter) => filter.filterType === "date") !== undefined && !localParams?.customFilterModel) {
            // this assumes just one filter with defaultMin and filterType "date"
            const dateFilter = filters.find((filter) => filter.defaultMin !== undefined && filter.filterType === "date");
            if (dateFilter) {
                let minDate = { columnField: dateFilter.field, id: Date.now(), operatorValue: "after", value: dayjs(dateFilter.defaultMin).format("YYYY-MM-DD") };
                let maxDate = { columnField: dateFilter.field, id: Date.now(), operatorValue: "before", value: dayjs(dateFilter?.defaultMax).format("YYYY-MM-DD") };
                let copycat;
                setLocalParams((prevParams) => {
                    // @ts-ignore
                    const oldParams = prevParams?.customFilterModel?.items.filter((i) => i.filterType !== "date") || [];
                    copycat = {
                        ...prevParams,
                        customFilterModel: {
                            items: [...oldParams, minDate, maxDate],
                        },
                    };
                    return copycat;
                });
            }
        }
    }, [filters, localParams, setLocalParams]);
};

export default useDateFilter;
