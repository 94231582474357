import { useEffect, useState } from "react";
import styles from "./SelectFilter.module.scss";
import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent, useMediaQuery } from "@mui/material";
import { DataGridFilterValues, DataGridHeaders } from "@/models/datagrid";
import { dividerState, themeState } from "@/lib/store";
import { useRecoilValue } from "recoil";
import _ from "lodash";

type Props = {
    setParams: (params: any) => void;
    values?: Array<DataGridFilterValues>;
    header: DataGridHeaders;
    initialParams?: any[];
};

const MultiSelectFilter = ({ header, setParams, values }: Props) => {
    const { field, filterOptions } = header;
    const isMobile = useMediaQuery("(max-width: 768px)");
    const theme = useRecoilValue(themeState);
    const dividerColor = useRecoilValue(dividerState);

    const [selectedValuesState, setSelectedValuesState] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setSelectedValuesState(() => (typeof value === "string" ? value.split(", ") : value));
    };

    const onDelete = (chip: string) => {
        const newSetValues = selectedValuesState.filter((c) => c !== chip);
        setSelectedValuesState(newSetValues);
        if (newSetValues.length === 0) {
            setParams((prevParams) => {
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: [],
                    },
                };
            });
        } else {
            setParams((prevParams) => {
                const newParams = _.cloneDeep(prevParams?.customFilterModel?.items) || [];
                let index = newParams.findIndex((el) => el.columnField === field);
                newParams[index].value = newSetValues;
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: newParams,
                    },
                };
            });
        }
    };

    const handleSubmit = () => {
        const currentValues = values.find((filter) => filter.columnField === field);

        if (!(currentValues === undefined && selectedValuesState.length === 0) && JSON.stringify(currentValues === undefined ? currentValues : currentValues.value) !== JSON.stringify(selectedValuesState)) {
            if (selectedValuesState.length > 0) {
                setParams((prevParams) => {
                    const newParams = _.cloneDeep(prevParams?.customFilterModel?.items) || [];

                    if (newParams.length !== 0) {
                        let index = newParams.findIndex((el) => el.columnField === field);
                        if (index >= 0) {
                            newParams[index].value = selectedValuesState;
                            return {
                                ...prevParams,
                                customFilterModel: {
                                    items: newParams,
                                },
                            };
                        }
                    } else {
                        return {
                            ...prevParams,
                            customFilterModel: {
                                items: [{ columnField: field, id: Date.now(), operatorValue: "contains", value: selectedValuesState }],
                            },
                        };
                    }
                });
            } else {
                setParams((prevParams) => {
                    return {
                        ...prevParams,
                        customFilterModel: {
                            items: [],
                        },
                    };
                });
            }
        } else {
            setParams((prevParams) => {
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: [{ columnField: field, id: Date.now(), operatorValue: "contains", value: selectedValuesState }],
                    },
                };
            });
        }
    };

    // useEffect(() => {
    //     setSelectedValuesState((values.find((filter) => filter.columnField === field)?.value as unknown as string[]) || []);
    // }, [values]);

    useEffect(() => {
        // @ts-ignore;
        const selectedValues: string[] = (values?.filter((value) => value.columnField === field)).map((el) => el.value)[0];
        setSelectedValuesState(selectedValues || []);
    }, []);

    useEffect(() => {
        if (values.length === 0) {
            setSelectedValuesState([]);
        }
    }, [values]);

    const formatStringWithUnderscore = (str: string) => {
        if (str.includes("_")) return str.replace(/_/g, " ");
        return str;
    };

    return (
        <FormControl size="small" fullWidth sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Select
                className={styles.select}
                onChange={handleChange}
                size="small"
                multiple
                // @ts-ignore
                value={selectedValuesState}
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <span style={{ color: "grey" }}>Select...</span>;
                    }
                    if (selected.length > 4) {
                        return selected.slice(0, 4) + "...";
                    }
                    return selected + "";
                }}
                MenuProps={isMobile ? { PaperProps: { style: { maxHeight: "90vh" } } } : {}}
                onClose={handleSubmit}
            >
                {filterOptions?.map((option) => (
                    <MenuItem value={option} sx={{ fontSize: "12px", textTransform: "capitalize" }} key={option}>
                        {formatStringWithUnderscore(option.toString())}
                    </MenuItem>
                ))}
            </Select>
            <Box sx={{ width: "100%", maxWidth: "388px", marginTop: 1, display: "flex", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap", gap: "8px" }}>
                {selectedValuesState.map((chip) => {
                    return (
                        <Chip
                            className={styles.chip}
                            label={chip}
                            onDelete={() => onDelete(chip)}
                            key={chip}
                            size="small"
                            sx={{
                                backgroundColor: theme !== "dark" ? dividerColor : "#e7e7e8",
                                color: theme === "dark" && "#000",
                                "& .MuiChip-deleteIcon": {
                                    color: theme === "dark" && "grey",
                                    ":hover": {
                                        color: "#ef4438ed",
                                    },
                                },
                                mx: 0.5,
                            }}
                        />
                    );
                })}
            </Box>
        </FormControl>
    );
};

export default MultiSelectFilter;
