import axios from "axios";
import { UserUniverses } from "@/models/universe";

export const getUniverses = async () => {
    try {
        const res = await axios.get("/API/main_universes");

        return res.data.sort((x, y) => (x.id === 7 ? -1 : y.id === 7 ? 1 : 0));
    } catch (err) {
        throw err;
    }
};

export const getUserUniverses = async (): Promise<any> => {
    try {
        // if (force_prod) {
        //     const res = await axios.get<UserUniverses>("/API/v2/get_user_universes?v2b=true");
        //     if (!res.data.regions) throw new Error("No user identified.");
        //     return res.data;
        // }
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve(data);
            return res;
        }
        const res = await axios.get<UserUniverses>("/API/v2/get_user_universes?v2b=true");
        if (!res.data.regions) throw new Error("No user identified.");
        return res.data;
    } catch (err) {
        throw err;
    }
};

const data = {
    selected: null,
    regions: {
        "2:Nordic": [
            { name: "Sweden", id: 1 },
            { name: "Norway", id: 4 },
            { name: "Denmark", id: 5 },
            { name: "Finland", id: 6 },
        ],
        "10:Europe": [
            { name: "Nordic", id: 2 },
            { name: "UK and Ireland", id: 9 },
            { name: "Europe", id: 10 },
            { name: "Benelux", id: 11 },
            { name: "Germany", id: 12 },
            { name: "France", id: 13 },
            { name: "Spain", id: 14 },
            { name: "Italy", id: 15 },
            { name: "Switzerland", id: 18 },
            { name: "Austria", id: 19 },
            { name: "Portugal", id: 20 },
            { name: "Poland", id: 28 },
            { name: "STOXX EUROPE 600", id: 53652 },
        ],
        "9:UKandIreland": [
            { name: "UK", id: 3 },
            { name: "Ireland", id: 17 },
            { name: "FTSE 100", id: 4547 },
        ],
        "7:US&Canada": [
            { name: "US & Canada", id: 7, US_stocks: true },
            { name: "US", id: 1940 },
            { name: "Canada", id: 1973 },
            { name: "Nasdaq100", id: 4745 },
            { name: "S&P 500", id: 53651 },
            { name: "SPACs", id: 53653 },
        ],
        "11:Benelux": [
            { name: "Belgium", id: 16 },
            { name: "Luxembourg", id: 21 },
            { name: "Netherlands", id: 22 },
        ],
        "23:Asia/Pacific": [
            { name: "Asia / Pacific", id: 23 },
            { name: "India", id: 27 },
            { name: "Japan", id: 73817 },
            { name: "Australia", id: 76157 },
            { name: "Hong Kong", id: 78302 },
            { name: "China", id: 108600 },
        ],
        "24:Africa/MiddleEast": [{ name: "Africa / Middle East", id: 24 }],
        "25:LatinAmericaandCaribbean": [{ name: "Latin America and Caribbean", id: 25 }],
        "51640:ETFs": [{ name: "ETFs", id: 51640 }],
    },
    portfolios: [{ name: "testing", id: 126845, isLinked: false, isManual: true }],
};
