// import styles from "./SubscriptionPlans.module.scss";
import { FC, useEffect, useState } from "react";
// import Modal from "@/components/modal/Modal";
// import StripeForm from "@/components/stripe-form/StripeForm";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Subscription } from "@/models/subscription";
// import { useRecoilValue } from "recoil";
// import { lighterBackgroundColorState, themeState } from "@/lib/store";

const SubscriptionPlans: FC<{ data: Subscription }> = ({ data }) => {
    return null;
    // const theme = useRecoilValue(themeState);
    // const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    // const [modal, setModal] = useState(false);
    // const [selected, setSelected] = useState<number>();
    // const [stripe, setStripe] = useState<PromiseLike<Stripe>>();
    // const [clientSecret, setClientSecret] = useState<string>();

    // useEffect(() => {
    //     setStripe(loadStripe(data.payment_data.stripe_public_key));
    //     setClientSecret(data.payment_data.stripe_client_secret);
    //     setModal(true);
    // }, [data]);

    // return (
    //     <Modal isOpen={modal} onClose={setModal} noCloseButton zIndex={5}>
    //         <div className={styles.SubscriptionPlans} style={{ color: theme === "dark" ? "rgb(255, 255, 255)" : "rgb(48, 49, 61)" }}>
    //             <p>{data.header}</p>
    //             <p>{data.sub_header}</p>
    //             <p>Select a plan:</p>
    //             <div>
    //                 {data.plans.map((plan, i) => (
    //                     <div key={plan.plan_id} onClick={() => setSelected(i)} style={{ borderColor: i === selected ? "rgb(49, 171, 166)" : lighterBackgroundColor }}>
    //                         <div>
    //                             <p>{plan.plan_interval}</p>
    //                         </div>
    //                         <div>
    //                             <p>
    //                                 {plan.currency_symbol} <span>{plan.plan_price / (plan.plan_interval === "annually" ? 12 : 1)}</span>/month
    //                             </p>
    //                             <p>Charged {plan.plan_interval}</p>
    //                         </div>
    //                     </div>
    //                 ))}
    //             </div>
    //             <p>{data.plans[selected]?.plan_header}</p>
    //             <p style={{ color: theme === "dark" ? "rgb(179, 179, 179)" : "rgb(109, 110, 120)" }}>{data.plans[selected]?.plan_desc}</p>
    //         </div>
    //         <Elements
    //             key={clientSecret}
    //             stripe={stripe}
    //             options={{
    //                 clientSecret,
    //                 appearance: { theme: theme === "dark" ? "night" : "stripe" },
    //             }}
    //         >
    //             <StripeForm data={data} selected={selected} onClose={setModal} />
    //         </Elements>
    //     </Modal>
    // );
};

export default SubscriptionPlans;
