import { classNames, generateNonce } from "@/lib/utils";
import { Box, Button, Typography } from "@mui/material";
import styles from "./SocialAuthButtons.module.scss";
import { useRecoilValue } from "recoil";
import { textColorState, themeState } from "@/lib/store";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { sendUserDataToBackend, sendUserDataToBackendApple } from "@/controllers/auth";
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import axios from "axios";

const SocialAuthButtons = () => {
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);
    const router = useRouter();

    let appleOptions: SignInWithAppleOptions = {
        clientId: "com.ortex.applications",
        redirectURI: "https://app.ortex.com",
        scopes: "email name",
        state: "User authentication request",
        nonce: generateNonce(),
    };

    const vertexAuth = async (idToken) => {
        const vertexUrl = `${process.env.NEXT_PUBLIC_API_URL_VERTEX}/users/auth/cognito/`;
        const ortexUrl = `${process.env.NEXT_PUBLIC_API_URL}/users/auth/cognito/`;

        const vertexPost = axios.post(vertexUrl, idToken, {
            withCredentials: true,
            xsrfCookieName: "vertex_csrftoken",
            headers: {
                "Content-Type": "application/json",
            },
            params: null,
        });

        const ortexPost = axios.post(ortexUrl, idToken, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: null,
        });

        const [vertexResponse, ortexResponse] = await Promise.all([vertexPost, ortexPost]);

        console.log("vertexResponse", vertexResponse);
        console.log("ortexResponse", ortexResponse);

        const vertexUserId = vertexResponse?.data?.userId;
        const ortexUserId = ortexResponse?.data?.userId;

        if (vertexUserId && ortexUserId) {
            localStorage.setItem("vertexUser", vertexUserId);
            localStorage.setItem("ortexUser", ortexUserId);

            router.reload();
        } else {
            console.error("Error saving auth tokens");
        }
    };

    async function signIn() {
        await GoogleAuth.signOut();
        const user = await GoogleAuth.signIn();

        if (user) {
            sendUserDataToBackend(user).then((res) => {
                if (res.success) {
                    router.reload();
                } else {
                    toast("An error has occurred. Please try again later.", { type: "error" });
                }
            });
            //vertexAuth(user.authentication?.idToken);
        }
    }

    async function appleSignIn() {
        await SignInWithApple.authorize(appleOptions)
            .then((result: SignInWithAppleResponse) => {
                if (result) {
                    sendUserDataToBackendApple(result).then((res) => {
                        if (res.success) {
                            router.reload();
                        } else {
                            toast("An error has occurred. Please try again later.", { type: "error" });
                        }
                    });
                    //vertexAuth(result.response?.identityToken);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Box>
            <Button className={classNames(styles.socialBtns, styles.googleBtn)} onClick={signIn} style={{ color: textColor }}>
                <span></span>
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                    Continue with Google
                </Typography>
            </Button>
            <Button className={classNames(styles.socialBtns, styles.appleBtn, theme !== "light" && styles.appleDarkTheme)} onClick={appleSignIn} style={{ color: textColor }}>
                <span></span>
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                    Continue with Apple
                </Typography>
            </Button>
        </Box>
    );
};

export default SocialAuthButtons;
