import { useEffect, useState } from "react";
import styles from "./SelectFilter.module.scss";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import { DataGridFilterValues, DataGridHeaders } from "@/models/datagrid";

type Props = {
    setParams: (params: any) => void;
    values?: Array<DataGridFilterValues>;
    header: DataGridHeaders;
    initialParams?: any[];
};

const SelectFilter = ({ header, setParams, values, initialParams }: Props) => {
    const { field, filterOptions } = header;
    const isMobile = useMediaQuery("(max-width: 768px)");

    const selectedValue = values?.find((value) => value.columnField === field)?.value;

    const [selectValue, setSelectValue] = useState(selectedValue || "");

    const onChangeSelect = ({ target: { value } }) => {
        setSelectValue(value);

        const formattedValue = { columnField: field, id: Date.now(), operatorValue: "equals", value };

        setParams((prevParams) => {
            const oldParams = prevParams?.customFilterModel?.items.filter((i) => i.columnField !== field) || [];

            return {
                ...prevParams,
                customFilterModel: {
                    items: [...oldParams, formattedValue],
                },
            };
        });
    };

    useEffect(() => setSelectValue(selectedValue || ""), [selectedValue]);

    const formatStringWithUnderscore = (str: string) => {
        if (str.includes("_")) return str.replace(/_/g, " ");
        return str;
    };

    return (
        <FormControl size="small" fullWidth sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Select
                className={styles.select}
                onChange={onChangeSelect}
                size="small"
                value={selectValue || ""}
                displayEmpty
                renderValue={(value) => (value === "" ? "Select an option" : value.toString())}
                MenuProps={isMobile ? { PaperProps: { style: { maxHeight: "90vh" } } } : {}}
            >
                {filterOptions.map((option) => (
                    <MenuItem value={option} sx={{ fontSize: "12px", textTransform: "capitalize" }} key={option}>
                        {formatStringWithUnderscore(option.toString())}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectFilter;
