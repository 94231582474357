import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemContentProps, TreeItemProps, treeItemClasses, useTreeItem } from "@mui/lab/TreeItem";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import styles from "./UniverseSelect.module.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { universesState, userState, textColorState, dividerState, universeState } from "@/lib/store";
import { updateUserSettings } from "@/controllers/auth";
import { Settings } from "@/models/auth";
import { useRouter } from "next/router";
import Select from "@mui/material/Select";
import { FormControl, Tooltip, Typography, Divider } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

let timeout: NodeJS.Timeout;

import { useState, useEffect, forwardRef } from "react";
import { Universe } from "@/models/universe";
import { useUpdateEffect } from "react-use";
import clsx from "clsx";

const SelectTreeView = (props) => {
    const newProps = { ...props };
    delete newProps.selected;
    return <TreeView {...newProps} />;
};

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

const CustomContent = forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        handleSelection(event);
    };

    const isCategory = label !== "Regions" && label !== "Watchlists" && label !== "Portfolios";

    return (
        <Box
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
            sx={{ cursor: isCategory ? "pointer" : "default !important" }}
        >
            <Box onClick={handleExpansionClick} className={classes.iconContainer} sx={{ cursor: "pointer !important" }}>
                {icon}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%", p: 0.5, pr: 0 }} onClick={handleSelectionClick}>
                <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
                    {label}
                </Typography>
                {isCategory && <Box component={ArrowCircleRightIcon} sx={{ ml: 1 }} color="inherit" />}
            </Box>
        </Box>
    );
});

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    maxWidth: 250,
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.5,
        },
    },
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.primary,
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "&.Mui-expanded": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: "var(--tree-view-color)",
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: "inherit",
            color: "inherit",

            ".MuiSvgIcon-root": {
                opacity: 0,
            },

            "&:hover": {
                ".MuiSvgIcon-root": {
                    opacity: 1,
                },
            },
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 10,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const StyledTreeItem = (props: TreeItemProps) => {
    return <StyledTreeItemRoot ContentComponent={CustomContent} {...props} />;
};

interface UniverseSelectProps {
    pageHandleOnChange?: (value: string) => void;
    showSelect?: boolean;
    newUniverse?: string;
    initialSet?: boolean;
}

const UniverseSelect = ({ pageHandleOnChange, showSelect, newUniverse, initialSet }: UniverseSelectProps) => {
    const [user, setUser] = useRecoilState(userState);
    const universes = useRecoilValue(universesState);
    const [universe, setUniverse] = useRecoilState(universeState);
    const textColor = useRecoilValue(textColorState);
    const dividerColor = useRecoilValue(dividerState);
    const [flattenedUniverses, setFlattenedUniverses] = useState<Universe[]>([]);
    const [loading, setLoading] = useState(false);

    const [selectOpen, setSelectOpen] = useState(false);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltip = (bool: boolean) => {
        selectOpen ? setTooltipOpen(false) : setTooltipOpen(bool);
    };

    const { push } = useRouter();

    const selectedUniverse = (id) => flattenedUniverses.find((u) => u.id === Number(id));
    const [nodeSelected, setNodeSelected] = useState(universe);

    useEffect(() => {
        universes && setFlattenedUniverses(Object.values(universes.regions).flat().concat(universes.portfolios));
    }, [universes]);

    useEffect(() => {
        !pageHandleOnChange && setNodeSelected(universe);
    }, [universe, universes]);

    useEffect(() => {
        initialSet && pageHandleOnChange(`${universe.id}`);
        setNodeSelected(user.settings.universe);
    }, []);

    useUpdateEffect(() => {
        newUniverse && setNodeSelected(selectedUniverse(newUniverse));
    }, [newUniverse]);

    const handleClose = () => {
        setSelectOpen(false);
        handleTooltip(false);
    };

    const handleOpen = () => {
        setSelectOpen(true);
        handleTooltip(false);
    };

    const onUniverseChange = (event, value) => {
        if (value === "edit") {
            push("/portfolio-admin");
            handleClose();
        } else if (Number(value)) {
            if (pageHandleOnChange) {
                pageHandleOnChange(value);
                setNodeSelected(selectedUniverse(value));
                handleClose();
            } else {
                setLoading(true);
                const newUniverse = selectedUniverse(value);

                if (!newUniverse) return;

                const settings: Settings = { ...user.settings, universe: { ...user.settings.universe, ...newUniverse } };

                if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
                    updateUserSettings(settings)
                        .then((res) => {
                            setLoading(false);
                            setUser({ ...user, settings: { ...user.settings, universe: res } });
                            setUniverse(res);
                            setNodeSelected(res);
                        })
                        .catch((err) => {
                            console.log(err, "err");
                        });
                } else {
                    setNodeSelected(settings.universe);
                }

                setLoading(false);

                handleClose();
            }
        }
    };

    const NestedTreeItems = (items) => {
        const universeNames = (name) => {
            const regionId = name.split(":")[0];
            const regionName = name.split(":")[1];

            return universes.regions[name].find((region) => region.id === Number(regionId))?.name || regionName;
        };

        return (
            items &&
            Object.keys(items).map((universe) => {
                const regionList = items[universe];
                const parentId = universe.split(":")[0];
                const parentName = regionList.find((item) => item.id.toString() === parentId)?.name;

                if (parentName) {
                    return (
                        <StyledTreeItem key={universe} nodeId={parentId} label={universeNames(universe)}>
                            {items[universe]
                                .filter((item) => item.id !== Number(parentId))
                                .map((region) => {
                                    return (
                                        <StyledTreeItem key={region.id} nodeId={region.id} label={region.name}>
                                            {universe !== region.id + ":" + region.name.replaceAll(" ", "") &&
                                                items[region.id + ":" + region.name.replaceAll(" ", "")]?.map((reg) => {
                                                    return <StyledTreeItem key={region.id + reg.id} nodeId={reg.id} label={reg.name}></StyledTreeItem>;
                                                })}
                                        </StyledTreeItem>
                                    );
                                })}
                        </StyledTreeItem>
                    );
                } else return null;
            })
        );
    };

    const getSelectOptions = (universeCategory: string) => {
        const categorySelected =
            universeCategory === "Regions"
                ? universes.regions
                : universeCategory === "Portfolios"
                ? universes.portfolios.filter((portfolio) => portfolio.isLinked || portfolio.isManual)
                : universes.portfolios.filter((portfolio) => !portfolio.isLinked && !portfolio.isManual);

        return (
            <StyledTreeItem key={universeCategory} nodeId={universeCategory} label={universeCategory}>
                {!pageHandleOnChange && universeCategory !== "Regions" && <StyledTreeItem key={"Edit " + universeCategory} nodeId={"edit"} label={"Edit " + universeCategory}></StyledTreeItem>}
                {universeCategory === "Regions"
                    ? NestedTreeItems(categorySelected)
                    : (categorySelected as Universe[]).map((universe) => <StyledTreeItem key={universe?.id} nodeId={JSON.stringify(universe?.id)} label={universe?.name}></StyledTreeItem>)}
            </StyledTreeItem>
        );
    };

    return (
        <div className={styles.UniverseSelectContainer}>
            <Tooltip arrow title="Universe Select" open={tooltipOpen}>
                <FormControl
                    fullWidth
                    size="small"
                    sx={{
                        width: "min-content",
                        "& .MuiInput-underline::before": {
                            borderBottom: `1px solid ${textColor}`,
                        },
                        "& .MuiInput-underline::after": {
                            borderBottom: `1px solid ${textColor}`,
                        },
                        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                            borderBottom: `2px solid ${textColor}`,
                        },
                        ".MuiSvgIcon-root ": {
                            fill: textColor,
                        },
                    }}
                >
                    <Select
                        open={selectOpen}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onMouseEnter={() => handleTooltip(true)}
                        onMouseLeave={() => handleTooltip(false)}
                        variant={showSelect ? "standard" : "outlined"}
                        sx={{
                            backgroundColor: "transparent",
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiSelect-select": {
                                padding: showSelect ? "0 0 5px 0 !important" : pageHandleOnChange ? "inherit" : "8.5px 5px !important",
                                display: "flex",
                                alignItems: "center",
                            },
                            "& .MuiSelect-icon": {
                                fontSize: pageHandleOnChange && "20px",
                                transform: "none",
                            },
                            width: showSelect ? "150px" : pageHandleOnChange ? "25px" : "40px",
                        }}
                        displayEmpty
                        renderValue={(value: string): React.ReactNode => (loading ? "Loading..." : ((showSelect && (nodeSelected ? nodeSelected?.name : "Select a Universe")) as string))}
                        IconComponent={TravelExploreIcon}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                        }}
                    >
                        {!showSelect && nodeSelected?.name && (
                            <Box sx={{ display: { xs: "initial", sm: "none" } }}>
                                <Typography variant="overline" sx={{ justifyContent: "center", ml: 2, mr: 2, mb: 1 }} color="primary.main" display="flex" alignItems="center">
                                    {nodeSelected?.name}
                                </Typography>
                                <Divider variant="middle" sx={{ borderColor: dividerColor, mb: 1 }} />
                            </Box>
                        )}

                        <SelectTreeView
                            onNodeSelect={onUniverseChange}
                            selected={nodeSelected?.name || ""}
                            defaultCollapseIcon={<MinusSquare />}
                            defaultExpandIcon={<PlusSquare />}
                            defaultEndIcon={<CloseSquare />}
                            sx={{ maxHeight: pageHandleOnChange ? 200 : 500, flexGrow: 1, overflowY: "auto" }}
                        >
                            {universes?.portfolios?.length > 0 && getSelectOptions("Watchlists")}
                            {universes?.portfolios?.length > 0 && getSelectOptions("Portfolios")}
                            {universes?.regions && getSelectOptions("Regions")}
                        </SelectTreeView>
                    </Select>
                </FormControl>
            </Tooltip>
            <Box onClick={() => handleOpen()} onMouseEnter={() => handleTooltip(true)} onMouseLeave={() => handleTooltip(false)} sx={{ cursor: "pointer" }}>
                <Typography variant="overline" color="primary.main" sx={{ alignSelf: "center", display: { xs: "none", sm: "initial" } }}>
                    {loading ? "Loading..." : nodeSelected?.name}
                </Typography>
            </Box>
        </div>
    );
};

export default UniverseSelect;
