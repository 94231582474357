import { FC } from "react";
import { useRecoilValue } from "recoil";
import { textColorState, themeState, whiteDarkerState } from "@/lib/store";
import { VscChromeClose } from "react-icons/vsc";
import { Drawer, IconButton, useMediaQuery } from "@mui/material";
import Color from "@/lib/Color";

interface PropsTypes {
    isOpen: boolean;
    onClose: (close: boolean) => void;
    noCloseButton?: boolean;
    zIndex?: number;
    width?: number;
    position?: boolean;
    hidden?: boolean;
}

const Offcanvas: FC<PropsTypes> = ({ isOpen, onClose, children, hidden }) => {
    const isLaptop = useMediaQuery("(min-width: 1280px)");
    const theme = useRecoilValue(themeState);
    const whiteDarker = useRecoilValue(whiteDarkerState);
    const textColor = useRecoilValue(textColorState);
    const greyTextColor = theme === "dark" ? whiteDarker : (new Color(textColor).brighten(0.5).get() as string);

    return (
        <Drawer
            hideBackdrop
            sx={{ width: "100vw" }}
            PaperProps={{
                sx: {
                    position: hidden && "relative",
                    zIndex: hidden && -1,
                    visibility: hidden && "hidden",
                    width: "100%",
                    overflow: isLaptop ? "hidden" : "auto",
                    marginTop: "env(safe-area-inset-top)",
                    maxHeight: "calc(100% - env(safe-area-inset-top))",
                },
            }}
            open={isOpen}
            onClose={onClose}
        >
            <div style={{ position: "absolute", right: 0, zIndex: 1001 }}>
                <IconButton size="large" onClick={() => onClose(false)} aria-label="Close Filters">
                    <VscChromeClose style={{ color: greyTextColor }} />
                </IconButton>
            </div>
            {children}
        </Drawer>
    );
};

export default Offcanvas;
