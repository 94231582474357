import { backgroundColorState, blackLighterState, greyDarkerState, textColorState, themeState, whiteDarkerState } from "@/lib/store";
import { FindInstruments } from "@/models/find-instruments";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import styles from "./Autocomplete.module.scss";
import Image from "next/image";
import { ImageNotSupported } from "@mui/icons-material";
import { getMissingLogo } from "@/controllers/stock";
import { Typography } from "@mui/material";

let searchTimeout: NodeJS.Timeout;

interface Props {
    instruments: FindInstruments[];
    onClick?: (instrument: FindInstruments) => void;
}

const Autocomplete = ({ instruments, onClick }: Props) => {
    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const blackLighter = useRecoilValue(blackLighterState);
    const whiteDarker = useRecoilValue(whiteDarkerState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const [instrumentsLocal, setInstrumentsLocal] = useState<FindInstruments[]>([]);
    const [imgError, setImgError] = useState<number[]>([]);

    useEffect(() => {
        setInstrumentsLocal(instruments);

        return () => setImgError((state) => []);
    }, [instruments]);

    const handleImgError = (security_id: number) => {
        setImgError((state) => [...state, security_id]);
        if (security_id) getMissingLogo(security_id);
    };

    return (
        <div className={styles.HeaderAutocomplete} style={{ display: instrumentsLocal?.length > 0 ? "block" : "none" }}>
            <style jsx>{`
                .${styles.instrument}:hover {
                    background-color: ${theme === "dark" ? blackLighter : whiteDarker} !important;
                }

                .${styles.instrumentsContainer}::-webkit-scrollbar {
                    background-color: ${backgroundColor};
                }
            `}</style>
            <div className={styles.instrumentsContainer}>
                {instrumentsLocal.map((instrument, index) => (
                    <div key={instrument.value} className={styles.instrument} style={{ borderBottom: `1px solid ${theme === "dark" ? greyDarker : whiteDarker}`, backgroundColor }} onClick={() => onClick(instrument)}>
                        <div className={styles.instrumentWrapper}>
                            <div className={styles.logo} style={{ backgroundColor: theme === "dark" ? "#c0c0c0" : "transparent", borderRadius: "4px" }}>
                                {!imgError.includes(instrument.security_id) ? (
                                    <Image
                                        unoptimized
                                        src={instrument.security_id ? `https://logo.ortex.com/${instrument.security_id}r.png` : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                        alt={`${instrument.name} Logo`}
                                        layout="fill"
                                        objectFit="contain"
                                        priority
                                        onError={() => handleImgError(instrument.security_id)}
                                    />
                                ) : (
                                    <ImageNotSupported />
                                )}
                            </div>
                            <div className={styles.instrumentContent}>
                                <div className={styles.instrumentInfo}>
                                    <Typography variant="body2" className={styles.ticker}>
                                        {instrument.exchange}:{instrument.ticker}
                                    </Typography>
                                    <Typography variant="body2" className={styles.name}>
                                        {instrument.name}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Autocomplete;
