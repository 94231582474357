import axios from "axios";
import { List, ListQueryParams } from "@/models/table";
import { TradingSignals, TradingSignalsHeaders } from "@/models/trading-signals";

export const getTradingSignalsOld = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, next_page_url } = params;

    const url = next_page_url || `/API/v2/${universe_id}/alpha/list/signals`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<TradingSignals, TradingSignalsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getTradingSignals = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, data, next_page_url } = params;

    const url = next_page_url || `/API/v2/90446/alpha/list/signals_v2`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, sig_filters: data?.filters, type: data?.type };

    try {
        const res = await axios.get<List<TradingSignals, TradingSignalsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getDataGridTradingSignals = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/interface/api/universe/${universe_id}/signals/list${data?.show_all ? "?show_all" : ""}`;

    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, sig_filters: data?.filters, type: data?.type, days_back: data?.days_back };

    try {
        const res = await axios.get<List<TradingSignals, TradingSignalsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getDataGridHistoricalTradingSignals = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/API/v2/${universe_id}/alpha/list/signals_v2?dgformat&initial${data?.show_all ? "&show_all" : ""}`;

    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, sig_filters: data?.filters, type: data?.type, days_back: data?.days_back };

    try {
        const res = await axios.get<List<TradingSignals, TradingSignalsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockDataGridTradingSignals = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/API/v2/id/${universe_id}/alpha/list/signals_v2?dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, sig_filters: data?.filters };

    try {
        const res = await axios.get<List<TradingSignals, TradingSignalsHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getTradingSignalsTypes = async () => {
    try {
        const res = await axios.get("/API/v2/signals_v2/filters");

        return res.data;
    } catch (err) {
        throw err;
    }
};
