import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useRecoilValue } from "recoil";
import { backgroundColorState, textColorState } from "@/lib/store";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuListItems } from "./utils";
import SideMenu from "../side-menu/SideMenu";

type Anchor = "top" | "left" | "bottom" | "right";
let timeout: NodeJS.Timeout;

export default function SwipeableTemporaryDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });

    const sideBarRef = React.useRef<HTMLDivElement>(null);

    const backgroundColor = useRecoilValue(backgroundColorState);
    const textColor = useRecoilValue(textColorState);

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor: Anchor) => (
        <Box
            style={{
                backgroundColor,
                overflow: "hidden",
            }}
            sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
                "@media (max-width: 767px)": {
                    width: anchor === "top" || anchor === "bottom" ? "auto" : 220,
                },
                "@media (max-width: 400px)": {
                    width: anchor === "top" || anchor === "bottom" ? "auto" : 190,
                }
            }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <style>
                {`
                    .MuiListItemIcon-root > div {
                        font-size: 25px;
                    }
                `}
            </style>
            <MenuListItems />
        </Box>
    );

    return (
        <>
            <SideMenu />
            <div>
                {(["left"] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                        <IconButton onClick={toggleDrawer(anchor, true)}>
                            <MenuIcon style={{ color: textColor }} />
                        </IconButton>

                        <SwipeableDrawer
                            PaperProps={{
                                sx: {
                                    backgroundColor: backgroundColor,
                                }
                            }}
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                            id="menu"
                            ref={sideBarRef}
                        >
                            <Box id="list-container">{list(anchor)}</Box>
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
}
