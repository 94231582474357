import { subscriptionOfferingsState, userDataState } from "@/lib/store";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

const useHandleAppUpgrade = () => {
    const [offerings, setOfferings] = useRecoilState(subscriptionOfferingsState);
    const userData = useRecoilValue(userDataState);

    const handleAppUpgrade = useCallback(async () => {
        console.log("Purchasing package: ", offerings);
        try {
            const purchaseResponse = await Purchases.purchasePackage({
                aPackage: offerings,
            });
            await Purchases.setDisplayName({ displayName: userData?.first_name + " " + userData?.last_name });
            await Purchases.setEmail({ email: userData.email });

            console.log("Purchase successful:", purchaseResponse);
        } catch (error) {
            console.error("Purchase failed:", error);
        }
    }, [offerings, userData]);

    return { handleAppUpgrade };
};

export default useHandleAppUpgrade;
