import styles from "./LoadingSpinner.module.scss";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { greyMediumState } from "@/lib/store";

interface PropsTypes {
    borderWidth?: number;
    color?: string;
    size?: number;
}

const LoadingSpinner: FC<PropsTypes> = ({ borderWidth = 8, color, size = 80 }) => {
    const greyMedium = useRecoilValue(greyMediumState);

    return (
        <div className={styles.LoadingSpinner} style={{ height: size, width: size }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <style jsx>
                {`
                    .${styles.LoadingSpinner} div {
                        margin: ${borderWidth}px;
                        width: ${size - borderWidth * 2}px;
                        height: ${size - borderWidth * 2}px;
                        border-color: ${color || greyMedium} transparent transparent transparent;
                        border-width: ${borderWidth}px;
                        z-index: 10;
                    }
                `}
            </style>
        </div>
    );
};

export default LoadingSpinner;
