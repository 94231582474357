import { NextPage } from "next";
import styles from "./PaymentDrawer.module.scss";

import * as React from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Context } from "@/lib/Context";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";

import { greyState, ortexColorState, textColorState, paymentModalOpenState } from "@/lib/store";

import { Browser } from "@capacitor/browser";

import { Capacitor } from "@capacitor/core";
import Image from "next/image";
import { Chip, Drawer, IconButton } from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { isOrtexApp } from "@/lib/utils";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";

interface PropsTypes {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const PaymentDrawer: React.FC<PropsTypes> = ({ open, setOpen }) => {
    const { openSubscriptionForm } = useOpenSubscriptionForm();

    const grey = useRecoilValue(greyState);
    const textColor = useRecoilValue(textColorState);
    const ortexColor = useRecoilValue(ortexColorState);

    const openAppBrowserTerms = async () => {
        await Browser.open({ url: "https://public.ortex.com/terms-and-conditions" });
    };

    const openAppBrowserPrivacy = async () => {
        await Browser.open({ url: "https://public.ortex.com/privacypolicy" });
    };
    const listItems = ["Live short interest", "ORTEX trading signals", "Options data and analysis", "Stock stamps", "Insider data and analysis", "Live order book", "ORTEX stock scores"];

    const [paymentModalOpen, setPaymentModalOpen] = useRecoilState(paymentModalOpenState);

    const handleUpgrade = () => {
        setPaymentModalOpen(true);
        openSubscriptionForm();
        setTimeout(function () {
            setPaymentModalOpen(false);
        }, 10000);
    };

    return (
        <Drawer
            variant="temporary"
            ModalProps={{
                keepMounted: false,
            }}
            anchor="bottom"
            sx={{ height: "100vh" }}
            PaperProps={{ sx: { height: "100%", display: "flex", alignItems: "space-around", justifyContent: "space-between", paddingTop: "env(safe-area-inset-top)", paddingBottom: "env(safe-area-inset-bottom)" } }}
            open={open}
        >
            <Box sx={{ height: "25%", textAlign: "center", width: "100%" }}>
                <Image unoptimized src="/assets/ortex-logo-v.png" alt="ortex-logo" width="180" height="100" />
                <Typography sx={{ fontSize: "24px", marginTop: 2, fontWeight: 800 }}>
                    Try <Chip size="small" sx={{ color: "#fff", fontWeight: 500, paddingX: 1, paddingY: 1.5 }} color="primary" label={"ADVANCED"} /> for free
                </Typography>
                <IconButton sx={{ color: textColor, position: "absolute", top: 0, right: 10, paddingTop: "env(safe-area-inset-top)" }} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box className={styles.list} sx={{ height: "45%" }}>
                <IconButton
                    disabled
                    sx={{
                        width: "45px",
                        height: "45px",
                        marginX: "auto",
                        backgroundColor: "black",
                        color: "#fff",
                        boxShadow: "0px 3px 6px #00000029",
                        ":disabled": {
                            backgroundColor: "black",
                            color: "#fff",
                        },
                    }}
                >
                    <LockIcon sx={{ fontSize: "24px" }} />
                </IconButton>
                <Typography sx={{ gridColumn: "2/4", fontSize: "24px", fontWeight: 800 }}>Unlock everything</Typography>
                <Typography sx={{ gridColumn: "2/4", fontSize: "16px" }}>Elevate your trading with ORTEX full access Advanced subscription.</Typography>
                <Typography sx={{ gridColumn: "2/4", fontSize: "14px", fontWeight: "300", marginTop: 3 }}>Includes</Typography>
                {listItems.map((item) => {
                    return (
                        <Box sx={{ gridColumn: "2/4", display: "flex", alignItems: "center", justifyContent: "flex-start" }} key={item}>
                            <IconButton
                                key={item}
                                disabled
                                size="small"
                                sx={{
                                    width: "25px",
                                    height: "25px",
                                    backgroundColor: "black",
                                    color: "#fff",
                                    boxShadow: "0px 3px 6px #00000029",
                                    ":disabled": {
                                        backgroundColor: "black",
                                        color: "#fff",
                                    },
                                }}
                            >
                                <CheckIcon sx={{ fontSize: "18px" }} />
                            </IconButton>
                            <Typography sx={{ fontWeight: 700, fontSize: "18px", marginLeft: 2 }}>{item}</Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box sx={{ height: "15%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Button
                    size="large"
                    variant="contained"
                    color="inherit"
                    sx={{
                        width: "354px",
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "30px",
                        textTransform: "initial",
                        boxShadow: "0px 3px 3px #00000061",
                        "&:hover": {
                            backgroundColor: "#000",
                        },
                        "&:active": {
                            backgroundColor: "#000",
                        },
                        "&:disabled": {
                            backgroundColor: "#000",
                        },
                    }}
                    onClick={handleUpgrade}
                >
                    {paymentModalOpen ? <LoadingSpinner size={20} borderWidth={2} color={ortexColor} /> : "Start my 7-day free trial now"}
                </Button>
            </Box>
            <Box sx={{ height: "5%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                    variant="text"
                    color="inherit"
                    sx={{ color: grey, textTransform: "initial", padding: 0.5, marginRight: 2 }}
                    href={isOrtexApp() ? undefined : "https://public.ortex.com/privacypolicy"}
                    target="_blank"
                    onClick={() => (isOrtexApp() ? openAppBrowserPrivacy() : undefined)}
                >
                    Privacy Policy
                </Button>
                <Button
                    variant="text"
                    color="inherit"
                    sx={{ color: grey, textTransform: "initial", padding: 0.5, marginLeft: 2 }}
                    href={isOrtexApp() ? undefined : "https://public.ortex.com/terms-and-conditions"}
                    target="_blank"
                    onClick={() => (isOrtexApp() ? openAppBrowserTerms() : undefined)}
                >
                    Terms & Conditions
                </Button>
            </Box>
        </Drawer>
    );
};

export default PaymentDrawer;
