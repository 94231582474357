import { FC } from "react";
import styles from "./StackedBar.module.scss";
import { Tooltip } from "@mui/material";

interface PropsTypes {
    max?: number;
    percentage: number;
}

const StackedBar: FC<PropsTypes> = ({ max = 100, percentage }) => {
    return (
        <>
            <Tooltip arrow title={percentage.toFixed(2)}>
                <div className={styles.StackedBar}>
                    <div style={{ width: `${(Math.min(Math.abs(percentage), max) / max) * 100}%` }} className="StackedBar_positive">
                        &nbsp;
                    </div>
                    <div style={{ width: `${100 - (Math.min(Math.abs(percentage), max) / max) * 100}%` }} className="StackedBar_negative">
                        &nbsp;
                    </div>
                </div>
            </Tooltip>
        </>
    );
};

export default StackedBar;
