import { useState, useEffect, useContext } from 'react';
import { Context } from '@/lib/Context';
import { useRecoilValue } from 'recoil';
import { userState } from '@/lib/store';
import { usExchanges } from '@/lib/vars';
import { priceDiffPercentage, sendWsMessage } from '@/lib/utils';

export const useTickerWebSocket = (tickerStock: any) => {
    const { multiWebSocket, ortexWebSocket, obookWebSocket } = useContext(Context);
    const [instrument, setInstrument] = useState<any>(tickerStock);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const user = useRecoilValue(userState);

    const updateMultiPrices = ({ data }: MessageEvent) => {
        const parsedData = JSON.parse(data);
        if (tickerStock === undefined) return;

        const ISIN = parsedData?.ISIN;
        const tsLast = parsedData?.["ts-last"];
        const update = parsedData?.update;
        const T = parsedData?.T;

        if (!tickerStock || ISIN !== instrument[0]) return;
        setInstrument((prevState) => {
            const updatedStock = T
                ? {
                    ...prevState[1],
                    price: T?.C?.c || prevState[1].price,
                    lastChange: (T?.C?.c || 0) - (prevState[1].lastPrice || 0),
                }
                : {
                    ...prevState[1],
                    price: tsLast?.[1] || prevState[1].price,
                    change: update?.nch || prevState[1].change,
                    percentage: update?.pch || prevState[1].percentage,
                    timestamp: tsLast?.[0] || prevState[1].timestamp,
                };

            const priceDiff = Math.abs(prevState[1].price - updatedStock.price) > 0.01;
            const percentageDiff = Math.abs(prevState[1].percentage - updatedStock.percentage) > 0.01;
            const timeDiff = updatedStock.timestamp - prevState[1].timestamp > 60 * 1000;
            if (priceDiff || percentageDiff || timeDiff) {
                setIsLoading(false);
                return [prevState[0], updatedStock];
            }
            return prevState;
        });
    };

    const updateOrtexPrices = ({ data }: MessageEvent) => {
        const parsedData = JSON.parse(data);
        if (tickerStock === undefined) return;
        const { CT, T } = parsedData;

        if (CT) {
            const { i, P } = CT;
            setInstrument((prev) => {
                const priceDiff = Math.abs(prev[1].price - P) > 0.01;
                if (!prev || !priceDiff) return prev;
                return [prev[0], { ...prev[1], price: P, change: P - prev[1].lastPrice, percentage: priceDiffPercentage(prev[1].lastPrice, P) }];
            });
        } else if (T) {
            if (instrument[0] !== T.i && (instrument[1].isin + instrument[1].currency) !== T.i && instrument[1].ticker !== T.i) return;
            const priceDiff = Math.abs(tickerStock[1].price - T.C.c) > 0.01;
            if (!tickerStock || !priceDiff) return;
            setInstrument((prevState) => ([prevState[0], { ...prevState[1], price: T.C.c, lastChange: T.C.c - prevState[1].lastPrice }]));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (tickerStock[0] === undefined) {
            if (user.email && ortexWebSocket) {
                ortexWebSocket.readyState === 1 ? sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) : ortexWebSocket.addEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                ortexWebSocket.removeEventListener("message", updateOrtexPrices);
                ortexWebSocket.addEventListener("message", updateOrtexPrices);
            }
        } else {
            if (tickerStock[1].exchange) {
                if (usExchanges.includes(tickerStock[1].exchange)) {
                    if (user.email && obookWebSocket) {
                        obookWebSocket.readyState === 1 ? sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) : obookWebSocket.addEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                        obookWebSocket.removeEventListener("message", updateOrtexPrices);
                        obookWebSocket.addEventListener("message", updateOrtexPrices);
                    }
                } else {
                    if (user.email && ortexWebSocket) {
                        ortexWebSocket.readyState === 1 ? sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) : ortexWebSocket.addEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                        ortexWebSocket.removeEventListener("message", updateOrtexPrices);
                        ortexWebSocket.addEventListener("message", updateOrtexPrices);
                    }
                }
            } else {
                if (user.email && multiWebSocket) {
                    multiWebSocket.readyState === 1 ? sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket) : multiWebSocket.addEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                    multiWebSocket.removeEventListener("message", updateMultiPrices);
                    multiWebSocket.addEventListener("message", updateMultiPrices);
                }
            }
        }

        return () => {
            if (user.email && multiWebSocket) {
                multiWebSocket.removeEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                multiWebSocket.removeEventListener("open", updateMultiPrices);
            }
            if (user.email && obookWebSocket) {
                obookWebSocket.removeEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                obookWebSocket.removeEventListener("open", updateMultiPrices);
            }
            if (user.email && ortexWebSocket) {
                ortexWebSocket.removeEventListener("open", () => sendWsMessage(tickerStock, obookWebSocket, multiWebSocket, ortexWebSocket));
                ortexWebSocket.removeEventListener("message", updateOrtexPrices);
            }
        };
    }, [multiWebSocket, obookWebSocket, ortexWebSocket, user, tickerStock]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 60000);
        return () => clearTimeout(timer);
    }, []);

    return { instrument, isLoading };
};
