import { Button, CircularProgress, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { textColorState } from "@/lib/store";
import { useRecoilValue } from "recoil";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { ModuleContext } from "@/lib/Context";
import { FilterState } from "@/models/datagrid";

interface PropsTypes {
    apiParamsValues?: any[];
    setParams?: any;
    defaultValues?: any;
    fetcher?: any;
    setResetFilters?: any;
    data: any;
    DataGrid?: boolean;
    reset?: { setter: any; defaultValue: any };
    setChips?: (chips: Array<any>) => void;
    dataObj?: boolean;
    initialParam?: boolean;
    headers?: any;
    cleared?: boolean;
    setFiltersState?: React.Dispatch<React.SetStateAction<FilterState>>;
}

const areEqualArrays = (a: any, b: any) => {
    if (typeof a === "object") {
        a = Object.values(a);
    }
    if (typeof b === "object") {
        b = Object.values(b);
    }
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    let sorted_a = [...a].sort();
    let sorted_b = [...b].sort();
    return sorted_a.length === sorted_b.length && sorted_a.every((element, index) => element === sorted_b[index]);
};

const ResetFiltersButton: FC<PropsTypes> = ({ headers = [], cleared, apiParamsValues, setParams, defaultValues, fetcher, setResetFilters, data, DataGrid, reset, setChips, dataObj, initialParam = null, setFiltersState }) => {
    const textColor = useRecoilValue(textColorState);
    const [isLoading, setisLoading] = useState(false);
    const { setFilterAmount } = useContext(ModuleContext);
    const [resetValue, setResetValue] = useState<boolean>(true);
    let extraFiltersAmount = 0;
    const [headerWithDefaultValues] = useState<string[]>(headers?.filter((header) => header.defaultMin || header.defaultMax)?.map((header) => header.field));

    const handleReset = () => {
        setisLoading(true);
        setResetFilters && setResetFilters(true);
        setFiltersState && setFiltersState((prev) => ({ previousState: prev.currentState, currentState: "reseted" }));
        setChips && setChips([]);

        if (reset !== undefined) {
            // if we pass the setter always in an array we can simplify this logic
            const { setter, defaultValue } = reset;
            if (Array.isArray(setter)) setter.forEach((set, i) => set(defaultValue[i]));
            else setter(defaultValue);
        }

        if (DataGrid) {
            setParams(defaultValues.apiParams);
            fetcher(1, undefined, defaultValues);
        }
    };

    const disableReset = () => {
        const apiParamsLength = Boolean(apiParamsValues?.length);

        // For tables that use extra data
        if (reset && data) {
            const { defaultValue } = reset;
            const values = DataGrid ? Object.values(data)[0] : data;
            let noFiltered: boolean = true;
            if (Array.isArray(defaultValue)) {
                if (values.length !== defaultValue.length) {
                    noFiltered = true;
                } else {
                    // If dataObj is present, it compares each element of values with defaultValue[i].payload.
                    // If dataObj is absent, it directly compares each element of values with defaultValue[index].
                    // extraFiltersAmount tracks the number of unequal comparisons.
                    // noFiltered is set to true if all comparisons are equal, otherwise, it is false.
                    if (dataObj) {
                        noFiltered = values
                            .map((val, i) => {
                                let areEquals: boolean;
                                if (typeof val === "object" && val !== null) areEquals = areEqualArrays(val, defaultValue[i].payload);
                                else areEquals = val === defaultValue[i].payload;
                                !areEquals && extraFiltersAmount++;
                                return areEquals;
                            })
                            .every((val: boolean) => val === true);
                    } else {
                        values.forEach((value, index) => {
                            if (value !== defaultValue[index]) extraFiltersAmount++;
                        });
                        noFiltered = !extraFiltersAmount;
                    }
                }
            } else {
                noFiltered = values === defaultValue;
                !noFiltered && extraFiltersAmount++;
            }
            return noFiltered && !apiParamsLength;
        }
        // For tables with no extra data and reset props
        else {
            if (initialParam !== null) {
                if (!initialParam && headerWithDefaultValues.length) {
                    return false;
                } else {
                    return initialParam || !apiParamsLength;
                }
            } else {
                return !apiParamsLength;
            }
        }
    };

    useEffect(() => {
        setResetValue(disableReset());

        // Sets for min and max operators
        const minOperators = new Set([">=", "after"]);
        const maxOperators = new Set(["<=", "before"]);

        // Helper functions to check the operator type
        const isMin = (operatorValue) => minOperators.has(operatorValue);
        const isMax = (operatorValue) => maxOperators.has(operatorValue);

        // Create a list of active non default filters
        const nonDefFilters = new Set<string>();
        const headersMap = new Map(headers?.map((header) => [header.field, header]));

        apiParamsValues?.forEach(({ operatorValue, value, columnField }) => {
            // Find header
            const header = headersMap.get(columnField);

            if (header) {
                const { defaultMin, defaultMax, rangeMin, rangeMax }: any = header;

                // Min case
                if (isMin(operatorValue)) {
                    const compareValue = defaultMin !== undefined ? defaultMin : rangeMin;
                    if (value !== compareValue) nonDefFilters.add(columnField);
                }
                // Max case
                else if (isMax(operatorValue)) {
                    const compareValue = defaultMax !== undefined ? defaultMax : rangeMax;
                    if (value !== compareValue) nonDefFilters.add(columnField);
                }
                // Any other case
                else {
                    nonDefFilters.add(columnField);
                }
            }
            // This fixes the case when columnField is id because of the stockColumns
            else if (columnField === "id" && headersMap.has("stock")) {
                nonDefFilters.add(columnField);
            }
        });

        // If initialParam, all non default filters should be removed
        if (initialParam) {
            setFilterAmount(0);
        }
        // If cleared, no filters should be shown
        else if (cleared) {
            setFilterAmount(0);
        }

        // Show filters normally
        else {
            // This is for the case when there are default values but the filter is set in min/max
            const missingDefHeaders = headerWithDefaultValues?.filter((header) => !apiParamsValues.some((item) => item.columnField === header));
            const allHeaders = new Set([...Array.from(nonDefFilters), ...missingDefHeaders]);
            setFilterAmount(nonDefFilters.size + extraFiltersAmount);
        }

        setisLoading(false);
    }, [apiParamsValues, data]);

    return (
        <Button
            aria-label="reset filters"
            size="small"
            sx={{
                /* mr: "auto", */
                color: textColor,
                mt: 1,
                mr: 1,
                "&:disabled": {
                    color: "grey",
                },
            }}
            disabled={resetValue}
            onClick={handleReset}
            endIcon={isLoading ? <CircularProgress size={18} sx={{ ml: 0.5 }} /> : <RestartAltIcon color={resetValue ? "inherit" : "error"} />}
        >
            <Typography variant="overline">Reset</Typography>
        </Button>
    );
};

export default ResetFiltersButton;
