import { EventMonthAhead, EventMonthAheadHeaders, EventWeekAhead, MacroEventWeekAhead, MacroEventMonthAhead, MacroEventMonthAheadHeaders } from "@/models/events";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";
import { groupBy } from "lodash";
import dayjs from "dayjs";
import { formatDate } from "@/lib/utils";

export const getEventsWeekAhead = async (universeId: number, event_types: string, signal?: AbortSignal, newFormat = true) => {
    try {
        const res = await axios.get<EventWeekAhead[][] | { obfuscated: boolean; data: EventWeekAhead[][] }>(`/API/v2/${universeId}/events/week_ahead${newFormat ? "?new_format" : ""}`, { params: { event_types }, signal });

        const parsedData = Object.entries(
            groupBy((Array.isArray(res.data) ? res.data : res.data.data).filter((events) => events.length).flat(), (event) => (event.format_override ? dayjs.utc(event.date) : dayjs(event.StartTime)).format("MM/DD/YY")) as {
                [date: string]: EventWeekAhead[];
            }
        )
            .sort(([a], [b]) => (dayjs(a).isAfter(b, "day") ? 1 : dayjs(a).isBefore(b, "day") ? -1 : 0))
            .filter(([date]) => dayjs(date).isAfter(dayjs().subtract(1, "day"), "day"))
            .map(([date, events]) => [dayjs(date).format("dddd - Do MMMM, YYYY"), events] as [string, EventWeekAhead[]]);

        return {
            obfuscated: res.data["obfuscated"],
            data: parsedData,
        };
    } catch (err) {
        throw err;
    }
};

export const getMacroEventsWeekAhead = async (countries: string, newFormat = true) => {
    try {
        const res = await axios.get<MacroEventWeekAhead[][] | { obfuscated: boolean; data: MacroEventWeekAhead[][] }>(`/API/v2/0/macro_events/week_ahead${newFormat ? "?new_format" : ""}`, {
            params: {
                countries,
            },
        });

        return {
            obfuscated: res.data["obfuscated"] as boolean,
            data: (Array.isArray(res.data) ? res.data : res.data.data).filter((events) => events.length),
        };
    } catch (err) {
        throw err;
    }
};

export const getEventsMonthAhead = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id } = params;

    const url = next_page_url || `/API/v2/${universe_id}/events/list/month_ahead?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<EventMonthAhead, EventMonthAheadHeaders>>(url, {
            params: {
                ...defaultParams,
            },
        });

        const utcOffset = dayjs().utcOffset();
        res.data.rows = res.data.rows.map((event) => {
            if (event[9]) event[0] = dayjs(event[0]).subtract(utcOffset, "minutes").valueOf();
            return event;
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getEventsByMonth = async (universeId: number, params: { start?: number; length?: number; month_of_year: string; event_types: string }) => {
    try {
        const res = await axios.get<List<EventMonthAhead, EventMonthAheadHeaders>>(`/API/v2/${universeId}/events/list/month_of_data`, { params });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getMacroEventsByMonth = async (params: { start?: number; length?: number; month_of_year: string; countries?: string; signal?: AbortSignal }) => {
    try {
        const res = await axios.get<List<MacroEventMonthAhead, MacroEventMonthAheadHeaders>>(`/API/v2/0/macro_events/list/month_of_data`, { params });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getEventTypes = async (universeId: number) => {
    try {
        const res = await axios.get<{ name: string; code: string; enabled: boolean }[]>(`/API/v2/${universeId}/events/event_families`);

        return res.data;
    } catch (err) {
        throw err;
    }
};
