import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models/range";
import { PickersShortcutsItem, PickersShortcutsProps } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Chip, Divider, List, ListItem, useMediaQuery } from "@mui/material";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { ortexColorState, textColorState, themeState } from "@/lib/store";
import { useRecoilValue } from "recoil";
interface PropsTypes {
    header: any;
    setParams: (params: any) => void;
    values: any[];
    initialParams?: any[];
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: "This Week",
        getValue: () => {
            const today = dayjs().startOf("day");
            return [today.startOf("week"), today.endOf("week")];
        },
    },
    {
        label: "Last Week",
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, "day");
            return [prevWeek.startOf("week"), prevWeek.endOf("week")];
        },
    },
    {
        label: "Last 7 Days",
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, "day"), today];
        },
    },
    {
        label: "Current Month",
        getValue: () => {
            const today = dayjs();
            return [today.startOf("month"), today.endOf("month")];
        },
    },
    {
        label: "Next Month",
        getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf("month").add(1, "day");
            return [startOfNextMonth, startOfNextMonth.endOf("month")];
        },
    },
    { label: "Clear", getValue: () => [null, null] },
];

const DateFilter = ({ header, setParams, values, initialParams }: PropsTypes) => {
    const [date, setDate] = useState<DateRange<Dayjs>>([null, null]);
    const [locale, setLocale] = useState("en-us");
    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);
    const ortexColor = useRecoilValue(ortexColorState);

    const isMobile = useMediaQuery("(max-width:768px)");

    const shortcutsSettings = isMobile
        ? {
              toolbar: { hidden: true },
              actionBar: { hidden: true },
          }
        : { actionBar: { actions: [] } };

    const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
        const { items, onChange, isValid } = props;

        if (items == null || items.length === 0) {
            return null;
        }

        const resolvedItems = items.map((item) => {
            const newValue = item.getValue({ isValid });

            return {
                label: item.label,
                onClick: () => {
                    onChange(newValue);
                },
                disabled: !isValid(newValue),
            };
        });

        return (
            <Box
                sx={{
                    gridRow: 1,
                    gridColumn: 2,
                }}
            >
                <List
                    dense
                    sx={(theme) => ({
                        display: "flex",
                        flexWrap: "wrap",
                        px: theme.spacing(4),
                        "& .MuiListItem-root": {
                            pt: 0,
                            pl: 0,
                            pr: theme.spacing(1),
                        },
                    })}
                >
                    {resolvedItems.map((item) => {
                        return (
                            <ListItem key={item.label} sx={{ width: "min-content" }}>
                                <Chip {...item} />
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
            </Box>
        );
    };

    useEffect(() => {
        if (values.length === 0) {
            setDate([null, null]);
        } else {
            const updatedArray: DateRange<Dayjs> = [header?.defaultMin ? dayjs(header?.defaultMin) : null, header?.defaultMax ? dayjs(header?.defaultMax) : null];

            values.forEach((e) => {
                if (e?.operatorValue === "after" && e?.columnField === header?.field) updatedArray[0] = dayjs(e.value);
                else if (e?.operatorValue === "before" && e?.columnField === header?.field) updatedArray[1] = dayjs(e.value);
            });

            setDate(updatedArray);
        }
    }, [values]);

    useEffect(() => {
        if (dayjs.locale() !== locale) {
            setLocale(dayjs.locale(locale));
        }
        if (date[0] !== null && date[1] !== null) {
            onAccept(date);
        }
    }, []);

    const onAccept = (value: DateRange<Dayjs>) => {
        let minDate = { columnField: header.field, id: Date.now(), operatorValue: "after", value: value[0]?.startOf("day").valueOf() };
        let maxDate = { columnField: header.field, id: Date.now(), operatorValue: "before", value: value[1]?.endOf("day").valueOf() };

        setParams((prevParams) => {
            const oldParams = prevParams?.customFilterModel?.items.filter((i) => i.columnField !== header.field) || [];

            if (minDate.value && maxDate.value) {
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: [...oldParams, minDate, maxDate],
                    },
                };
            } else {
                return {
                    ...prevParams,
                    customFilterModel: {
                        items: [...oldParams],
                    },
                };
            }
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                disableFuture={!header.rangeMax}
                format={navigator.language.includes("US") ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                value={date}
                onAccept={onAccept}
                minDate={header.rangeMin ? dayjs(header.rangeMin) : null}
                maxDate={header.rangeMax ? dayjs(header.rangeMax) : null}
                slots={isMobile ? { shortcuts: CustomRangeShortcuts } : {}}
                slotProps={{
                    shortcuts: {
                        items: shortcutsItems,
                    },
                    ...shortcutsSettings,
                    textField: { size: "small", variant: "outlined", label: "", inputProps: { sx: { fontSize: 12 } } },
                    fieldSeparator: { children: "to" },
                    desktopPaper: {
                        sx: {
                            "& .MuiPickersDay-root": {
                                "&.Mui-selected": {
                                    fontWeight: 700,
                                },
                            },
                            "& .MuiListItem-root": {
                                "&:hover": {
                                    backgroundColor: "initial !important",
                                },

                                "& .MuiChip-root": {
                                    backgroundColor: theme === "dark" && "rgba(255, 255, 255, 0.04)",
                                },
                                "& .MuiChip-root:hover": {
                                    backgroundColor: ortexColor,
                                },
                            },
                            "& .MuiPickersArrowSwitcher-button:hover": {
                                backgroundColor: ortexColor,
                            },
                        },
                    },
                    leftArrowIcon: { sx: { color: textColor } },
                    rightArrowIcon: { sx: { color: textColor } },
                }}
            />
        </LocalizationProvider>
    );
};

export default DateFilter;
