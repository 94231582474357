import styles from "./ChartCell.module.scss";
import { FC, useContext, useEffect, useState } from "react";
import Highcharts, { Options, SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from "recoil";
import { positiveState, negativeState, themeState } from "@/lib/store";
import { ModuleContext } from "@/lib/Context";
import { addAccessibility } from "@/lib/highcharts.src";
import CircularProgress from "@mui/material/CircularProgress";
import { classNames } from "@/lib/utils";
interface PropsTypes {
    width?: number;
    height?: number;
    backgroundColor?: string;
    data: [number, number][] | { [key: string]: [number, number][] };
    isMultiTime?: boolean;
    datetime: boolean;
    direction?: string;
    type?: string;
    customTooltip?: { formatter: () => string };
    seriesOptions?: SeriesOptionsType;
    obfuscated?: string;
}

const ChartCell: FC<PropsTypes> = ({ width = 250, height, data, isMultiTime, datetime, direction, backgroundColor, type, customTooltip, seriesOptions, obfuscated }) => {
    addAccessibility(Highcharts);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const theme = useRecoilValue(themeState);
    const [isShown, setIsShown] = useState(false);
    const [options, setOptions] = useState<Options>({
        chart: {
            borderWidth: 0,
            backgroundColor: "transparent",
            margin: 0,
        },
        accessibility: { enabled: true },
        series: [],
        tooltip: {
            enabled: !obfuscated,
            split: false,
            shared: true,
            useHTML: true,
            outside: true,
            ...(!datetime && {
                formatter: function () {
                    const points = this.points;
                    let tooltipMarkup = points.length ? `<span style="font-size: 11px">After ${points[0].key} Days</span><br/>` : "";
                    for (let index = 0; index < points.length; index += 1) {
                        let valueNumber = points[index].y;
                        if (index < 2 && points[0] && points[1] && valueNumber) {
                            valueNumber = parseFloat(((valueNumber / (points[0].y + points[1].y)) * 100).toFixed(0));
                        }
                        if (index == 2) {
                            valueNumber = parseFloat(valueNumber.toFixed(2));
                        }
                        tooltipMarkup += `<span style="color:${points[index].color}">●</span>${points[index].series.name}: <b>${valueNumber}%</b><br/>`;
                    }
                    return tooltipMarkup;
                },
            }),
            ...(datetime && customTooltip && customTooltip),
            style: { zIndex: 1300 },
        },
        title: { text: null },
        credits: { enabled: false },
        xAxis: {
            lineWidth: 0,
            tickLength: 0,
            type: datetime ? "datetime" : "linear",
            labels: { enabled: false },
        },
        yAxis: [
            {
                softMin: -1,
                softMax: 1,
                labels: { enabled: false },
                title: { text: null },
                endOnTick: false,
                gridLineWidth: 0,
            },
            {
                labels: { enabled: false },
                title: { text: null },
                endOnTick: false,
                gridLineWidth: 0,
                opposite: true,
                reversed: direction === "Sell" ? true : false,
            },
        ],
        legend: { enabled: false },
        plotOptions: {
            series: {
                lineWidth: 1,
                shadow: false,
                animation: false,
                marker: { enabled: false },
                states: { hover: { lineWidth: 1 } },
            },
            area: {
                stacking: "percent",
                dataLabels: { enabled: false },
            },
        },
        exporting: { enabled: false },
    });

    useEffect(() => {
        setOptions({
            ...options,
            series: isMultiTime
                ? Object.entries(data)
                      .reverse()
                      .map(([name, data], index) => ({
                          name,
                          data: data || [],
                          type: (name === "Returns" || type === "line" ? "line" : "area") as undefined,
                          color: seriesOptions?.[index]?.color ? seriesOptions?.[index]?.color : name === "Returns" ? "#FFF" : name === "Risk" ? negative : positive,
                          yAxis: name !== "Returns" ? 1 : 0,
                          ...seriesOptions?.[index],
                      }))
                : [
                      {
                          data,
                          type: (type || "line") as undefined,
                          name: datetime ? "Price" : "Return",
                          color: theme === "dark" ? "#FFF" : "#000",
                      },
                  ],
        });
    }, [data]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, 0);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={classNames(styles.ChartCell, obfuscated)} style={{ height: "100%" || (isMultiTime ? 100 : 50), width: "100%" }}>
            {isShown ? <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} /> : <CircularProgress size={20} />}
        </div>
    );
};

export default ChartCell;
