import "draft-js/dist/Draft.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import styles from "./RichTextEditor.module.scss";
import { FC, FormEventHandler, MouseEvent as ReactMouseEvent, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { CompositeDecorator, DraftDecorator, EditorState, EntityInstance, Modifier, RichUtils } from "draft-js";
import createMentionPlugin, { MentionData } from "@draft-js-plugins/mention";
import createImagePlugin from "@draft-js-plugins/image";
import createVideoPlugin from "@draft-js-plugins/video";
import Editor from "@draft-js-plugins/editor";
import { Options, stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { findInstruments } from "@/controllers/find-instruments";
import { classNames, dateParser, nFormatter } from "@/lib/utils";
import { useRecoilValue } from "recoil";
import { backgroundColorState, darkerBackgroundColorState, greyDarkerState, greyLighterState, greyMediumState, greyState, lighterBackgroundColorState, regionState, textColorState, themeState } from "@/lib/store";
import MacroEventsTable from "@/components/macro-events-table/MacroEventsTable";
import { getDataGridTradingSignals } from "@/controllers/trading-signals";
import { getLargestLendingChanges } from "@/controllers/short-interest";
import { getEventsMonthAhead } from "@/controllers/events";
import { getAnalystsRecent } from "@/controllers/analysts";
import dayjs, { Dayjs } from "dayjs";
import Color from "@/lib/Color";
import sections from "./ortexSections";
import { Paper, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, TextField, Tooltip, ToggleButtonGroup, ToggleButton } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import CodeIcon from "@mui/icons-material/Code";
import TitleIcon from "@mui/icons-material/Title";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { DataGrid, MemoizedDataGrid } from "../data-grid/DataGrid";
import Module from "../module/Module";

const upDown = {
    up: "buy",
    down: "sell",
};

interface RangeSelectorInterface {
    buttons: {
        text: string;
        value: number;
    }[];
    key?: string;
    selected?: number;
}

const shortInterestsRangeSelector: RangeSelectorInterface = {
    buttons: [
        { text: "1 DAY", value: 1 },
        { text: "1 WEEK", value: 7 },
        { text: "1 MONTH", value: 30 },
    ],
    key: "days_back",
    selected: 1,
};

const mentionPlugin = createMentionPlugin({
    mentionTrigger: "#",
    mentionComponent: ({ children, mention }) => <a href={mention.link}>{children}</a>,
    supportWhitespace: true,
});
const { MentionSuggestions } = mentionPlugin;

const imagePlugin = createImagePlugin();
const { addImage } = imagePlugin;

const videoPlugin = createVideoPlugin();
const { addVideo } = videoPlugin;

const plugins = [mentionPlugin, imagePlugin, videoPlugin];

const getLinkIcon = (section: string) => {
    const sectionIcon = sections.find((s) => s.value === section);
    if (sectionIcon) {
        return sectionIcon.icon;
    }

    return null;
};

const decorators: DraftDecorator[] = [
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && ["LINK", "ORTEXLINK", "IFRAME"].includes(contentState.getEntity(entityKey).getType());
            }, callback);
        },
        component: ({ children, contentState, entityKey }) => {
            const entity = contentState.getEntity(entityKey) as EntityInstance;
            const { src, target, url } = entity.getData();
            switch (entity.getType()) {
                case "LINK":
                    return (
                        <a href={getLinkIcon(url) ? window.location.origin + url : url} className={styles.custom} target={target}>
                            {getLinkIcon(url) && <i className={getLinkIcon(url)} />}
                            {children}
                        </a>
                    );
                case "IFRAME":
                    return (
                        <div>
                            <iframe src={src} frameBorder="0" allowFullScreen></iframe>
                        </div>
                    );
            }
        },
    },
];

const getContentAndSelection = (editorState: EditorState) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection().merge({
        anchorOffset: 0,
        anchorKey: currentContent.getFirstBlock().getKey(),
        focusOffset: currentContent.getLastBlock().getText().length,
        focusKey: currentContent.getLastBlock().getKey(),
    });
    return { currentContent, selection };
};

const stateFromHTMLOptions: Options = { customInlineFn: (element, { Entity }) => (element.tagName === "IFRAME" ? Entity("IFRAME", { src: element.getAttribute("src") }) : null) };

interface PropsTypes {
    markup?: string;
    onReset?: () => void;
    onCancel?: () => void;
    onSave: (markup: string, published_after?: Date | null) => void;
    stockButtons?: boolean;
    openEditor?: boolean;
    setOpenEditor?: (value: boolean) => void;
    setNote?: (value: any) => void;
    noteType?: boolean;
}

const RichTextEditor: FC<PropsTypes> = ({ markup = "", onReset, onCancel, onSave, stockButtons, openEditor, setOpenEditor, setNote, noteType }) => {
    const region = useRecoilValue(regionState);
    const theme = useRecoilValue(themeState);
    const grey = useRecoilValue(greyState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const greyMedium = useRecoilValue(greyMediumState);
    const greyLighter = useRecoilValue(greyLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const shadow = new Color(backgroundColor).brighten(theme === "dark" ? -0.11 : -0.56).get();
    const textColor = useRecoilValue(textColorState);

    const timeout = useRef<NodeJS.Timeout>();
    const editorRef = useRef<Editor>();
    const firstLoad = useRef(true);
    const [src, setSrc] = useState("");
    const [text, setText] = useState("");
    const [form, setForm] = useState<"link" | "ortexlink" | "image" | "video">(null);
    const [open, setOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [suggestions, setSuggestions] = useState<MentionData[]>([]);
    const [type, setType] = useState<"signals" | "shorts" | "macro" | "events" | "insiders" | "analysts">(null);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(stateFromHTML(markup, stateFromHTMLOptions), new CompositeDecorator(decorators)));
    const [date, setDate] = useState<Dayjs>(dayjs());

    const [rangeSelectionLendingChanges, setRangeSelectionLendingChanges] = useState<number>(0);
    const [rangeSelectionShorts, setRangeSelectionShorts] = useState<number>(1);

    const getRangeSelectorBtns = (el: RangeSelectorInterface, state: number, setter: React.Dispatch<React.SetStateAction<number>>) => {
        let rangeButtons = (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <ToggleButtonGroup color="primary" exclusive aria-label="toggle-buttons" size="small" onChange={(event, range) => setter(range)} value={state}>
                    {el?.buttons.map((btn) => <ToggleButton value={btn.value}>{btn.text}</ToggleButton>)}
                </ToggleButtonGroup>
            </Box>
        );
        return rangeButtons;
    };
    const [tradingSignalsFilters, setTradingSignalsFilters] = useState<JSX.Element>(null);
    const [shortFilters, setShortFilters] = useState<JSX.Element>(null);

    const appBarButtonsOne = [
        { function: () => setType("signals"), key: "Signals", content: "Signals" },
        { function: () => setType("shorts"), key: "Shorts", content: "Shorts", type: "button" },
        { function: () => setType("macro"), key: "Macro", content: "Macro", type: "button" },
        { function: () => setType("events"), key: "Events", content: "Events", type: "button" },
        { function: () => setType("analysts"), key: "Analysts", content: "Analysts", type: "button" },
        { function: () => setForm("ortexlink"), key: "Ortex Link", content: "Ortex Link", type: "button" },
    ];
    const appBarButtonsTwo = [
        { function: () => setForm("link"), key: "Link", content: <AddLinkIcon />, type: "iconButton", subType: "button" },
        { function: () => setForm("image"), key: "Image", content: <ImageIcon />, type: "iconButton", subType: "button" },
        { function: () => setForm("video"), key: "Video", content: <VideoLibraryIcon />, type: "iconButton", subType: "button" },
        { function: (event) => toggleInlineStyle(event, "BOLD"), key: "Bold", content: <FormatBoldIcon />, type: "iconButton", subtype: "inline" },
        { function: (event) => toggleInlineStyle(event, "ITALIC"), key: "Italic", content: <FormatItalicIcon />, type: "iconButton", subtype: "inline" },
        { function: (event) => toggleInlineStyle(event, "UNDERLINE"), key: "Underline", content: <FormatUnderlinedIcon />, type: "iconButton", subtype: "inline" },
        { function: (event) => toggleInlineStyle(event, "CODE"), key: "Code", content: <CodeIcon />, type: "iconButton", subtype: "inline" },
        { function: (event) => toggleBlockType(event, "header-one"), key: "Title", content: <TitleIcon />, type: "iconButton", subtype: "block" },
        { function: (event) => toggleBlockType(event, "header-two"), key: "Subtitle", content: <TextFieldsIcon />, type: "iconButton", subtype: "block" },
    ];

    useEffect(() => {
        if (!firstLoad.current) {
            const { currentContent, selection } = getContentAndSelection(editorState);
            const blockMap = stateFromHTML(markup, stateFromHTMLOptions).getBlockMap();
            const content = Modifier.replaceWithFragment(currentContent, selection, blockMap);
            const state = EditorState.push(editorState, content, "insert-fragment");
            setEditorState(state);
        }
        firstLoad.current = false;
    }, [markup]);

    useEffect(() => {
        if (!readOnly && !type && !form) setEditorState(() => EditorState.moveFocusToEnd(editorState));
    }, [readOnly, type, form]);

    const toggleInlineStyle = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>, style: string) => {
        event.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>, type: string) => {
        event.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    };

    const getInlineStyle = (style: string) => editorState.getCurrentInlineStyle().has(style);

    const getBlockType = (type: string) => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === type;

    const handleKeyCommand = (command: string) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const reset = () => {
        setReadOnly(false);
        if (setOpenEditor !== undefined) {
            setOpenEditor(true);
        }
        const { currentContent, selection } = getContentAndSelection(editorState);
        const content = Modifier.removeRange(currentContent, selection, "forward");
        const state = EditorState.push(editorState, content, "remove-range");
        setEditorState(state);
        onReset?.();
    };

    const onSearchChange = useCallback(({ value }: { value: string }) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(
            () =>
                findInstruments(value).then((data) =>
                    setSuggestions(
                        data.map(({ exchange, name, ticker, value }) => ({
                            exchange,
                            id: value,
                            stock: name,
                            name: `${exchange}:${ticker}`,
                            link: `${window.location.origin}/s/${exchange}/${ticker}`,
                        }))
                    )
                ),
            1000
        );
    }, []);

    const cancel: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        if (setOpenEditor !== undefined) {
            setOpenEditor(false);
        }
        setReadOnly(true);
        setEditorState(EditorState.createWithContent(stateFromHTML(markup, stateFromHTMLOptions), new CompositeDecorator(decorators)));
        onCancel?.();
        setCancelOpen(false);
        if (setNote !== undefined) {
            setNote((prevState) => ({ ...prevState, note: "" }));
        }
    };

    const save = () => {
        setReadOnly(true);
        onSave(
            stateToHTML(editorState.getCurrentContent(), {
                entityStyleFn: (entity) => {
                    switch (entity.getType()) {
                        case "#mention":
                            return {
                                element: "a",
                                attributes: { href: entity.getData().mention.link },
                            };
                        case "IFRAME":
                            return {
                                element: "iframe",
                                attributes: { src: entity.getData().src, frameBorder: "0", allowFullScreen: true },
                            };
                        case "draft-js-video-plugin-video":
                            let src = entity.getData().src as string;
                            if (src.includes("youtube")) {
                                const url = new URL(src);
                                const video = url.searchParams.get("v");
                                src = `https://www.youtube.com/embed/${video}`;
                            }
                            return {
                                element: "iframe",
                                attributes: { src, frameBorder: "0", allowFullScreen: true },
                            };
                    }
                },
            }),
            date?.toDate() ?? null
        );
        if (setOpenEditor !== undefined) {
            setOpenEditor(false);
        }
    };

    const getModalChildren = () => {
        switch (type) {
            case "signals":
                return (
                    <Module
                        title={"Signals"}
                        filters={
                            <>
                                {getRangeSelectorBtns(shortInterestsRangeSelector, rangeSelectionLendingChanges, setRangeSelectionLendingChanges)}
                                {tradingSignalsFilters}
                            </>
                        }
                    >
                        <MemoizedDataGrid
                            id={region}
                            fetcher={getDataGridTradingSignals}
                            data={{ days_back: rangeSelectionLendingChanges, type: "hist" }}
                            setFilters={setTradingSignalsFilters}
                            onRowClick={(row) => {
                                addText(`<a href="${window.location.origin}/id/${row.stock_id}/trading-signals">${row.ticker}</a> has a ${row.type} ${row.type.includes(row["b/s"]) ? "" : row["b/s"]} Signal today.`);
                            }}
                        />
                    </Module>
                );
            case "shorts":
                return (
                    <Module
                        title={"Shorts"}
                        filters={
                            <>
                                {getRangeSelectorBtns(shortInterestsRangeSelector, rangeSelectionShorts, setRangeSelectionShorts)}
                                {shortFilters}
                            </>
                        }
                    >
                        <MemoizedDataGrid
                            id={region}
                            fetcher={getLargestLendingChanges}
                            data={{ days_back: rangeSelectionShorts }}
                            setFilters={setShortFilters}
                            onRowClick={({ "dtc change": change, stock_id, ticker }) => {
                                addText(`Short interest in <a href="${window.location.origin}/id/${stock_id[0]}/short-interest">${ticker}</a> has gone ${change < 0 ? "down" : "up"} with ${change.toFixed(2)}% in the last week.`);
                            }}
                        />
                    </Module>
                );
            case "macro":
                return (
                    <MacroEventsTable
                        onEventClick={({ time, title_short }) => {
                            addText(`${title_short} ${dateParser(dayjs.utc(time).tz("Europe/Paris"), "[on] ddd, Do MMM [at] HH:mm", "Europe/Paris")} CET. Click <a href="${window.location.origin}/macro">here</a> for details.`);
                        }}
                    />
                );
            case "events":
                return (
                    <DataGrid
                        id={region}
                        fetcher={getEventsMonthAhead}
                        onRowClick={({ date, stock_id, ticker, type }) => {
                            addText(`<a href="${window.location.origin}/id/${stock_id}/events">${ticker}</a> has a ${type} event ${dateParser(dayjs.utc(date).tz("Europe/Paris"), "[on] ddd, Do MMM [at] HH:mm", "Europe/Paris")} CET.`);
                        }}
                    />
                );
            case "analysts":
                return (
                    <DataGrid
                        id={region}
                        fetcher={getAnalystsRecent}
                        onRowClick={({ an_sub_text, "avg. target price": price, change, stock_id, ticker }) => {
                            addText(
                                `<a href="${window.location.origin}/id/${stock_id}/analysts">${ticker}</a> has been ${change[0] ? change[0].toLowerCase() + "graded" : "N/A"} ${
                                    an_sub_text ? `by ${an_sub_text[0]} to ${nFormatter(price[0], 2)}` : `to a ${change[0] ? upDown[change[0].toLowerCase()] : "N/A"}`
                                }.`
                            );
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const addElement: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        switch (form) {
            case "link":
                addText(`<a href=${src} target=${src.includes("ortex.com") ? "_self" : "_blank"}>${text}</a>`);
                setText("");
                break;
            case "ortexlink":
                addText(`<a href=${src} target="_self">${text}</a>`);
                setText("");
                break;
            case "image":
                setEditorState(addImage(editorState, src, {}));
                break;
            case "video":
                setEditorState(addVideo(editorState, { src }));
                break;
        }
        setForm(null);
        setSrc("");
    };

    const addText = (markup: string) => {
        const content = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), stateFromHTML(markup, stateFromHTMLOptions).getBlockMap());
        setEditorState(EditorState.push(editorState, content, "insert-fragment"));
        setType(null);
    };

    const minMaxDate = (min: boolean, monthly: boolean) => {
        if (monthly) {
            const monthYear = dayjs().format("YYYY-MM");
            if (min) {
                return dayjs(`${monthYear}-01`);
            } else {
                const daysInMonth = dayjs().daysInMonth();
                return dayjs(`${monthYear}-${daysInMonth}`);
            }
        } else {
            if (min) {
                return dayjs();
            } else {
                return dayjs().add(1, "day");
            }
        }
    };

    return (
        <div className={classNames(styles[theme], styles.RichTextEditor)} style={{ borderWidth: readOnly ? 0 : 1 }}>
            <style jsx>{`
                .active {
                    color: ${textColor};
                    background-color: ${darkerBackgroundColor};
                }
                @media (hover: hover) {
                    button:hover {
                        background-color: ${lighterBackgroundColor};
                    }
                }
                .${styles.toolbar} > div {
                    border-bottom-color: ${theme === "dark" ? greyMedium : greyLighter};
                }

                .${styles.options} > div {
                    border-top-color: ${theme === "dark" ? greyMedium : greyLighter};
                }
                .${styles.dropdown} {
                    background-color: ${backgroundColor};
                    box-shadow:
                        0 0 5px ${shadow},
                        0 0 5px ${shadow};
                    border-color: ${theme === "dark" ? greyDarker : grey};
                }
                .${styles.dropdown} > * {
                    border-bottom-color: ${theme === "dark" ? greyDarker : grey};
                }
                .${styles.dropdown} > :global(*:nth-child(even)) {
                    background-color: ${darkerBackgroundColor};
                }
                .${styles.dropdown} .hover {
                    background-color: ${lighterBackgroundColor};
                }
            `}</style>
            <div className={classNames(styles.toolbar, styles[readOnly && "read-only"])}>
                <div>
                    {readOnly ? (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button onClick={reset}>ADD</Button>
                            {editorState.getCurrentContent().getPlainText() && (
                                <Button
                                    onClick={() => {
                                        setReadOnly(false);
                                        if (setOpenEditor !== undefined) {
                                            setOpenEditor(true);
                                        }
                                    }}
                                    sx={{
                                        marginLeft: 1,
                                    }}
                                >
                                    EDIT
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <Paper sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box className={styles.appbar}>
                                <Box className={styles["button-container"]}>
                                    {appBarButtonsOne.map((btn, index) => {
                                        if (stockButtons || index === appBarButtonsOne.length - 1) {
                                            return (
                                                <Button key={btn.key} onClick={btn.function} sx={{ padding: 0.5, margin: 0.5 }}>
                                                    {btn.content}
                                                </Button>
                                            );
                                        }
                                    })}
                                </Box>
                                <Box className={styles["button-container"]}>
                                    {appBarButtonsTwo.map((btn, index) => {
                                        if (btn.subType === "button") {
                                            return (
                                                <Tooltip arrow key={btn.key} title={btn.key}>
                                                    <IconButton onMouseDown={btn.function} aria-label={btn.key.toLowerCase()} size={"small"} sx={{ margin: 0.25 }}>
                                                        {btn.content}
                                                    </IconButton>
                                                </Tooltip>
                                            );
                                        } else {
                                            return (
                                                <Tooltip arrow title={btn.key} key={btn.key}>
                                                    <IconButton
                                                        onMouseDown={(event) => btn.function(event)}
                                                        aria-label={btn.key.toLowerCase()}
                                                        sx={{ color: (["Title", "Subtitle"].includes(btn.key) ? getBlockType(btn.key.toUpperCase()) : getInlineStyle(btn.key.toUpperCase())) ? textColor : "grey", margin: 0.25 }}
                                                        size={index === appBarButtonsTwo.length - 1 ? "medium" : "small"}
                                                    >
                                                        {btn.content}
                                                    </IconButton>
                                                </Tooltip>
                                            );
                                        }
                                    })}
                                </Box>
                                <Dialog open={!!type} onClose={() => setType(null)} fullWidth>
                                    <div className={styles.modal}>{getModalChildren()}</div>
                                </Dialog>

                                {
                                    <Dialog open={!!form} onClose={() => setForm(null)}>
                                        <Box component="form" onSubmit={addElement}>
                                            {form && (
                                                <>
                                                    {form !== "ortexlink" ? (
                                                        <DialogContent>
                                                            <TextField variant="standard" placeholder="URL" type="text" value={src} onChange={(e) => setSrc(e.target.value)} />
                                                            <br />
                                                            {form === "link" && <TextField variant="standard" placeholder="Text" type="text" value={text} onChange={(e) => setText(e.target.value)} sx={{ mt: 3 }} />}
                                                        </DialogContent>
                                                    ) : (
                                                        <DialogContent className={styles.ortexlink} sx={{ margin: 2, width: "250px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                            <Grid container spacing={2} justifyContent="center" alignItems="center" marginLeft={"-8px"}>
                                                                {sections.map((section, i) => (
                                                                    <Grid item xs={3}>
                                                                        <label key={section.value} htmlFor={section.text}>
                                                                            <input
                                                                                type="radio"
                                                                                id={section.text}
                                                                                name="section icon"
                                                                                onChange={(e) => {
                                                                                    setText(section.text);
                                                                                    setSrc(section.value);
                                                                                }}
                                                                            />

                                                                            <i className={section.icon} title={section.title} />
                                                                        </label>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                            <TextField variant="standard" placeholder="Ortex Link" type="text" value={text} onChange={(e) => setText(e.target.value)} sx={{ mt: 3 }} />
                                                        </DialogContent>
                                                    )}
                                                    <DialogActions>
                                                        <Button
                                                            onClick={() => {
                                                                setForm(null);
                                                                setText("");
                                                                setSrc("");
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button type="submit">Add</Button>
                                                    </DialogActions>
                                                </>
                                            )}
                                        </Box>
                                    </Dialog>
                                }
                            </Box>
                        </Paper>
                    )}
                </div>
            </div>
            <div className={styles.editor} style={{ padding: readOnly ? 0 : 5, display: openEditor === false && "none" }}>
                <Editor editorKey="editor" editorState={editorState} onChange={setEditorState} readOnly={readOnly} plugins={plugins} handleKeyCommand={handleKeyCommand} decorators={decorators} ref={editorRef} />
                <MentionSuggestions
                    open={open}
                    onOpenChange={useCallback(setOpen, [])}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={useCallback(() => setSuggestions([]), [])}
                    entryComponent={({ isFocused, mention, onMouseDown, onMouseEnter, onMouseUp }) => (
                        <div className={classNames(styles.mention, isFocused && "hover")} onMouseDown={onMouseDown} onMouseEnter={onMouseEnter} onMouseUp={onMouseUp}>
                            <p style={{ color: grey }}>{mention.name}</p>
                            <p>{mention.stock}</p>
                        </div>
                    )}
                    popoverContainer={(props) => {
                        // @ts-ignore
                        const element = props.store.getReferenceElement();
                        const { height, left, top } = element ? element.getBoundingClientRect() : { height: 0, left: "30%", top: "20%" };
                        return (
                            <div style={element ? { left, top: top + height + 5 } : { left, top }} className={styles.dropdown}>
                                {props.children}
                            </div>
                        );
                    }}
                />
            </div>
            {!readOnly && (
                <div className={styles.options}>
                    <Divider sx={{ borderColor: greyDarker }} />
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        {stockButtons && (
                            <Box sx={{ mr: "auto" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Publish at..."
                                        value={date}
                                        onChange={(e) => setDate(dayjs(e))}
                                        maxDate={minMaxDate(false, noteType)}
                                        minDate={minMaxDate(true, noteType)}
                                        format={"DD/MM/YYYY"}
                                        slotProps={{
                                            textField: {
                                                variant: "standard",
                                                name: "date",
                                                sx: {
                                                    width: "auto",
                                                    fontSize: "14px",
                                                    "& .MuiInput-underline::before": {
                                                        borderColor: `${textColor}`,
                                                    },
                                                },
                                            },
                                            openPickerButton: { color: "primary" },

                                            desktopPaper: {
                                                sx: {
                                                    "& .MuiPickersDay-root": {
                                                        backgroundColor: "inherit",
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#31aba6",
                                                        },
                                                        "&.Mui-disabled": {
                                                            color: "#808080",
                                                        },
                                                    },
                                                    svg: { color: textColor },
                                                    span: { color: textColor },
                                                },
                                            },

                                            popper: {
                                                sx: {
                                                    color: "red",
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        )}
                        <Tooltip arrow title="Cancel">
                            <IconButton sx={{ width: "fit-content", height: "fit-content", marginRight: 1 }} onClick={() => setCancelOpen(true)}>
                                <ClearIcon color="error" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save">
                            <IconButton sx={{ width: "fit-content", height: "fit-content" }} onClick={save}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </div>
            )}
            <Dialog open={cancelOpen} onClose={() => setCancelOpen(false)}>
                <DialogContent>
                    <DialogContentText id="alert-dialog">Are you sure you want to discard all changes?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCancelOpen(false)}>Cancel</Button>
                    <Button onClick={cancel}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RichTextEditor;
