import axios from "axios";
import { SectorAverages, SectorAveragesHeaders, Extremes, ExtremesHeaders, LargestLendingChanges, LargestLendingChangesHeaders, FlaggedInterestChanges, FlaggedInterestChangesHeaders } from "@/models/short-interest";
import { List, ListQueryParams } from "@/models/table";

export const getSectorAverages = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/API/v2/${universe_id}/short/list/sector_averages?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data };

    try {
        const res = await axios.get<List<SectorAverages, SectorAveragesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getExtremes = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/interface/api/universe/${universe_id}/shorts/screener/list`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data };

    try {
        const res = await axios.get<List<Extremes, ExtremesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getLargestLendingChanges = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, data, next_page_url } = params;

    const url = next_page_url || `/API/v2/${universe_id}/short/list/largest_lending_changes?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data };

    try {
        const res = await axios.get<List<LargestLendingChanges, LargestLendingChangesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getFlaggedInterestChanges = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, universe_id, next_page_url } = params;

    const url = next_page_url || `/API/v2/${universe_id}/short/list/flagged_interest_changes?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<FlaggedInterestChanges, FlaggedInterestChangesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getShortInterestRatio = async (universeId: number, from: string, to: string) => {
    try {
        const res = await axios.get<{ [key: string]: [number, number][] } | string>(`/API/region/${universeId}/shorts/dtc/sector`, {
            params: {
                from,
                to,
            },
        });

        // Only US & Canada returns a string
        if (typeof res.data === "string") return JSON.parse(res.data.replaceAll("NaN", null));
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const exportShortData = async (universeId: number, exportType: "csv" | "xlsx", columns: string[], customFilterModel: {}, sortModel: {}) => {
    try {
        const res = await axios.get<string>(`/interface/api/universe/${universeId}/shorts/screener/list?export=${exportType}`, {
            params: { columns: Array.isArray(columns) && columns.length ? columns.join(",") : null, customFilterModel, sortModel },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

const shortsTreemap = async (universeId: number, size_by: number, color_by: number) => {
    try {
        const res = await axios.get(`/API/v2/si_treemap?index_id=${universeId}&size_by=${size_by}&color_by=${color_by}`);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getShortInterestHolders = async (params: ListQueryParams) => {
    const { data, page_size, next_page_url, filter_options, page } = params;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, category: data?.category ? "summary" : "by_country" };

    const url = next_page_url || `/interface/api/short/esma/holders`;

    try {
        const res = await axios.get<string>(url, {
            params: {
                ...defaultParams,
            },
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getShortInterestHoldersDetail = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, universe_id, data } = params;
    const defaultParams = { page_size, page, ...filter_options, ...data };
    try {
        const res = await axios.get<string>(`interface/api/short/esma/holder/${universe_id}/detail`, {
            params: {
                ...defaultParams,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};
