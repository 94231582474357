import { createContext, Dispatch, SetStateAction } from "react";

export const Context = createContext({
    multiWebSocket: undefined as WebSocket,
    obookWebSocket: undefined as WebSocket,
    ortexWebSocket: undefined as WebSocket,
});

export const ModuleContext = createContext({
    settings: { moduleId: undefined as string, modulePage: undefined as string },
    isFullscreen: undefined as boolean,
    obfuscated: undefined as boolean,
    setObfuscated: (obfuscated: boolean) => {},
    filteredAmount: undefined as number,
    setFilterAmount: (filteredAmount: number) => {},
});

export const OffCanvasContext = createContext({
    position: undefined as string,
});

export const ContentLayoutContext = createContext({
    disableFilters: undefined as boolean,
    setDisableFilters: (disableFilters: boolean) => {},
});

export const AlertDialogContext = createContext({
    hasTrySaving: false as boolean,
    alert: {} as any,
    setAlert: (alert: any) => {},
    validate: () => undefined as boolean,
    errors: {} as any,
    setErrors: (errors: any) => {},
    isSingleRule: true as boolean,
});
