import styles from "./TableCell.module.scss";
import { Fragment, ReactNode, memo, useContext, useEffect, useRef, useState } from "react";
import { classNames, dateParser, getLink, nFormatter } from "@/lib/utils";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { positiveState, negativeState, greyMediumState, greyDarkerState, greyState, greyLighterState } from "@/lib/store";
import ReactTooltip from "react-tooltip";
import { Column } from "@/models/table";
import { isNil } from "lodash";
import ProgressBar from "@/components/progress-bar/ProgressBar";
import ChartCell from "@/components/chart-cell/ChartCell";
import Color from "@/lib/Color";
import { Context, ModuleContext } from "@/lib/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartColumn } from "@fortawesome/pro-solid-svg-icons";
import { useRouter } from "next/router";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import SizeBar from "../size-bar/SizeBar";
import StackedBar from "../stacked-bar/StackedBar";
import Meter from "../meter/Meter";
import ReactCountryFlag from "react-country-flag";
import Link from "next/link";
import SparkChart from "../spark-chart/SparkChart";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ArrowDownward } from "@mui/icons-material";
import { SeriesOptionsType } from "highcharts";
import { usExchanges } from "@/lib/vars";

type Props = {
    button: {
        icon?: ReactNode;
        onClick: (filter: number | string, row: object) => any;
        series?: SeriesOptionsType[];
        rowKey?: string;
    };
    datetime: boolean;
    header: Column;
    link?: string;
    liveData: {
        message: (row: (string | number)[]) => any;
        onMessage: (data: any, name: string, row: any, setLivePrice?: (value: Array<number | string>) => void) => number | string | void;
        websocket?: WebSocket;
    }[];
    onRowClick?: (row: any) => any | void;
    row: any;
    value: any;
    tooltip?: string;
    extraButton?: {
        icon?: ReactNode;
        onClick: (filter: number | string, row: object) => any;
        label?: string;
        color?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning";
        tooltip?: string;
    };
    className?: string;
    stock_dates?: boolean;
};

const TableCell = ({ button, datetime, header, liveData, onRowClick, row, value, tooltip, extraButton, className, stock_dates }: Props) => {
    const { obfuscated } = useContext(ModuleContext);
    const { obookWebSocket, ortexWebSocket } = useContext(Context);

    const chooseWebSocket = (exchange, ticker, isin, currency) => {
        if (exchange) {
            if (usExchanges.includes(exchange)) return obookWebSocket;
            else return ortexWebSocket;
        } else if (ticker && !isin && !currency) return obookWebSocket;
        return ortexWebSocket;
    };

    const grey = useRecoilValue(greyState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const greyMedium = useRecoilValue(greyMediumState);
    const greyLighter = useRecoilValue(greyLighterState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const darkerPositive = new Color(positive).brighten(-0.15).get();
    const darkerNegative = new Color(negative).brighten(-0.15).get();
    const wsRef = useRef(false);
    const { pathname } = useRouter();

    const [data, setData] = useState(value);
    const [livePrice, setLivePrice] = useState([]);

    const getCellComponent = (header: Column, row, cellValue) => {
        if (header.onClick === "link" && header.link !== undefined && !className)
            return (
                <Link href={getLink(row, header.link)} passHref>
                    {cellValue}
                </Link>
            );
        return cellValue;
    };

    useEffect(() => {
        "tooltip" in header && ReactTooltip.rebuild();

        try {
            const isLiveCell = liveData && ["live", "live_millify", "live_millify_pct", "spark_chart"].includes(header.format);

            const callbacks = {};

            const currentWS = chooseWebSocket(row.exchangeSymbol || row.exchangesymbol, row.ticker, row.isin, row.currency || row.currency_iso);

            if (isLiveCell) {
                liveData.forEach((obj, i) => {
                    if (obj.websocket) {
                        callbacks[i] = ({ data }) => {
                            const parsedData = JSON.parse(data);
                            const res = obj.onMessage(parsedData, header.headerName || header.id, row, setLivePrice);
                            res && setData([res]);
                        };
                        obj.websocket.addEventListener("message", callbacks[i]);
                    } else {
                        callbacks[i] = ({ data }) => {
                            const parsedData = JSON.parse(data);
                            const res = obj.onMessage(parsedData, header.headerName || header.id, row, setLivePrice);
                            res && setData([res]);
                        };
                        currentWS && currentWS.addEventListener("message", callbacks[i]);
                    }
                });
            }

            return () => {
                if (isLiveCell) {
                    liveData.forEach((obj, i) => {
                        if (obj.websocket) {
                            if (obj.websocket) {
                                obj.websocket.removeEventListener("message", callbacks[i]);
                            }
                            if (obj.websocket?.readyState === WebSocket.OPEN && wsRef.current) {
                                obj.websocket.send(JSON.stringify({ type: "unsubscribe", identifier: row.ticker }));
                            }
                        } else {
                            if (currentWS) {
                                currentWS.removeEventListener("message", callbacks[i]);
                            }
                            if (currentWS?.readyState === WebSocket.OPEN && wsRef.current) {
                                if (currentWS.url.includes("owss-us")) {
                                    currentWS.send(JSON.stringify({ type: "unsubscribe", identifier: row.ticker }));
                                } else if (row.isin && (row.currency || row.currency_iso)) {
                                    currentWS.send(JSON.stringify({ type: "unsubscribe", identifier: row.isin + (row.currency || row.currency_iso) }));
                                }
                            }
                        }
                    });
                }
            };
        } catch (error) {
            console.log("Error with WS ", error);
        }
    }, [...(liveData || []).map((obj) => obj.websocket), row]);

    useEffect(() => {
        const timeout: NodeJS.Timeout = setTimeout(() => {
            wsRef.current = true;
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    const classes = [];
    let formatted = [...data];
    let tooltipText: string;
    let hasColor = false;
    let image: string;

    if ("tooltip" in header) {
        tooltipText = formatted[formatted.length - 1];
        formatted.pop();
    }

    if ("hasTooltip" in header && tooltip) tooltipText = tooltip;

    switch (header.format) {
        case "size_bar":
            return <SizeBar percentage={data[0]} max={header.max || 100} />;
        case "stacked_bar":
            return <StackedBar percentage={data[0] || 0} />;
        case "starrating":
            return (
                <Tooltip arrow title={!className && data[0].toFixed(2)}>
                    <Box
                        sx={{
                            "& .MuiRating-root": {
                                display: "flex",
                            },
                        }}
                        className={className}
                    >
                        <Rating value={data[0] / 20} readOnly size="small" className={styles.rating} precision={0.1} emptyIcon={<StarIcon style={{ color: greyLighter }} fontSize="inherit" />} />
                    </Box>
                </Tooltip>
            );
        case "spark_chart":
            const [liveData, triggerLevel] = livePrice;

            return <SparkChart data={liveData || []} triggerLevel={triggerLevel || 0} />;
        case "progress_bar":
            return <ProgressBar max={header.max} percentage={data[0]} tooltip={(data[1] || data[0])?.toFixed(2)} className={className} />;
        case "time_series":
            return <ChartCell data={data[0]} datetime={datetime} obfuscated={className} />;
        case "multi_time_series":
            return <ChartCell data={data[0]} isMultiTime datetime={datetime} direction={row.direction[0]} />;
        case "gauge":
            return (
                <div style={{ width: "70%" }} className={className}>
                    <Meter value={data} maxValue="100" />
                </div>
            );
        case "button":
            let iconColor: string = "#31aba6";
            if (button && button.series) {
                if (button?.rowKey) {
                    iconColor = button?.series.some((item) => item.id !== undefined && item.id.includes(row[button.rowKey])) ? "#31aba6" : "grey";
                } else {
                    // @ts-ignore
                    iconColor = button?.series.some((item) => item.id !== undefined && item.row_id === row.id) ? "#31aba6" : "grey";
                }
            }

            return (
                <Button variant={"text"} sx={{ paddingX: 0, paddingY: 2 }} className={classNames(styles.Button, className)} onClick={obfuscated || className ? undefined : () => button?.onClick(data[0] || row?.peer_id, row)}>
                    {button?.icon || <FontAwesomeIcon icon={faChartColumn} height={25} color={iconColor} />}
                </Button>
            );
        case "extra-button":
            const onClick = () => {
                if (obfuscated || className) {
                    return;
                } else {
                    if (extraButton.label === "Delete Alert") {
                        extraButton?.onClick(row.id, row);
                    } else if (extraButton.label === "Edit Alert") {
                        extraButton?.onClick(row, null);
                    } else {
                        extraButton?.onClick(data[0] || row?.peer_id, row);
                    }
                }
            };

            return extraButton.label === "Reset Alert" ? null : (
                <Tooltip title={extraButton?.tooltip} arrow>
                    <Button variant={"text"} sx={{ paddingX: 0, paddingY: 2 }} className={classNames(styles.Button, className)} onClick={onClick} disabled={row.company_type_id === null} color={extraButton.color || "primary"}>
                        {extraButton?.icon || <FontAwesomeIcon icon={faChartColumn} height={25} color="#31aba6" />}
                    </Button>
                </Tooltip>
            );
        case "company":
            const { link } = header;
            const company = header.field;
            const { ticker, status } = row;
            const inactiveStatus = status === "inactive";
            const styledDiv = link !== undefined && !inactiveStatus ? { cursor: "pointer" } : {};

            const defaultComponent = (
                <div style={styledDiv} className={className}>
                    {inactiveStatus ? <Chip label={<Typography variant="captionSessionChip">Inactive</Typography>} color="error" variant="outlined" size="small" sx={{ fontSize: "0.75rem" }} /> : <p style={{ color: grey }}>{ticker}</p>}
                    <p>{row[company]}</p>
                </div>
            );

            if (link !== undefined && !inactiveStatus) {
                return (
                    <Link href={getLink(row, link)} passHref>
                        {defaultComponent}
                    </Link>
                );
            }

            return defaultComponent;

        case "stock_list":
            classes.push(styles.row);
            return (
                <div className={className}>
                    {formatted.map((stock) => (
                        <Link href={getLink(stock, "")} passHref key={stock.name}>
                            <Tooltip title={!className && stock.name} arrow placement="bottom-start" key={stock.ticker}>
                                <Chip size="small" label={stock.ticker} variant="outlined" sx={{ m: 0.25 }} />
                            </Tooltip>
                        </Link>
                    ))}
                </div>
            );
        case "date":
            formatted[0] = formatted[0] ? dateParser(dayjs.utc(formatted[0]), "D MMM YYYY", "UTC", stock_dates) : "N/A";
            classes.push(styles.left);
            break;
        case "datetime":
            if (row.format_override?.[0] === "date") {
                formatted[0] = formatted[0] ? dateParser(dayjs.utc(formatted[0]), "D MMM YYYY", undefined, stock_dates) : "N/A";
                classes.push(styles.left, styles.nowrap);
            } else {
                formatted[0] = formatted[0] ? dateParser(dayjs(formatted[0]), "D MMM YYYY [at] HH:mm", undefined, stock_dates) : "N/A";
                classes.push(styles.left);
            }
            break;
        case "millify":
            const splittedPathname = pathname.split("/");
            const isIbkrPage = splittedPathname[splittedPathname.length - 1] === "short-interest" && (splittedPathname[1] === "s" || splittedPathname[1] === "id");
            for (let i in formatted) formatted[i] = nFormatter(parseFloat(formatted[i]), 2);
            if (formatted[0] === "-0") formatted[0] = "0";
            if (formatted[0] === "-1" && isIbkrPage) formatted[0] = "> 10m";
            if (formatted.length < 2) formatted[0] = formatted[0].split(/(^-?[0-9]+)/);
            if (Array.isArray(formatted[0]) && formatted[0].length > 1) formatted[0].shift();
            classes.push(styles.nowrap, styles[formatted.length > 1 ? "subtext" : "center"], styles[Array.isArray(formatted[0]) && formatted[0].length > 1 && "millify"]);
            break;
        case "live_millify":
            const [liveMillify] = livePrice;
            formatted[0] = liveMillify ?? "N/A";
            classes.push(styles.right, styles.nowrap);
            break;
        case "millify_pct":
            if (isFinite(parseFloat(formatted[0]))) {
                classes.push(styles.millify);
                formatted[0] = nFormatter(parseFloat(formatted[0]), 2);
                if (formatted[0] === "-0") formatted[0] = "0";
                formatted[0] = `${formatted[0]}‎‏‏‎ ‎%`.split(/(^-?[0-9]+)/);
                formatted[0].shift();
            } else formatted[0] = "N/A";
            classes.push(styles.left, styles.nowrap);
            break;
        case "millify_arrow":
            const value = parseFloat(formatted[0]).toFixed(2);
            return (
                <p className={classNames(styles["millify_arrow"], styles.centeredCells)}>
                    {value}
                    {row.above ? <ArrowUpwardIcon /> : <ArrowDownward />}
                </p>
            );
        case "live_millify_pct":
            const [liveMillifyPct] = livePrice;
            formatted[0] = liveMillifyPct ?? "N/A";
            if (formatted[0] !== "N/A" && !formatted[0].endsWith("%")) formatted[0] += " %";
            break;
        case "currency":
            formatted[0] = nFormatter(parseFloat(formatted[0]), 2).split(/(^-?[0-9]+)/);
            if (formatted[0].length > 1) formatted[0].shift();
            classes.push(styles.center, styles.nowrap, styles[formatted[0].length > 1 && "millify"]);
            break;
        case "arrow_str":
            if (formatted[0] !== "") image = formatted.shift()?.toLowerCase();
            break;
        case "consensus":
            classes.push(styles.consensus);
            break;
        case "dec2":
            for (let i in formatted) {
                formatted[i] === null ? (formatted[i] = "N/A") : formatted[i]?.toString().split(".").length > 1 ? (formatted[i] = formatted[i]?.toFixed(2)) : formatted[i];
            }
            classes.push(styles[formatted?.length > 1 ? "subtext" : "center"]);
            break;
        case "money":
            for (let i in formatted) {
                formatted[i] === null ? (formatted[i] = "N/A") : (formatted[i] = formatted[i]?.toFixed(2));
                if (formatted[i]?.split(".")[1] === "00") formatted[i] = formatted[i].split(".")[0];
                if (formatted[i]?.toString().length > 3) {
                    formatted[i] = formatted[i]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
            classes.push(styles[formatted?.length > 1 ? "subtext" : "center"]);
            break;
        case "str_flag":
            return (
                <>
                    <ReactCountryFlag
                        countryCode={data[0]?.[1]}
                        aria-label={data[0]?.[0]}
                        svg
                        style={{
                            marginRight: "5px",
                        }}
                    />
                    <p>{data[0]?.[0]}</p>
                </>
            );
    }

    if (!pathname.includes("/screening")) {
        switch (formatted[0]?.toLowerCase?.()) {
            case "buy":
                classes.push(styles.bold, styles.center, styles["big-text"], styles.direction, "positive");
                break;
            case "sell":
                classes.push(styles.bold, styles.center, styles["big-text"], styles.direction, "negative");
                break;
        }
    }

    if (header.id === "isocode") classes.push(styles.center, styles["big-text"]);

    if (("class_positive" in header && "class_negative" in header) || ("classPositive" in header && "classNegative" in header)) hasColor = !isNil(data[0]);

    return (
        <div className={classNames(styles.TableCell, className)} style={{ margin: header.hasSubtext ? "auto" : "inherit" }}>
            {image && (
                <div className={styles.arrow}>
                    {image === "up" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.67 64">
                            <path fill={positive} d="M19.57.78L.78 19.5a2.67 2.67 0 003.77 3.78L18.67 9.21v52.12a2.67 2.67 0 105.33 0V9l14.11 14.27a2.67 2.67 0 103.78-3.76L23.35.79a2.67 2.67 0 00-3.78 0z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.67 64">
                            <path
                                fill={negative}
                                d="M23.1 63.22L41.88 44.5a2.67 2.67 0 10-3.76-3.78L24 54.79V2.67a2.67 2.67 0 10-5.33 0V55L4.56 40.73a2.67 2.67 0 00-3.77 0A2.63 2.63 0 000 42.61a2.68 2.68 0 00.77 1.88l18.55 18.72a2.67 2.67 0 003.78 0z"
                            />
                        </svg>
                    )}
                </div>
            )}
            <div className={classNames(...classes, styles.container, hasColor && (data[0] < 0 ? "negative" : "positive"), header.link ? styles.link : null)}>
                {formatted.map((value, i) => {
                    const cellValue = Array.isArray(value);
                    const onClick = header.onClick !== "link" && onRowClick && !className ? { onClick: () => onRowClick(row) } : {};

                    const cell = (
                        <Tooltip arrow title={(i && !tooltip) || obfuscated || className ? undefined : tooltipText}>
                            <div key={i} {...onClick} className={className} style={{ cursor: header.onClick || onRowClick ? "pointer" : "initial" }}>
                                {cellValue ? (
                                    value.map((text, j) => <p key={j}>{text}</p>)
                                ) : header?.id?.includes("description") ? (
                                    <p dangerouslySetInnerHTML={{ __html: value }} className={styles[value.length > 100 && "description"]}></p>
                                ) : header?.field === "status" && row?.status === "Triggered" ? (
                                    <Box display={"flex"} alignItems={"center"}>
                                        <p>{value?.toString()}</p>
                                        <Tooltip title="This alert has already triggered, reset the alert to allow it to trigger again" arrow>
                                            <IconButton color="primary" onClick={() => extraButton?.onClick(row, null)}>
                                                <ReplayIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                ) : (
                                    <p>{value?.toString()}</p>
                                )}
                            </div>
                        </Tooltip>
                    );
                    return <Fragment key={i}>{getCellComponent(header, row, cell)}</Fragment>;
                })}
            </div>
            <style jsx>{`
                .${styles.subtext} div:last-child p {
                    color: ${grey};
                }

                .${styles.consensus} div:nth-child(1) {
                    border-top: 26px solid ${negative};
                    border-right: 26px solid ${darkerNegative};
                }

                .${styles.consensus} div:nth-child(2) {
                    border-top: 26px solid ${greyMedium};
                    border-right: 26px solid ${greyDarker};
                }

                .${styles.consensus} div:nth-child(3) {
                    border-top: 26px solid ${positive};
                    border-right: 26px solid ${darkerPositive};
                }
            `}</style>
        </div>
    );
};

export default memo(TableCell);
