import Module from "@/components/module/Module";
import styles from "./ContentLayout.module.scss";
import { Box } from "@mui/material";
import { HelpProps, NoBackgroundProps, NoPaddingProps } from "@/models/content-layout";
import { MutableRefObject, ReactNode, RefObject, useState } from "react";
import { Chart } from "highcharts";
import { ContentLayoutContext } from "@/lib/Context";
import { classNames } from "@/lib/utils";

type Props = {
    allowFullscreen?: boolean;
    children: JSX.Element;
    filters?: JSX.Element;
    help?: HelpProps;
    title: string | JSX.Element;
    modalComponent?: ReactNode;
    stockPage?: boolean;
    sonification?: MutableRefObject<{
        chart: Chart;
        container: RefObject<HTMLDivElement>;
    }>;
    autoScroll?: boolean;
};


const noPadding: NoPaddingProps = { source: "body", type: "all" };

const ContentLayout = ({ allowFullscreen, children, help, filters, title, modalComponent, stockPage, sonification, autoScroll = false }: Props) => {

    const [disableFilters, setDisableFilters] = useState(false);
    return (
        <ContentLayoutContext.Provider value={{ disableFilters, setDisableFilters }}>

            <div className={styles.ContentLayout}>
                <Module allowFullscreen={allowFullscreen} filters={filters} help={help} title={title} noPadding={noPadding} modalComponent={modalComponent} contentLayout stockPage={stockPage} sonification={sonification} >
                    <Box className={classNames(styles.container, autoScroll ? styles["auto-scroll"] : "")}>{children}</Box>
                </Module>
            </div >
        </ContentLayoutContext.Provider>
    );
};

export default ContentLayout;
