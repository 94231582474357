import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useRecoilValue } from "recoil";
import { backgroundColorState, textColorState } from "@/lib/store";
import { v4 as uuidv4 } from "uuid";

interface CustomViewsUnsavedChangesModalProps {
    text?: string;
    show: boolean;
    setShow: (value: boolean) => void;
    agree: () => void;
    continueWithoutSaving?: () => void;
    customCancel?: () => void;
}

const CustomViewsUnsavedChangesModal = ({ text, show, setShow, agree, continueWithoutSaving, customCancel }: CustomViewsUnsavedChangesModalProps) => {
    const textColor = useRecoilValue(textColorState);
    const backgroundColor = useRecoilValue(backgroundColorState);

    const id = uuidv4();

    return (
        <Dialog
            open={show}
            onClose={setShow}
            sx={{
                [`& .MuiDialog-paper`]: { backgroundColor },
            }}
            aria-labelledby={id}
        >
            <DialogTitle id={id} sx={{ color: textColor }}>
                You have unsaved changes. {text ? text : "Do you want to save before leaving this page?"}
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => {
                    if (customCancel !== undefined) {
                        customCancel()
                        setShow(false)

                    } else {
                        setShow(false)
                    }
                }}>Cancel</Button>
                <Button onClick={continueWithoutSaving}>Do not save changes</Button>
                <Button onClick={agree} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomViewsUnsavedChangesModal;
