import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Browser } from "@capacitor/browser";
import { isOrtexApp } from "@/lib/utils";
import { setUserTermsAndConditions } from "@/controllers/auth";
import { AgreementStatus, User } from "@/models/auth";
import styles from "./AgreementsModal.module.scss";
import { CheckBox } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { greyLighterState } from "@/lib/store";

type AgreementsModalTypes = {
    open: boolean;
    setOpen: (open: boolean) => void;
    visitor: boolean;
    user: User;
};

const setCookie = (name, value, days) => {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
};

let timeout: NodeJS.Timeout;

const TANDC_URL = "https://ortex.com/TermsAndConditions";
const PRIVACY_POLICY_URL = "https://ortex.com/PrivacyPolicy";

const AgreementsModal = ({ open, setOpen, visitor, user }: AgreementsModalTypes) => {
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery("(max-width:768px)");
    const greyColor = useRecoilValue(greyLighterState);
    const visitorCookie = document.cookie.split("tandcagreement=")[1]?.split(";")[0];
    const [tandcAgreement, setTandcAgreement] = useState(false);
    const [essentialEmails, setEssentialEmails] = useState(false);
    const [marketingEmails, setMarketingEmails] = useState(false);

    const openAppBrowser = async () => {
        await Browser.open({ url: TANDC_URL });
    };

    const handleAgree = () => {
        if (visitor) {
            setCookie("tandcagreement", "true", 30);
        } else {
            const preferences = {
                ortex_tandc: tandcAgreement,
                essential_emails: essentialEmails,
                marketing_emails: marketingEmails,
            };

            if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
                setUserTermsAndConditions(preferences);
            }
        }
        setOpen(false);
    };

    const tandcLink = (
        <a onClick={() => (isOrtexApp() ? openAppBrowser() : undefined)} href={isOrtexApp() ? undefined : TANDC_URL} target="_blank" rel="noreferrer">
            Terms and Conditions
        </a>
    );

    const privacyPolicyLink = (
        <a onClick={() => (isOrtexApp() ? openAppBrowser() : undefined)} href={isOrtexApp() ? undefined : PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
            Privacy Policy
        </a>
    );

    const dialogTitle = (tandc: AgreementStatus) => {
        if (tandc === "not_agreed") {
            return <span>Before continuing, please read and agree to the following:</span>;
        } else if (tandc === "new_version") {
            return <span>We have updated our T&C's. Before continuing, please read and agree to the following: </span>;
        } else if (tandc === "expired") {
            return <span>You need to reconfirm your agreements below. Please read and agree to the following: </span>;
        }
    };

    useEffect(() => {
        timeout = setTimeout(() => {
            setOpenModal(open);
        }, 500);

        return () => clearTimeout(timeout);
    }, [open]);

    useEffect(() => {
        if (visitor && visitorCookie) setOpen(false);
    });

    return (
        <Dialog
            maxWidth="sm"
            PaperProps={{
                style: {
                    width: !isMobile && "85vw",
                    overflowX: "hidden",
                },
            }}
            fullScreen={isMobile}
            open={openModal}
            aria-labelledby="responsive-dialog-title"
            className={styles.AgreementsModal}
        >
            <DialogTitle id="alert-dialog-title">{"Terms and Conditions"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {visitor ? (
                        <>
                            <span>By using this website I am agreeing to the {tandcLink}.</span>
                        </>
                    ) : (
                        <>
                            {dialogTitle(user?.ortex_tandc)}
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={(e) => setEssentialEmails(e.target.checked)} />} label="I agree to receive essential emails." />
                                <FormControlLabel control={<Checkbox onChange={(e) => setMarketingEmails(e.target.checked)} />} label="I agree to receive marketing and promotional emails." />
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => setTandcAgreement(e.target.checked)} />}
                                    label={
                                        <span>
                                            I have read and agree to the {tandcLink} and {privacyPolicyLink}
                                        </span>
                                    }
                                />
                            </FormGroup>
                        </>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pb: "15px" }}>
                <Button disabled={!visitor && !tandcAgreement} sx={{ color: `${!visitor && !tandcAgreement && greyColor} !important` }} onClick={handleAgree}>
                    {visitor ? "Okay" : "Accept"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AgreementsModal;
