import axios from "axios";
import { FindInstruments } from "@/models/find-instruments";

export const findInstruments = async (text: string, refresh?: boolean) => {
    try {
    const res = await axios.get<FindInstruments[]>(`/API/v2/find/0/${text}`, {
            params: refresh
                ? {
                      refresh: 2,
                  }
                : {},
        })
        
            return res.data ? res.data : [];
        }
        catch(err) {
            throw err;
        };
};
