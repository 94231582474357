import Paper from "@mui/material/Paper";
import { useRecoilValue } from "recoil";
import { backgroundColorState, borderColorState, themeState } from "@/lib/store";
import styles from "./SideMenu.module.scss";
import { MenuListItems } from "../menu/utils";
import { useState } from "react";
import { classNames } from "@/lib/utils";

const SideMenu = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const theme = useRecoilValue(themeState);
    const borderColor = useRecoilValue(borderColorState);

    return (
        <span
            id="sideMenu"
            className={styles.SideMenuTrigger}
            onMouseOver={() => {
                setMenuVisible(true);
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                setIsHovered(false);
                setTimeout(() => {
                    setMenuVisible(false);
                }, 200);
            }}
        >
            {menuVisible && (
                <Paper
                    className={classNames(styles.SideMenu, !isHovered && styles.HideMenu)}
                    elevation={theme === "dark" ? 0 : 3}
                    variant={theme === "dark" ? "outlined" : "elevation"}
                    sx={{
                        backgroundColor, borderColor: "rgb(62, 62, 62)",
                        "& .MuiListItemIcon-root": {
                            minWidth: "auto",
                        },
                    }}
                >
                    <MenuListItems isIconOnly={true} />
                </Paper>
            )}
        </span>
    );
};

export default SideMenu;
