import styles from "./PaymentWelcome.module.scss";
import { Dispatch, FC, SetStateAction } from "react";
import { Plan } from "@/models/subscription";
import dayjs from "dayjs";
import RocketIcon from "@mui/icons-material/Rocket";
import { Box, Button, Card, Typography } from "@mui/material";

interface PropsTypes {
    plan: Plan;
    closeModal: Dispatch<SetStateAction<boolean>>;
}

const PaymentWelcome: FC<PropsTypes> = ({ closeModal, plan }) => {
    const onClick = () => {
        closeModal(false);
        window.location.href = `${window.location.origin}/markets-overview`;
    };

    return (
        <Box className={styles.PaymentWelcome}>
            <Card className={styles.card} elevation={0}>
                <RocketIcon sx={{ fontSize: "90px" }} color={"primary"} />
                <Typography component="h1" variant="h1">
                    Welcome to ORTEX
                </Typography>
                <Typography variant="h2">
                    Your {plan.product_name} subscription has begun as of {dayjs().format("DD/MM/YYYY")}
                </Typography>
            </Card>
            <Button className={styles.btn} variant="contained" onClick={onClick} sx={{ textTransform: "initial" }}>
                Begin your journey
            </Button>
        </Box>
    );
};

export default PaymentWelcome;
