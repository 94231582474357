import axios from "axios";

export const getModuleHelp = async (module_id: string, module_page: string) => {
    try {
        const res = await axios.get<string>("/API/v2/get_module_help", {
            params: {
                module_id,
                module_page,
            },
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const upsertModuleHelp = async (module_id: string, module_page: string, help_data: string) => {
    try {
        const res = await axios.post<string>(
            "/API/v2/get_module_help",
            {
                module_id,
                module_page,
                help_data,
            },
            {
                params: {
                    module_id,
                    module_page,
                },
            }
        );
        return res.data;
    } catch (err) {
        throw err;
    }
};
