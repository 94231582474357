import styles from "./Checkbox.module.scss";
import { ChangeEventHandler, useState, useEffect, forwardRef, PropsWithChildren } from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";

interface PropsTypes {
    checked?: boolean;
    onChange?: (checked: boolean, value?: string | number) => any;
    required?: boolean;
    size?: "sm" | "md" | "lg";
    value?: string | number;
}

const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<PropsTypes>>(({ checked, children, onChange, required, value }, ref) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => setIsChecked(checked), [checked]);

    const onChecked: ChangeEventHandler<HTMLInputElement> = e => {
        setIsChecked(e.target.checked);
        onChange(e.target.checked, value);
    };

    return (
        <label className={styles.Checkbox}>
            <MuiCheckbox inputRef={ref} value={value} onChange={onChange ? onChecked : undefined} checked={isChecked} required={required} />
            {children}
        </label>
    );
});

export default Checkbox;
