import { backgroundColorState, blackLighterState, dividerLightState, greyLighterState, greyMediumState, negativeState, newbackgroundColorState, popoverContentState, positiveState, textColorState, themeState, whiteMediumState } from "@/lib/store";
import { Box, Card, CardContent, CardHeader, IconButton, Popover, Tooltip, Typography, useMediaQuery } from "@mui/material";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from "@mui/icons-material/Info";
import { useRecoilValue } from "recoil";

import styles from "./TickertapePopover.module.scss";
import { RefObject, useEffect, useRef, useState } from "react";
import { getStockInfoOnServer } from "@/controllers/stock";
import { isEmpty } from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Chart } from "highcharts";
import dayjs from "dayjs";
import { classNames, formatDate, getTimeDifference, nFormatter } from "@/lib/utils";
import Color from "@/lib/Color";
import Link from "next/link";

const TickertapePopover = ({ instrument, anchorEl, handleClose }) => {
    const theme = useRecoilValue(themeState);

    const blackLighter = useRecoilValue(blackLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const popoverContentBackground = useRecoilValue(popoverContentState);
    const textColor = useRecoilValue(textColorState);
    const greyMedium = useRecoilValue(greyMediumState);
    const negative = useRecoilValue(negativeState);
    const positive = useRecoilValue(positiveState);
    const whiteMedium = useRecoilValue(whiteMediumState);
    const newbackgroundColor = useRecoilValue(newbackgroundColorState);

    const secondaryColor = theme === "dark" ? (new Color(textColor).brighten(-0.1).get() as string) : (new Color(greyMedium).brighten(0.1).get() as string);

    const isMobile = useMediaQuery("(max-width: 1024px)");

    const [stockData, setStockData] = useState<any>(null);

    const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement>; }>(null);
    const [options, setOptions] = useState({
        chart: {
            // @ts-ignore
            skipClone: true,
            backgroundColor: "transparent",
        },
        responsive: {
            enabled: true,
        },
        series: [
            {
                data: [],
                lineWidth: 2,
                shadow: false,
                type: "spline",
                color: "#31ABA6",
                animation: false,
                showInLegend: false,
                marker: {
                    radius: 0,
                    animation: false,
                    lineColor: "transparent",
                    states: { hover: { radius: 4 } },
                },
                states: {
                    hover: {
                        halo: { size: 0 },
                        lineWidthPlus: 0,
                    },
                },
            },
        ],
        tooltip: {
            padding: 4,
            hideDelay: 0,
            shadow: false,
            borderWidth: 0,
            borderRadius: 0,
            animation: false,
            headerFormat: "",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            pointFormatter: function () {
                return `${this.y > 10 ? formatDate(dayjs(this.x)) + " | " + this.y.toFixed(2) : formatDate(dayjs(this.x)) + " | " + this.y.toFixed(2)}`;
            },
            style: {
                fontSize: "12px",
                color: "#fff",
                fontFamily: "Roboto",
            },
        },
        plotOptions: {
            series: {
                shadow: false,
                animation: false,
                marker: { animation: false },
            },
        },
        title: { text: "" },
        yAxis: { visible: false },
        xAxis: { visible: false },
        credits: { enabled: false },
        exporting: { enabled: false },
    });

    const timeDiff = getTimeDifference(instrument?.[1].timestamp);

    useEffect(() => {
        if (instrument && instrument[1].exchange && instrument[1].ticker) {
            getStockInfoOnServer(instrument[1].exchange as string, instrument[1].ticker as string, true).then((res) => {
                if (!isEmpty(res)) {
                    setStockData(res);
                    setOptions({
                        ...options,
                        series: [
                            {
                                ...options.series[0],
                                data: res.price_trend,
                            },
                        ],
                    });
                }
            });
        } else {
            setStockData(null);
            setOptions({
                ...options,
                series: [
                    {
                        ...options.series[0],
                        data: [],
                    },
                ],
            });
        }
    }, [instrument?.[0]]);

    const percentageVariationColor = (percentage: number) => {
        if (percentage > 0) {
            return positive;
        } else if (percentage < 0) {
            return negative;
        } else {
            return textColor;
        }
    };

    const percentageVariationArrow = (percentage: number) => {
        if (percentage > 0) {
            return <ArrowDropUpIcon sx={{ color: positive, fontSize: "16px" }} />;
        } else if (percentage < 0) {
            return <ArrowDropDownIcon sx={{ color: negative, fontSize: "16px" }} />;
        } else return;
    };

    const removeMinusSign = (percentage: number) => {
        if (percentage < 0) {
            return percentage * -1;
        } else return percentage;
    };

    const popoverContent = (
        <Card className={styles.card} sx={{ color: textColor }}>
            <CardHeader
                className={styles.header}
                sx={{ backgroundColor: theme === "dark" ? backgroundColor : whiteMedium }}
                title={
                    <Box className={styles["space-between"]}>
                        <Box className={styles.title}>
                            <Typography className={styles.stockName}>{instrument?.[1].name}</Typography>
                            <Typography className={styles.stockTicker} sx={{ color: secondaryColor }}>
                                {instrument?.[0]}
                            </Typography>
                        </Box>
                        {timeDiff[1] < 0 ||
                            timeDiff[1] > 20 ||
                            (timeDiff[0] !== 0 && (
                                <Tooltip arrow title={`Last updated: ${dayjs(instrument?.[1].timestamp).format("DD MMM HH:mm")}`}>
                                    <InfoIcon sx={{ fontSize: "14px", marginLeft: 0.5 }} />
                                </Tooltip>
                            ))}
                    </Box>
                }
                subheader={
                    <Box className={classNames(styles.subtitle, styles["space-between"])} sx={{ color: secondaryColor }}>
                        {stockData &&
                            (stockData?.sector?.length > 1 ? (
                                <>
                                    <Typography variant="body2" component="p" sx={{ fontWeight: 600 }}>
                                        {stockData.sector[0]}
                                    </Typography>
                                    <ArrowForwardIcon sx={{ color: textColor, fontSize: "16px" }} />
                                    <Typography variant="body2" component="p">
                                        {stockData.sector[stockData.sector.length - 1]}
                                    </Typography>
                                </>
                            ) : (
                                stockData?.sector?.[0]
                            ))}
                    </Box>
                }
                action={
                    instrument?.[1].exchange && instrument?.[1].ticker ? (
                        <IconButton aria-label="Launch" onClick={handleClose}>
                            <Link href={`/s/${instrument?.[1].exchange}/${instrument?.[1].ticker}`} passHref>
                                <a target={isMobile ? "_self" : "_blank"}>
                                    <LaunchRoundedIcon sx={{ color: textColor }} />
                                </a>
                            </Link>
                        </IconButton>
                    ) : null
                }
                disableTypography
            ></CardHeader>
            <CardContent className={styles.content} sx={{ backgroundColor: theme === "dark" ? popoverContentBackground : newbackgroundColor }}>
                <Box className={styles.stockData}>
                    <Box className={styles.price}>
                        <Box className={styles.lastPrice}>
                            <Typography variant="body2" component="p" className={styles.contentSubtitle} sx={{ color: secondaryColor }}>
                                Last Close
                            </Typography>
                            <Typography variant="body2" component="p">
                                {instrument?.[1].lastPrice}
                            </Typography>
                        </Box>
                        <Box className={styles.priceTrend}>
                            <Typography variant="body2" component="p" className={styles.contentSubtitle} sx={{ color: secondaryColor }}>
                                Price Trend - 1 YR
                            </Typography>
                            <Box className={styles.chartContainer}>
                                <HighchartsReact highcharts={Highcharts} constructorType="chart" options={options} ref={chartRef} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.market}>
                        <Box className={styles.row}>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    1M Return
                                </Typography>
                                <Box className={styles.priceVariation} sx={{ color: percentageVariationColor(stockData?.["1m_return"]) }}>
                                    {percentageVariationArrow(stockData?.["1m_return"])}
                                    <Typography variant="body2" component="p">
                                        {(stockData && removeMinusSign(stockData["1m_return"]) + "%") || "N/A"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    1Y Return
                                </Typography>
                                <Box className={styles.priceVariation} sx={{ color: percentageVariationColor(stockData?.["1y_return"]), justifyContent: "flex-end" }}>
                                    {percentageVariationArrow(stockData?.["1y_return"])}
                                    <Typography variant="body2" component="p">
                                        {(stockData && removeMinusSign(stockData["1y_return"]) + "%") || "N/A"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={styles.row}>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    52W High
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {(stockData && nFormatter(stockData?.high_52_week, 2)) || "N/A"}
                                </Typography>
                            </Box>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    52W Low
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {(stockData && nFormatter(stockData?.low_52_week, 2)) || "N/A"}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={styles.row}>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    Market Cap
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {(stockData && nFormatter(stockData?.mkt_cap_usd, 2)) || "N/A"}
                                </Typography>
                            </Box>
                            <Box className={styles.profits}>
                                <Typography variant="body2" component="p" sx={{ color: secondaryColor }}>
                                    Volume
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {(stockData && nFormatter(stockData?.volume_3m_this_exchange, 2)) || nFormatter(stockData?.volume_3m_usd, 2) || "N/A"}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box></Box>
            </CardContent>
        </Card>
    );

    return (
        <Popover
            open={instrument !== null}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "transparent",
                },
            }}
            slotProps={{
                paper: {
                    sx: {
                        p: 0,
                        bgcolor: "transparent",
                        width: "380px",
                        boxShadow: 3,
                        borderRadius: "4px",
                    },
                },
            }}
        >
            {instrument && popoverContent}
        </Popover>
    );
};

export default TickertapePopover;
