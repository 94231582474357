import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton, Divider, Grid, Box } from "@mui/material";
import styles from "./HeaderCustomViews.module.scss";
import { textColorState, customViewsState, customViewsActiveTabState } from "@/lib/store";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MonitorIcon from "@mui/icons-material/Monitor";
import CircleIcon from "@mui/icons-material/Circle";
import { useRouter } from "next/router";
import Tooltip from "@mui/material/Tooltip";

export default function HeaderCustomViews() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const customViews = useRecoilValue(customViewsState);
    const setTabIndex = useSetRecoilState(customViewsActiveTabState);

    const router = useRouter();
    const { pathname } = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewClick = (view: any) => {
        if (pathname !== "/custom-views") {
            router.push("/custom-views");
        }

        const index = customViews.findIndex((v: any) => v.id === view.id);
        setTabIndex(index);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const textColor = useRecoilValue(textColorState);

    return (
        <div className={styles.customViewsWrapper}>
            <Tooltip arrow title="Custom Views">
                <IconButton aria-describedby={id} onClick={handleClick}>
                    <MonitorIcon color="action" style={{ color: textColor }} />
                </IconButton>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ p: 2 }}>Your custom views</Typography>

                <div style={{ color: textColor }}>
                    <Divider />
                </div>

                {customViews &&
                    customViews.map((customView) => (
                        <div key={customView.id}>
                            <Button color="success" style={{ textTransform: "none", color: textColor, padding: "0px", width: "100%" }} onClick={() => handleViewClick(customView)}>
                                <Box sx={{ p: 2, width: "100%" }}>
                                    <div className={styles.header_custom_views}>
                                        <CircleIcon color={customView.modules.length > 0 ? "success" : "inherit"} />
                                        <Grid item xs>
                                            <div className={styles.header_custom_views_title}>{customView.name}</div>
                                            <div className={styles.header_custom_views_state}>
                                                <Typography variant="caption">Updated: {customView?.lastUpdated ? customView?.lastUpdated : "Unavailable"}</Typography>
                                            </div>
                                        </Grid>
                                    </div>
                                </Box>
                            </Button>
                            <div style={{ color: textColor }}>
                                <Divider />
                            </div>
                        </div>
                    ))}
            </Popover>
        </div>
    );
}
