import { useEffect, useState } from "react";
import { getPromo } from "@/controllers/subscription";
import { Promo } from "@/models/subscription";
import { isEmpty } from "lodash";
import SignUpForm from "@/components/sign-up-form/SignUpForm";
import SignUpDiscountForm from "@/components/sign-up-discount-form/SignUpDiscountForm";
import CognitoLogin from "../cognito-login/CognitoLogin";
import { Box } from "@mui/material";
import styles from "./SignUp.module.scss";

const SignUp = () => {
    const [promotion, setPromotion] = useState<Promo>();

    useEffect(() => {
        getPromo().then(setPromotion);
    }, []);

    // return (
    //     <Box className={styles.signUpContainer}>
    //         <CognitoLogin register />
    //     </Box>
    // );
    return !promotion ? null : isEmpty(promotion) ? <SignUpForm /> : <SignUpDiscountForm />;
};

export default SignUp;
