import { Typography } from "@mui/material";
import styles from "./CountdownBanner.module.scss";
import { FC } from "react";
import Countdown from "react-countdown";

const CountdownBanner: FC<{ ortexFree?: boolean }> = ({ ortexFree }) => {
    const CountdownElement = ({ digits, text }) => {
        return (
            <div className={styles.countdownElement}>
                <div>
                    {digits.map((digit, i) => (
                        <Typography key={i} className={styles.digit}>
                            {digit}
                        </Typography>
                    ))}
                </div>
                <Typography className={styles.text}>{text}</Typography>
            </div>
        );
    };

    const renderer = ({ days, hours, minutes, seconds }) => {
        const convertToTwoDigits = (number) => {
            if (number < 10) {
                return [0, number];
            }
            return [number.toString().slice(0, 1), number.toString().slice(1)];
        };

        return (
            <div className={styles.countdown}>
                <CountdownElement digits={convertToTwoDigits(days)} text="days" />
                <CountdownElement digits={convertToTwoDigits(hours)} text="hrs" />
                <CountdownElement digits={convertToTwoDigits(minutes)} text="mins" />
                <CountdownElement digits={convertToTwoDigits(seconds)} text="secs" />
            </div>
        );
    };

    return (
        <div className={styles.bannerContainer}>
            <div className={styles.banner}>
                <div className={styles.redBackground}></div>
                <div className={styles.container}>
                    <div className={styles.discountCircle}>
                        <Typography>up to</Typography>
                        <Typography>20%</Typography>
                        <Typography>off</Typography>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.promoTitle}>
                            <div className={styles.stars}>
                                {[1, 2, 3, 4, 5].map((_, i) => (
                                    <svg key={i} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
                                    </svg>
                                ))}
                            </div>
                            <Typography className={styles.title}>4th JULY </Typography>
                            <Typography className={styles.title}>INDEPENDENCE DAY SALE</Typography>
                        </div>
                        <div className={styles.discountCircle}>
                            <Typography>up to</Typography>
                            <Typography>20%</Typography>
                            <Typography>off</Typography>
                        </div>
                        <div className={styles.countdownContainer}>
                            <Typography>limited time only</Typography>
                            <Countdown renderer={renderer} date={1657080000000} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.promotextBanner}>
                <h3>The 4th of July discount is extended for one more day! </h3>
                <Typography>Last chance to get 20% off ORTEX Advanced!</Typography>
            </div>
        </div>
    );
};

export default CountdownBanner;
