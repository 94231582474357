import { FC } from "react";
import styles from "./SizeBar.module.scss";
import { Tooltip } from "@mui/material";

interface PropsTypes {
    max?: number;
    percentage: number;
}

const SizeBar: FC<PropsTypes> = ({ max = 100, percentage }) => {
    return (
        <>
            <Tooltip title={percentage.toFixed(2)} arrow>
                <div className={styles.SizeBar}>
                    <div className={styles["progress-bar"]}>
                        {percentage < 0 && (
                            <div style={{ width: `${isFinite(max) ? (Math.min(Math.abs(percentage), max) / max) * 100 : Math.min(Math.abs(percentage), 100)}%` }} className={"bg-negative"}>
                                &nbsp;
                            </div>
                        )}
                    </div>
                    <div className={styles["progress-bar"]}>
                        {percentage > 0 && (
                            <div style={{ width: `${isFinite(max) ? (Math.min(Math.abs(percentage), max) / max) * 100 : Math.min(Math.abs(percentage), 100)}%` }} className={"bg-positive"}>
                                &nbsp;
                            </div>
                        )}
                    </div>
                </div>
            </Tooltip>
        </>
    );
};

export default SizeBar;
