import { FC } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import { RecoilRoot } from "recoil";

export const withApp = (Component: FC<AppProps>) => (props: AppProps) =>
    (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no, viewport-fit=cover" />
            </Head>
            <RecoilRoot>
                <Component {...props} />
            </RecoilRoot>
        </>
    );
