import dynamic from "next/dynamic";
import { FC, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useDebounce } from "react-use";
import { Box, Button, Collapse, Divider, Tooltip, Typography, useMediaQuery } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { isEqual } from "lodash";

import { backgroundColorState, dividerState, themeState } from "@/lib/store";
import type { HeaderFilters } from "@/models/filters";

import DateFilter from "./DateFilter";
import InputFilter from "./InputFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import SelectFilter from "./SelectFilter";
import useDateFilter from "./hooks/useDateFilter";

import ClearButton from "../clear-button/ClearButton";
import ResetFiltersButton from "../reset-button/ResetFiltersButton";
import SaveFiltersButton from "./SaveFiltersButtons";

const RangeFilter = dynamic(() => import("@/components/data-grid/RangeFilter"), { ssr: false });

const compareValuesWithLocalParams = (localParams, values) => {
    // If the lengths are different, the arrays are different
    if (localParams?.customFilterModel?.items.length !== values.length) return true;

    // If both arrays are empty, there is no need to compare them
    if (localParams?.customFilterModel?.items.length === 0 && values.length === 0) return false;

    // If the arrays are the same length, we need to compare each value
    return localParams?.customFilterModel?.items.some((value) => {
        const localParam = values.find((item) => item.operatorValue === value.operatorValue && item.columnField === value.columnField);
        return !localParam || !isEqual(localParam.value, value.value);
    });
};

const HeaderFilters: FC<HeaderFilters> = ({
    chips,
    data,
    defaultValues,
    fetcher,
    filters,
    filtersCleared = false,
    filtersState,
    initialParam,
    isNewsSearch,
    reset,
    resetFilters,
    setChips,
    setCustomFiltersModel,
    setFiltersCleared,
    setFiltersState,
    setParams,
    setResetFilters,
    stockColumns,
    tableId,
    values,
}) => {
    const backgroundColor = useRecoilValue(backgroundColorState);
    const dividerColor = useRecoilValue(dividerState);
    const theme = useRecoilValue(themeState);
    const isMobile = useMediaQuery("(max-width: 600px)");
    const initialFilters = initialParam ? [...values] : [];
    const otherFilters = [];
    const inputFilters = [];
    filters.forEach((el) => (["text", "str", "multi_str"].includes(el.filterType) ? inputFilters : otherFilters).push(el));
    const [stringValues, setStringValues] = useState([]);
    const [dateValues, setDateValues] = useState([]);
    const [collapseIsOpen, setCollapseIsOpen] = useState([]);
    const [localParams, setLocalParams] = useState<any>({
        customFilterModel: { items: values },
    });
    const [filtersHaveChanged, setFiltersHaveChanged] = useState(compareValuesWithLocalParams(localParams, values));

    const firstLoad = useRef(true);
    const cleared = filtersCleared;
    const setCleared = setFiltersCleared;
    const settingDefValues = useRef(false);

    const [prevResetFilters, setPrevResetFilters] = useState(false);

    useEffect(() => {
        const areEqual = compareValuesWithLocalParams(localParams, values);
        setFiltersHaveChanged(areEqual);
    }, [values, localParams]);

    const handleApplyFilters = () => {
        if (filtersHaveChanged) setParams(localParams);
    };

    useEffect(() => {
        setCleared(!values.length);
    }, [values]);

    useEffect(() => {
        if (prevResetFilters === true && resetFilters === false) {
            settingDefValues.current = true;
            setLocalParams({ customFilterModel: { items: values } });
        }

        // Update the previous value
        setPrevResetFilters(resetFilters);
    }, [resetFilters, prevResetFilters]);

    // Moved from DataGrid and turned into a custom hook
    useDateFilter(filters, localParams, setLocalParams);

    useDebounce(
        () => {
            if (firstLoad.current) {
                firstLoad.current = false;
            } else if (settingDefValues.current) {
                settingDefValues.current = false;
            }
        },
        0,
        [localParams]
    );

    const paramsSetter = (props) => {
        setLocalParams(props);
        setFiltersState &&
            setFiltersState((prev) => {
                if (prev.currentState !== "normal") {
                    return { previousState: prev.currentState, currentState: "normal" };
                } else return prev;
            });
    };

    const getComponent = (header) => {
        switch (header.filterType) {
            case "range":
            case "log_range":
                const rangeValues = values?.filter((h) => header.field === h.columnField);
                return <RangeFilter header={header} cleared={cleared} setParams={paramsSetter} values={rangeValues} initialParams={initialFilters} />;
            case "date":
                return <DateFilter key={header.field} header={header} setParams={paramsSetter} values={dateValues} initialParams={initialFilters} />;
            case "select":
                return <SelectFilter header={header} setParams={paramsSetter} values={values} initialParams={initialFilters} />;
            case "multi_select":
                return <MultiSelectFilter header={header} setParams={paramsSetter} values={values} initialParams={initialFilters} />;
            default:
                return null;
        }
    };

    const infoIconElement = (
        <Tooltip
            arrow
            title={
                <>
                    <Typography> Adjust the range slider or type a number followed by:</Typography>
                    <Box component={"ul"} sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", "> li": { width: "45%" } }}>
                        <li>K for thousand</li>
                        <li>M for million</li>
                        <li>B for billion</li>
                        <li>T for trillion</li>
                    </Box>
                </>
            }
        >
            <InfoIcon fontSize={"small"} sx={{ marginLeft: 0.5, marginBottom: 0.25 }} />
        </Tooltip>
    );

    const getStructure = (header, index: number) => {
        const collapsableFilter = ["range", "log_range"].includes(header.filterType);
        const collapseIcon = collapsableFilter && (collapseIsOpen[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />);
        const infoIcon = collapsableFilter && header.filterType === "range" && collapseIsOpen[index] && infoIconElement;
        const divider = collapsableFilter ? collapseIsOpen[index] && <Divider flexItem sx={{ mb: 2.5, backgroundColor: dividerColor }} /> : <Divider flexItem sx={{ mb: 2.5, backgroundColor: dividerColor }} />;
        return (
            <>
                <Box sx={{ display: "flex", alignItems: "flex-end", cursor: collapsableFilter ? "pointer" : "initial", userSelect: "none" }} onClick={collapsableFilter ? () => handleCollapse(index) : null}>
                    <Typography variant="subtitle1" sx={{ fontSize: "14px", fontWeight: "700", mt: 1.5 }}>
                        Filter by {header.headerName}
                    </Typography>
                    {infoIcon}
                    {collapseIcon}
                </Box>
                {divider}
                <Box sx={{ mb: 1, width: "100%" }}>
                    {collapsableFilter ? (
                        <Collapse in={collapseIsOpen[index]} timeout={0} sx={{ width: "100%" }}>
                            <Box sx={{ px: 1 }}>{getComponent(header)}</Box>
                        </Collapse>
                    ) : (
                        <Box>{getComponent(header)}</Box>
                    )}
                </Box>
            </>
        );
    };

    // The values should be setted the same way the rest of the filters
    useEffect(() => {
        if (!cleared || cleared === undefined) {
            setStringValues(values?.filter((value) => value.operatorValue === "contains" || value.operatorValue === "equals") || []);
            setDateValues(values?.filter((value) => value.operatorValue === "after" || value.operatorValue === "before") || []);
        } else {
            setDateValues([]);
            setStringValues([]);
        }
    }, [values, data]);

    const handleCollapse = (index) =>
        setCollapseIsOpen((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    const shouldOpenByDefault = (header, index: number) => {
        const isFiltered = values?.some((h) => header.field === h.columnField);
        if (collapseIsOpen[index] !== undefined) return;
        if (header?.defaultMin || isFiltered) collapseIsOpen[index] = true;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minWidth: "200px",
                margin: "0 auto",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    maxHeight: isMobile ? "55vh" : "calc(100% - 50px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    width: "100%",
                }}
            >
                {inputFilters.length > 0 && <InputFilter fields={inputFilters} setParams={paramsSetter} values={stringValues} chips={chips} setChips={setChips} isNewsSearch={isNewsSearch} stockColumns={stockColumns} initialParams={initialFilters} />}
                {otherFilters.map((header, index) => {
                    shouldOpenByDefault(header, index);
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%",
                            }}
                            key={`${index} - ${header.headerName}`}
                        >
                            {getStructure(header, index)}
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: theme === "dark" ? backgroundColor : "white",
                    zIndex: 1,
                }}
            >
                <ResetFiltersButton
                    headers={filters}
                    setParams={setLocalParams}
                    apiParamsValues={values}
                    fetcher={fetcher}
                    defaultValues={defaultValues}
                    setResetFilters={setResetFilters}
                    data={data}
                    reset={reset}
                    DataGrid
                    setChips={setChips}
                    initialParam={initialParam}
                    cleared={cleared}
                    setFiltersState={setFiltersState}
                />
                <ClearButton
                    setParams={setLocalParams}
                    setCustomFiltersModel={setCustomFiltersModel}
                    apiParamsValues={values}
                    fetcher={fetcher}
                    setChips={setChips}
                    cleared={cleared}
                    setCleared={setCleared}
                    clear={reset}
                    filtersState={filtersState}
                    setFiltersState={setFiltersState}
                />
                <Box>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            "&:disabled": {
                                color: "grey",
                                border: "1px solid grey",
                            },
                        }}
                        onClick={handleApplyFilters}
                        disabled={!filtersHaveChanged}
                    >
                        Apply Filters
                    </Button>
                </Box>
            </Box>
            {tableId && <SaveFiltersButton tableId={tableId} values={values} resetOptions={{ setResetFilters, setChips, reset, DataGrid: true, setParams, fetcher, defaultValues }} />}
        </Box>
    );
};

export default HeaderFilters;
