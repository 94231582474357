import { FC, useContext, useEffect, useRef, useState } from "react";
import { classNames } from "@/lib/utils";
import { categoryTypes } from "@/lib/vars";
import { MacroEventWeekAhead } from "@/models/events";
import { getMacroEventsWeekAhead } from "@/controllers/events";
import MacroEventsCalendar from "@/components/macro-events-calendar/MacroEventsCalendar";
import styles from "./MacroEventsTable.module.scss";
import dayjs from "dayjs";
import Image from "next/image";
import { useRecoilValue } from "recoil";
import { backgroundColorState, darkerBackgroundColorState, dividerState, greyDarkerState, greyLighterState, lighterBackgroundColorState, textColorState, themeState } from "@/lib/store";
import Chip from "@mui/material/Chip";
import { ContentLayoutContext, ModuleContext } from "@/lib/Context";
import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Typography, useMediaQuery } from "@mui/material";

interface PropsTypes {
    countries?: string;
    oneColumn?: boolean;
    withCalendar?: boolean;
    onEventClick?: (event: MacroEventWeekAhead) => any | void;
    isCustomView?: boolean;
}

const tableColumns = {
    5: "five",
    4: "four",
    3: "three",
    2: "two",
    1: "one",
};

const MacroEventsTable: FC<PropsTypes> = ({ countries, oneColumn, onEventClick, withCalendar, isCustomView }) => {
    const { obfuscated, setObfuscated } = useContext(ModuleContext);
    const { setDisableFilters } = useContext(ContentLayoutContext);

    const theme = useRecoilValue(themeState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const greyLighter = useRecoilValue(greyLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const textColor = useRecoilValue(textColorState);
    const dividerColor = useRecoilValue(dividerState);

    const [eventsLoading, setEventsLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<MacroEventWeekAhead[][]>([]);
    const [eventsList, setEventsList] = useState<{ date: string; eventName: string; country: string; type: string }[]>([]);
    const index = useRef(20);
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        setEventsLoading(true);
        getMacroEventsWeekAhead(countries || undefined)
            .then((res) => {
                if (!oneColumn && !onEventClick) {
                    setEvents(res.data.slice(0, 2));
                } else setEvents(res.data);
                setObfuscated(res.obfuscated);
                setDisableFilters(res.obfuscated);
                // Execute this function from time to time to add non-existing event types
            })
            .finally(() => setEventsLoading(false));
    }, [countries]);

    const EventList = () => {
        return (
            <Box sx={{ height: "50%" }}>
                <Typography variant="h2" sx={{ marginLeft: 2, marginY: 1, width: "fit-content" }}>
                    {eventsList.length > 0 ? `${eventsList?.length} Events` : "No Events"}
                </Typography>
                <Box sx={{ height: "100%", width: "100%", overflow: "auto" }}>
                    {eventsList.length > 0 && (
                        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                            {eventsList.map((event, i) => {
                                return (
                                    <Card
                                        elevation={2}
                                        key={`${event.country}-${event.type}-${i}`}
                                        sx={{
                                            width: eventsList.length < 6 ? "100%" : isMobile ? "45%" : "30%",
                                            maxHeight: "100px",
                                            backgroundColor: lighterBackgroundColor,
                                            display: "flex",
                                            flexDirection: "column",
                                            marginY: 1,
                                            padding: 0.5,
                                        }}
                                    >
                                        <CardHeader sx={{ height: "30%", padding: 0.5, marginBottom: 0.5 }} title={<Typography variant="moduleContentTitle">{event.eventName}</Typography>} />
                                        <CardContent
                                            sx={{
                                                height: "70%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-around",
                                                padding: 0.5,
                                                "&:last-child": {
                                                    paddingBottom: 0,
                                                },
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ width: "fit-content" }}>
                                                <span style={{ fontWeight: 600 }}>Area: </span>
                                                {event.country}
                                            </Typography>
                                            <Typography variant="body2" sx={{ width: "fit-content" }}>
                                                <span style={{ fontWeight: 600 }}>Event: </span>
                                                {event.type}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <div className={classNames(styles[theme], styles.MacroEventsTable, styles[tableColumns[events.length > 5 ? 5 : events.length]], styles[withCalendar && "with-calendar"])} style={isCustomView && { overflow: "auto" }}>
            {eventsLoading ? (
                <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <div className={classNames(styles.table, styles[oneColumn && "one-column"], obfuscated && "obfuscated")}>
                        <div className={styles.left}>
                            {withCalendar && (
                                <Box sx={{ width: "100%", height: "45%" }}>
                                    <MacroEventsCalendar countries={countries || undefined} setEventsList={setEventsList} />
                                </Box>
                            )}
                            {EventList()}
                        </div>
                        {events.map((dayEvents, i) => {
                            const day = dayjs.utc(dayEvents[0].time).startOf("day");
                            const date = day.date();
                            return (
                                <div key={i} className={styles["events-column"]}>
                                    <Typography sx={{ backgroundColor: `${backgroundColor}` }}>{day.format("dddd - Do MMMM, YYYY")}</Typography>
                                    <Divider sx={{ backgroundColor: dividerColor }} />
                                    <div style={{ borderRightColor: theme === "dark" ? greyDarker : greyLighter }}>
                                        {dayEvents.map((event, j) => {
                                            const time = dayjs(event.time);
                                            const utcTime = dayjs.utc(event.time);
                                            const eventDate = time.date();
                                            return (
                                                <div key={event.title_short + i + j} onClick={() => onEventClick?.(event)} style={{ cursor: onEventClick ? "pointer" : "unset" }}>
                                                    <div className={styles.title}>
                                                        <Typography variant="moduleContentTitle">{event.title_short}</Typography>
                                                        <Chip
                                                            label={event.category}
                                                            title={event.category}
                                                            style={{ maxWidth: 130, color: textColor, border: `2px solid ${categoryTypes[event.category]?.[0]}`, backgroundColor: `${categoryTypes[event.category]?.[0]}11` }}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                    <Typography variant="body2">
                                                        {event.format_override ? (
                                                            "All day"
                                                        ) : (
                                                            <>
                                                                {time.format(`${date === eventDate ? "" : "DD/MM "}HH:mm z`)} <span>({utcTime.format("HH:mm [UTC]")})</span>
                                                            </>
                                                        )}
                                                    </Typography>
                                                    <div className={styles.description}>
                                                        {event.actual && <Typography>Actual: {event.actual}</Typography>}
                                                        {event.expectation && <Typography>Expectation: {event.expectation}</Typography>}
                                                        {event.surprise && <Typography>Surprise: {event.surprise}</Typography>}
                                                        {event.previous && <Typography>Previous: {event.previous}</Typography>}
                                                        <div>
                                                            <Typography style={{ marginRight: event.country_iso2 ? 5 : 0 }}>{event.sub_type}</Typography>
                                                            {event.country_iso2 && (
                                                                <Image unoptimized src={`${process.env.NEXT_PUBLIC_STATIC_URL}/static/images/flags/1x1/${event.country_iso2.toLowerCase()}.svg`} alt={event.sub_type} width={20} height={20} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        <style jsx>{`
                            .${styles.table} .${styles["events-column"]} > div > div:nth-child(even) {
                                background-color: ${darkerBackgroundColor};
                            }
                            @media (hover: hover) {
                                .${styles.table} .${styles["events-column"]} > div > div:hover {
                                    background-color: ${lighterBackgroundColor};
                                }
                            }
                        `}</style>
                    </div>
                </>
            )}
        </div>
    );
};

export default MacroEventsTable;
