import { FC, MouseEventHandler, useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Chip, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";

import { FindInstruments } from "@/models/find-instruments";
import { backgroundColorState, blackLighterState, greyDarkerState, greyLighterState, newbackgroundColorState, searchSettingsState, themeState, userState, whiteDarkerState } from "@/lib/store";
import { classNames } from "@/lib/utils";
import { Settings } from "@/models/auth";
import { updateUserSettings } from "@/controllers/auth";
import { usExchanges } from "@/lib/vars";

import styles from "./HeaderAutocomplete.module.scss";
import ImageNotSupported from "@mui/icons-material/ImageNotSupported";
import { getMissingLogo } from "@/controllers/stock";

import noBackgroundStocks from "@/models/noBackgroundStocks.json";

interface PropsTypes {
    activeIndex?: number;
    instruments: FindInstruments[];
    onClick?: (instrument: FindInstruments) => void;
    onMouseEnter?: MouseEventHandler<HTMLDivElement>;
    changeStockModule?: boolean;
    setNewStock?: (stock) => void;
}

let timeout: NodeJS.Timeout;

const HeaderAutocomplete: FC<PropsTypes> = ({ activeIndex, instruments, onClick, changeStockModule, setNewStock, onMouseEnter }) => {
    const theme = useRecoilValue(themeState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const blackLighter = useRecoilValue(blackLighterState);
    const whiteDarker = useRecoilValue(whiteDarkerState);
    const greyLighter = useRecoilValue(greyLighterState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const newbackgroundColor = useRecoilValue(newbackgroundColorState);
    const textColor = useRecoilValue(greyLighterState);
    const [user, setUser] = useRecoilState(userState);
    const searchSettings = useRecoilValue(searchSettingsState);
    const [activeItem, setActiveItem] = useState(undefined);

    const [instrumentsLocal, setInstrumentsLocal] = useState<FindInstruments[]>([]);
    const [imgError, setImgError] = useState<number[]>([]);

    const [blankImg, setBlankImg] = useState<boolean>(false);

    const isMobile = useMediaQuery("(max-width: 900px)");

    const searchLinks = [
        {
            text: "overview",
            link: "",
        },
        {
            text: "shorts",
            link: "short-interest",
        },
        {
            text: "analysts",
            link: "analysts",
        },
        {
            text: "options",
            link: "options",
        },
        {
            text: "signals",
            link: "trading-signals",
        },
        {
            text: "scores",
            link: "stock-scores",
        },
    ];

    const etfSearchLinks = [
        {
            text: "overview",
            link: "",
        },
        {
            text: "corp actions",
            link: "corporate-actions",
        },
        {
            text: "options",
            link: "options",
        },
        {
            text: "shorts",
            link: "short-interest",
        },
        {
            text: "signals",
            link: "trading-signals",
        },
    ];

    const nonUSExchange = (exchange: string) => !usExchanges.includes(exchange);

    const getStockLinks = (instrument: FindInstruments) => {
        const links = instrument.security_type === "ETF" ? etfSearchLinks : searchLinks;

        if (nonUSExchange(instrument.exchange)) {
            return links.filter((link) => link.text !== "options").map((link, index) => searchLinkItem(instrument, link.text, link.link, index));
        } else {
            return links.map((link, index) => searchLinkItem(instrument, link.text, link.link, index));
        }
    };

    const handleSearchClick = (instrument: FindInstruments): void => {
        const searchArray = [...searchSettings, instrument];
        const search_settings = searchArray.filter((value, index, self) => index === self.findIndex((item) => item.name === value.name));
        if (search_settings.length > 5) {
            search_settings.splice(0, 1);
        }
        const settings: Settings = { ...user.settings, search_settings };
        setUser({ ...user, settings });

        if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
            clearTimeout(timeout);
            timeout = setTimeout(() => updateUserSettings(settings), 1000);
        }
    };

    const searchLinkItem = (instrument: FindInstruments, text: string, link: string, index: number) => {
        return (
            <Link key={index} href={`/s/${instrument.exchange}/${instrument.ticker}/${link}`} passHref>
                <a style={{ color: textColor }} onClick={() => handleSearchClick(instrument)}>
                    {text.toUpperCase()}
                </a>
            </Link>
        );
    };

    useEffect(() => {
        setInstrumentsLocal(instruments);

        return () => setImgError((state) => []);
    }, [instruments]);

    const handleImgError = (security_id: number) => {
        setImgError((state) => [...state, security_id]);
        if (security_id) getMissingLogo(security_id);
    };

    return (
        <div className={styles.HeaderAutocomplete} style={{ display: instrumentsLocal.length > 0 ? "block" : "none", top: isMobile ? "calc(100px + env(safe-area-inset-top) + 26px)" : "85px" }} onMouseEnter={onMouseEnter}>
            <style jsx>{`
                .${styles.instrument}:hover {
                    background-color: ${theme === "dark" ? blackLighter : whiteDarker} !important;
                }

                .${styles.instrumentsContainer}::-webkit-scrollbar {
                    background-color: ${backgroundColor};
                }
            `}</style>
            <div className={styles.instrumentsContainer}>
                {instrumentsLocal.map((instrument, index) => (
                    <div
                        key={instrument.value}
                        className={classNames(styles.instrument, index === activeIndex && "active", activeItem && activeItem === instrument && styles.isOpen)}
                        style={{ borderBottom: `1px solid ${theme === "dark" ? "greyDarker" : whiteDarker}`, backgroundColor: theme === "dark" ? backgroundColor : newbackgroundColor }}
                        onClick={onClick ? () => onClick(instrument) : () => setActiveItem(instrument)}
                    >
                        <div className={styles.instrumentWrapper}>
                            {instrument.is_history ? <HistoryIcon htmlColor={greyLighter} fontSize="medium" sx={{ mr: isMobile ? 1 : 3 }} /> : <SearchIcon htmlColor={greyLighter} fontSize="medium" sx={{ mr: isMobile ? 1 : 3 }} />}
                            <div className={styles.logo}>
                                {!imgError.includes(instrument.security_id) ? (
                                    <div
                                        className={styles.logo}
                                        style={{
                                            width: "80px",
                                            backgroundColor: theme === "dark" && !blankImg && !noBackgroundStocks.includes(instrument.ticker) && !noBackgroundStocks.includes(instrument.name) ? "#c0c0c0" : "transparent",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Image
                                            unoptimized
                                            src={instrument.security_id ? `https://logo.ortex.com/${instrument.security_id}r.png` : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                                            alt={`${instrument.name} Logo`}
                                            layout="fill"
                                            objectFit="contain"
                                            priority
                                            onError={() => handleImgError(instrument.security_id)}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.logo}>
                                        <ImageNotSupported />
                                    </div>
                                )}
                            </div>
                            <div className={styles.instrumentContent}>
                                <div className={styles.instrumentInfo}>
                                    <Typography sx={{ fontWeight: 700 }} className={styles.ticker}>
                                        {instrument.exchange}:{instrument.ticker}
                                    </Typography>
                                    <Typography variant="body2" className={styles.name}>
                                        {instrument.name}
                                    </Typography>
                                    <div className={styles.type}>
                                        <Chip className={styles.securityType} label={instrument.security_type} color={instrument.security_type === "ETF" ? "secondary" : "info"} variant="outlined" size="small" />
                                    </div>
                                </div>
                                <div className={styles.instrumentLinks}>{getStockLinks(instrument)}</div>
                                <div className={styles.instrumentMobileLinks} style={{ backgroundColor }}>
                                    <div className={styles.instrumentMobileLinksWrapper}>{getStockLinks(instrument)}</div>
                                    <IconButton
                                        aria-label="close"
                                        style={{ color: textColor }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveItem(undefined);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        {!changeStockModule && !onClick && (
                            <Link href={`/s/${instrument.exchange || " ‎"}/${instrument.ticker}`}>
                                <a onClick={() => handleSearchClick(instrument)}></a>
                            </Link>
                        )}
                        {changeStockModule && (
                            <div className={styles.changeStockModule}>
                                <button
                                    className={styles.changeStockModuleButton}
                                    onClick={() => {
                                        setNewStock(instrument);
                                    }}
                                >
                                    Change
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HeaderAutocomplete;
