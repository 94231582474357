export const usExchanges = ["ARCA", "Nasdaq", "NYSE", "AMEX"];

export const plansFeatures = {
    "ORTEX Basics": [
        {
            text: "Event Data",
            show: true,
            space_after: false,
        },
        {
            text: "Analyst Data",
            show: true,
            space_after: false,
        },
        {
            text: "Holdings Data",
            show: true,
            space_after: false,
        },
        {
            text: "Dividend Analysis",
            show: true,
            space_after: false,
        },
        {
            text: "Short Interest Extremes",
            show: true,
            space_after: false,
        },
        {
            text: "IPOs and Deals",
            show: true,
            space_after: false,
        },
        {
            text: "Peer / Currency / Commodity",
            show: true,
            space_after: false,
        },
        {
            text: "Corporate Action Data",
            show: true,
            space_after: false,
        },
        {
            text: "Index and ETF Data and Signals",
            show: true,
            space_after: false,
        },
        {
            text: "Portfolio Integration",
            show: true,
            space_after: false,
        },
        {
            text: "Export Functionality",
            show: true,
            space_after: false,
        },
    ],
    "ORTEX Advanced": [
        {
            text: "Live Short Interest",
            show: true,
            space_after: false,
        },
        {
            text: "ORTEX Trading Signals",
            show: true,
            space_after: false,
        },
        {
            text: "Options Data and Analysis",
            show: true,
            space_after: false,
        },
        {
            text: "Stock Stamps",
            show: true,
            space_after: false,
        },
        {
            text: "Insider Data and Analysis",
            show: true,
            space_after: false,
        },
        {
            text: "Live Order Book",
            show: true,
            space_after: false,
        },
        {
            text: "ORTEX Stock Scores",
            show: true,
            space_after: false,
        },
    ],
};

export const fundamentalsDataPointsByKeys = {
    dps: "DPS",
    ebit: "EBIT",
    ebita: "EBITA",
    ebitda: "EBITDA",
    eps: "EPS",
    ev: "EV",
    evebitda: "EV/EBITDA",
    fcf: "FCF (Levered)",
    netdebt: "NET Debt",
    pbook: "P/BOOK",
    pe: "P/E",
    payout: "Payout Ratio",
    roa: "ROA",
    roe: "ROE",
    revenue: "Revenue",
};

export const fundamentalsDataPointsByValues = {
    DPS: "dps",
    EBIT: "ebit",
    EBITA: "ebita",
    EBITDA: "ebitda",
    EPS: "eps",
    EV: "ev",
    "EV/EBITDA": "evebitda",
    "FCF (Levered)": "fcf",
    "NET Debt": "netdebt",
    "P/BOOK": "pbook",
    "P/E": "pe",
    "Payout Ratio": "payout",
    ROA: "roa",
    ROE: "roe",
    Revenue: "revenue",
};

export const chartPatterns = [
    {
        width: 8,
        height: 8,
        path: "M-1,9 l2,2 M-1,5 l6,6 M-1,1 l10,10 M1,-1 l10,10 M5,-1 l6,6 M9,-1 l2,2",
    },
    {
        width: 9,
        height: 9,
        path: "M0,0 l10,0 M0,3 l10,0 M0,6 l10,0 M0,9 l10,0",
    },
    {
        width: 8,
        height: 8,
        path: "M-1,1 l2,-2 M-1,5 l6,-6 M-1,9 l10,-10 M1,11 l10,-10 M5,11 l6,-6 M9,11 l2,-2 M-1,9 l2,2 M-1,5 l6,6 M-1,1 l10,10 M1,-1 l10,10 M5,-1 l6,6 M9,-1 l2,2",
    },
    {
        width: 8,
        height: 8,
        path: "M-1,1 l2,-2 M-1,5 l6,-6 M-1,9 l10,-10 M1,11 l10,-10 M5,11 l6,-6 M9,11 l2,-2",
    },
    {
        width: 9,
        height: 9,
        path: "M0,0 l0,10 M3,0 l0,10 M6,0 l0,10 M9,0 l0,10",
    },
    {
        width: 30,
        height: 30,
        path: "M0,0 l30,0 M0,3 l30,0 M0,6 l30,0 M0,9 l30,0 M0,12 l30,0 M0,15 l30,0 M0,18 l30,0 M0,21 l30,0 M0,24 l30,0 M0,27 l30,0 M0,30 l30,0 M0,0 l0,30 M3,0 l0,30 M6,0 l0,30 M9,0 l0,30 M12,0 l0,30 M15,0 l0,30 M18,0 l0,30 M21,0 l0,30 M24,0 l0,30 M27,0 l0,30 M30,0 l0,30",
    },
];

export const colors = [
    ["#FFE119", "#000000"],
    ["#4363D8", "#E6E6E6"],
    ["#F58231", "#000000"],
    ["#DCBEFF", "#000000"],
    ["#800000", "#E6E6E6"],
    ["#000075", "#E6E6E6"],
    ["#A9A9A9", "#000000"],
    ["#469990", "#E6E6E6"],
];

export const eventFamilies = {
    "Results / Earnings": ["#FFE119", "#000000"],
    Other: ["#4363D8", "#E6E6E6"],
    CMD: ["#F58231", "#000000"],
    "Company meetings": ["#DCBEFF", "#000000"],
    "Roadshow / Investor day": ["#800000", "#E6E6E6"],
    "Tradeshow / Conference": ["#000075", "#E6E6E6"],
    "Conf call/Webcast": ["#A9A9A9", "#000000"],
    "Analyst briefing": ["#469990", "#E6E6E6"],
};

export const categoryTypes = {
    Holidays: ["#FFE119", "#000000"],
    "Building Permits": ["#4363D8", "#E6E6E6"],
    "Business Confidence": ["#F58231", "#000000"],
    "GDP Annual Growth Rate": ["#DCBEFF", "#000000"],
    "GDP Growth Rate": ["#800000", "#E6E6E6"],
    "Inflation Rate": ["#000075", "#E6E6E6"],
    "Manufacturing Production": ["#A9A9A9", "#000000"],
    "Industrial Production": ["#469990", "#E6E6E6"],
    "Balance of Trade": ["#FFE119", "#000000"],
    "Core Consumer Prices": ["#4363D8", "#E6E6E6"],
    "Core Inflation Rate": ["#F58231", "#000000"],
    "Producer Prices Change": ["#DCBEFF", "#000000"],
    "Inflation Rate Mom": ["#800000", "#E6E6E6"],
    "Producer Prices": ["#000075", "#E6E6E6"],
    "Harmonised Consumer Prices": ["#A9A9A9", "#000000"],
    "Labor Force Participation Rate": ["#469990", "#E6E6E6"],
    "Unemployment Rate": ["#FFE119", "#000000"],
    "Construction Output": ["#4363D8", "#E6E6E6"],
    "Household Spending": ["#F58231", "#000000"],
    "New Orders": ["#DCBEFF", "#000000"],
    "Industrial Production Mom": ["#800000", "#E6E6E6"],
    "Retail Sales YoY": ["#000075", "#E6E6E6"],
    "Retail Sales MoM": ["#A9A9A9", "#000000"],
    "52 Week Bill Yield": ["#469990", "#E6E6E6"],
    "6 Month Bill Yield": ["#FFE119", "#000000"],
    "Foreign Exchange Reserves": ["#4363D8", "#E6E6E6"],
    "Zew Economic Sentiment Index": ["#F58231", "#000000"],
    Calendar: ["#DCBEFF", "#000000"],
    "Government Bond 10Y": ["#800000", "#E6E6E6"],
    "Nfib Business Optimism Index": ["#000075", "#E6E6E6"],
    "Consumer Confidence": ["#A9A9A9", "#000000"],
    Productivity: ["#469990", "#E6E6E6"],
    "Labour Costs": ["#FFE119", "#000000"],
    "Redbook Index": ["#4363D8", "#E6E6E6"],
    "API Crude Oil Stock Change": ["#F58231", "#000000"],
    "Inflation Expectations": ["#DCBEFF", "#000000"],
    "30 Year Bond Yield": ["#800000", "#E6E6E6"],
    "Retail Sales Yoy": ["#000075", "#E6E6E6"],
    "New Home Sales": ["#A9A9A9", "#000000"],
    "Machine Tool Orders": ["#469990", "#E6E6E6"],
    "Total Vehicle Sales": ["#FFE119", "#000000"],
    "Loans to Private Sector": ["#4363D8", "#E6E6E6"],
    "Loan Growth": ["#F58231", "#000000"],
    "Money Supply M2": ["#DCBEFF", "#000000"],
    "Banks Balance Sheet": ["#800000", "#E6E6E6"],
    "Car Registrations": ["#000075", "#E6E6E6"],
    "Coincident Index": ["#A9A9A9", "#000000"],
    "Tourist Arrivals": ["#469990", "#E6E6E6"],
    "Tourism Revenues": ["#FFE119", "#000000"],
    "Mortgage Rate": ["#4363D8", "#E6E6E6"],
    "Mortgage Applications": ["#F58231", "#000000"],
    "Interest Rate": ["#DCBEFF", "#000000"],
    "Cushing Crude Oil Stocks": ["#800000", "#E6E6E6"],
    "Distillate Fuel Production": ["#000075", "#E6E6E6"],
    "Distillate Stocks": ["#A9A9A9", "#000000"],
    "Crude Oil Stocks Change": ["#469990", "#E6E6E6"],
    "Crude Oil Imports": ["#FFE119", "#000000"],
    "Gasoline Stocks Change": ["#4363D8", "#E6E6E6"],
    "Refinery Crude Runs": ["#F58231", "#000000"],
    "Gasoline Production": ["#DCBEFF", "#000000"],
    "Heating Oil Stocks": ["#800000", "#E6E6E6"],
    "Government Budget Value": ["#000075", "#E6E6E6"],
    "Food Inflation": ["#A9A9A9", "#000000"],
    "Current Account": ["#469990", "#E6E6E6"],
    "Goods Trade Balance": ["#FFE119", "#000000"],
    "Economic Activity Index": ["#4363D8", "#E6E6E6"],
    "Private Investment": ["#F58231", "#000000"],
    "Leading Economic Index": ["#DCBEFF", "#000000"],
    "Construction Orders": ["#800000", "#E6E6E6"],
    "7 Year Note Yield": ["#000075", "#E6E6E6"],
    "3 Year Note Yield": ["#A9A9A9", "#000000"],
    Imports: ["#469990", "#E6E6E6"],
    Exports: ["#FFE119", "#000000"],
    "Housing Index": ["#4363D8", "#E6E6E6"],
    "Core Producer Prices": ["#F58231", "#000000"],
    "Continuing Jobless Claims": ["#DCBEFF", "#000000"],
    "Jobless Claims 4-week Average": ["#800000", "#E6E6E6"],
    "Initial Jobless Claims": ["#000075", "#E6E6E6"],
    "Natural Gas Stocks Change": ["#A9A9A9", "#000000"],
    "4 Week Bill Yield": ["#469990", "#E6E6E6"],
    "Export Prices": ["#FFE119", "#000000"],
    "Import Prices": ["#4363D8", "#E6E6E6"],
    "Manufacturing PMI": ["#F58231", "#000000"],
    "Foreign Bond Investment": ["#DCBEFF", "#000000"],
    "Foreign Stock Investment": ["#800000", "#E6E6E6"],
    "Wholesale Prices": ["#000075", "#E6E6E6"],
    "Foreign Direct Investment": ["#A9A9A9", "#000000"],
    "Deposit Growth": ["#469990", "#E6E6E6"],
    "Crude Oil Rigs": ["#FFE119", "#000000"],
    "Fixed Asset Investment": ["#4363D8", "#E6E6E6"],
    "Capacity Utilization": ["#F58231", "#000000"],
    "GDP Growth Annualized": ["#DCBEFF", "#000000"],
    "Services PMI": ["#800000", "#E6E6E6"],
    "Ny Empire State Manufacturing Index": ["#000075", "#E6E6E6"],
    "Wholesale Sales": ["#A9A9A9", "#000000"],
    "Manufacturing Sales": ["#469990", "#E6E6E6"],
    "3 Month Bill Yield": ["#FFE119", "#000000"],
    "Capital Flows": ["#4363D8", "#E6E6E6"],
    "Net Long-term Tic Flows": ["#F58231", "#000000"],
    "Domestic Exports of Non Oil (nodx) (%yoy)": ["#DCBEFF", "#000000"],
    "Exports of Non-oil Domestic Exports Of": ["#800000", "#E6E6E6"],
    "Tertiary Industry Index": ["#000075", "#E6E6E6"],
    "Claimant Count Change": ["#A9A9A9", "#000000"],
    "Wage Growth": ["#469990", "#E6E6E6"],
    "Employment Change": ["#FFE119", "#000000"],
    "Business Inventories": ["#4363D8", "#E6E6E6"],
    "Personal Spending": ["#F58231", "#000000"],
    "Money Supply M1": ["#DCBEFF", "#000000"],
    "2 Year Note Yield": ["#800000", "#E6E6E6"],
    "Housing Starts": ["#000075", "#E6E6E6"],
    "Retail Sales Ex Autos": ["#A9A9A9", "#000000"],
    "Nahb Housing Market Index": ["#469990", "#E6E6E6"],
    "Global Dairy Trade Price Index": ["#FFE119", "#000000"],
    "Machinery Orders": ["#4363D8", "#E6E6E6"],
    "Retail Price Index": ["#F58231", "#000000"],
    "Money Supply M3": ["#DCBEFF", "#000000"],
    "Reuters Tankan Index": ["#800000", "#E6E6E6"],
    "Retail Sales Ex Fuel": ["#000075", "#E6E6E6"],
    "Retail Sales Mom": ["#A9A9A9", "#000000"],
    "Government Debt": ["#469990", "#E6E6E6"],
    "Government Revenues": ["#FFE119", "#000000"],
    "Composite PMI": ["#4363D8", "#E6E6E6"],
    "Gold Production": ["#F58231", "#000000"],
    "Mining Production": ["#DCBEFF", "#000000"],
    "Factory Orders": ["#800000", "#E6E6E6"],
    "Chicago Fed National Activity Index": ["#000075", "#E6E6E6"],
    "Existing Home Sales": ["#A9A9A9", "#000000"],
    "Unemployed Persons": ["#469990", "#E6E6E6"],
    "5 Year Note Yield": ["#FFE119", "#000000"],
    "Car Production": ["#4363D8", "#E6E6E6"],
    "Deposit Interest Rate": ["#F58231", "#000000"],
    "Richmond Fed Manufacturing Index": ["#DCBEFF", "#000000"],
    "CBI Distributive Trades": ["#800000", "#E6E6E6"],
    "Economic Optimism Index": ["#000075", "#E6E6E6"],
    "Durable Goods Orders": ["#A9A9A9", "#000000"],
    "Durable Goods Orders Ex Transportation": ["#469990", "#E6E6E6"],
    "Durable Goods Orders Ex Defense": ["#FFE119", "#000000"],
    "Non Farm Payrolls": ["#4363D8", "#E6E6E6"],
    "Corporate Profits": ["#F58231", "#000000"],
    "Business Climate Indicator": ["#DCBEFF", "#000000"],
    "Gdp Deflator": ["#800000", "#E6E6E6"],
    "Kansas Fed Manufacturing Index": ["#000075", "#E6E6E6"],
    "Tokyo Cpi": ["#A9A9A9", "#000000"],
    "Job Advertisements": ["#469990", "#E6E6E6"],
    "Construction Spending": ["#FFE119", "#000000"],
    "Cement Production": ["#4363D8", "#E6E6E6"],
    "Home Loans": ["#F58231", "#000000"],
    "Unemployment Change": ["#DCBEFF", "#000000"],
    "Lmi Logistics Managers Index Current": ["#800000", "#E6E6E6"],
    "Factory Orders Ex Transportation": ["#000075", "#E6E6E6"],
    "Construction PMI": ["#A9A9A9", "#000000"],
    "Services Sentiment": ["#469990", "#E6E6E6"],
    "Composite Pmi": ["#FFE119", "#000000"],
    "Adp Employment Change": ["#4363D8", "#E6E6E6"],
    "Ism New York Index": ["#F58231", "#000000"],
    "Non Manufacturing PMI": ["#DCBEFF", "#000000"],
    "Challenger Job Cuts": ["#800000", "#E6E6E6"],
    "Lending Rate": ["#000075", "#E6E6E6"],
    "Reverse Repo Rate": ["#A9A9A9", "#000000"],
    "Cash Reserve Ratio": ["#469990", "#E6E6E6"],
    "Nonfarm Payrolls Private": ["#FFE119", "#000000"],
    "Auto Exports": ["#4363D8", "#E6E6E6"],
    "Average Hourly Earnings": ["#F58231", "#000000"],
    "Manufacturing Payrolls": ["#DCBEFF", "#000000"],
    "Government Payrolls": ["#800000", "#E6E6E6"],
    "Average Weekly Hours": ["#000075", "#E6E6E6"],
    "Part Time Employment": ["#A9A9A9", "#000000"],
    "Full Time Employment": ["#469990", "#E6E6E6"],
    "Wholesale Inventories": ["#FFE119", "#000000"],
    "Treasury Cash Balance": ["#4363D8", "#E6E6E6"],
    "Consumer Credit": ["#F58231", "#000000"],
    "Job Offers": ["#DCBEFF", "#000000"],
    "Tax Revenue": ["#800000", "#E6E6E6"],
    "Credit Card Spending": ["#000075", "#E6E6E6"],
    "Philadelphia Fed Manufacturing Index": ["#A9A9A9", "#000000"],
    "CPI Core Core": ["#469990", "#E6E6E6"],
    "Personal Income": ["#FFE119", "#000000"],
    "Pce Price Index": ["#4363D8", "#E6E6E6"],
    "Core Pce Price Index": ["#F58231", "#000000"],
    "Industrial Sentiment": ["#DCBEFF", "#000000"],
    "Consumer Confidence Price Trends": ["#800000", "#E6E6E6"],
    "Pending Home Sales": ["#000075", "#E6E6E6"],
    "Dallas Fed Manufacturing Index": ["#A9A9A9", "#000000"],
    "Jobs To Applications Ratio": ["#469990", "#E6E6E6"],
    "Mortgage Approvals": ["#FFE119", "#000000"],
    "Terms of Trade": ["#4363D8", "#E6E6E6"],
    "GDP Deflator": ["#F58231", "#000000"],
    "Consumer Spending": ["#DCBEFF", "#000000"],
    "Gross Fixed Capital Formation": ["#800000", "#E6E6E6"],
    "Nationwide Housing Prices": ["#000075", "#E6E6E6"],
    "Gross National Product": ["#A9A9A9", "#000000"],
    "Gdp Growth Annualized": ["#469990", "#E6E6E6"],
    "External Debt": ["#FFE119", "#000000"],
    "Bank Lending Rate": ["#4363D8", "#E6E6E6"],
    "Case Shiller Home Price Index": ["#F58231", "#000000"],
    "Personal Savings": ["#DCBEFF", "#000000"],
    "Private Sector Credit": ["#800000", "#E6E6E6"],
    "Copper Production": ["#000075", "#E6E6E6"],
    "Chicago PMI": ["#A9A9A9", "#000000"],
    "Economy Watchers Survey": ["#469990", "#E6E6E6"],
    "Input Producer Prices": ["#FFE119", "#000000"],
    "20 Year Bond Yield": ["#4363D8", "#E6E6E6"],
    "Ifo Current Conditions": ["#F58231", "#000000"],
    "Ifo Expectations": ["#DCBEFF", "#000000"],
    "FHFA House Price Index YoY": ["#800000", "#E6E6E6"],
    "Case Shiller Home Price Index MoM": ["#000075", "#E6E6E6"],
    "Case Shiller Home Price Index YoY": ["#A9A9A9", "#000000"],
    "FHFA House Price Index MoM": ["#469990", "#E6E6E6"],
    "Richmond Fed Services Index": ["#FFE119", "#000000"],
    "Richmond Fed Manufacturing Shipments": ["#4363D8", "#E6E6E6"],
    "New Home Sales MoM": ["#F58231", "#000000"],
    "Dallas Fed Services Revenues Index": ["#DCBEFF", "#000000"],
    "Dallas Fed Services Index": ["#800000", "#E6E6E6"],
    "MBA Mortgage Market Index": ["#000075", "#E6E6E6"],
    "MBA Mortgage Refinance Index": ["#A9A9A9", "#000000"],
    "MBA Purchase Index": ["#469990", "#E6E6E6"],
    "Retail Inventories Ex Autos": ["#FFE119", "#000000"],
    "Non Defense Capital Goods Orders Ex Aircraft": ["#4363D8", "#E6E6E6"],
    "PCE Prices QoQ": ["#F58231", "#000000"],
    "Real Consumer Spending": ["#DCBEFF", "#000000"],
    "GDP Sales QoQ": ["#800000", "#E6E6E6"],
    "Core PCE Prices QoQ": ["#000075", "#E6E6E6"],
    "Pending Home Sales MoM": ["#A9A9A9", "#000000"],
    "Kansas Fed Composite Index": ["#469990", "#E6E6E6"],
    "Small Business Sentiment": ["#FFE119", "#000000"],
    "Employment Cost Index": ["#4363D8", "#E6E6E6"],
    "Employment Cost Index Wages": ["#F58231", "#000000"],
    "Employment Cost Index Benefits": ["#DCBEFF", "#000000"],
    "Michigan 5 Year Inflation Expectations": ["#800000", "#E6E6E6"],
    "Michigan Inflation Expectations": ["#000075", "#E6E6E6"],
    "Michigan Current Economic Conditions": ["#A9A9A9", "#000000"],
    "Michigan Consumer Expectations": ["#469990", "#E6E6E6"],
    "ISM Manufacturing Employment": ["#FFE119", "#000000"],
    "ISM Manufacturing Prices": ["#4363D8", "#E6E6E6"],
    "ISM Manufacturing New Orders": ["#F58231", "#000000"],
    "IPC-Fipe Inflation MoM": ["#DCBEFF", "#000000"],
    "ISM Non Manufacturing Business Activity": ["#800000", "#E6E6E6"],
    "ISM Non Manufacturing Employment": ["#000075", "#E6E6E6"],
    "ISM Non Manufacturing New Orders": ["#A9A9A9", "#000000"],
    "ISM Non Manufacturing Prices": ["#469990", "#E6E6E6"],
    "Producer Price Inflation MoM": ["#FFE119", "#000000"],
    "8 Week Bill Yield": ["#4363D8", "#E6E6E6"],
    "50 Year Bond Yield": ["#F58231", "#000000"],
    "Household Spending MoM": ["#DCBEFF", "#000000"],
    "Average Hourly Earnings YoY": ["#800000", "#E6E6E6"],
    "Manufacturing Production MoM": ["#000075", "#E6E6E6"],
    "BRC Retail Sales Monitor YoY": ["#A9A9A9", "#000000"],
    "Economy Watchers Survey Outlook": ["#469990", "#E6E6E6"],
    "Zew Current Conditions": ["#FFE119", "#000000"],
    "5 Year note Yield": ["#4363D8", "#E6E6E6"],
    "Monthly GDP YoY": ["#F58231", "#000000"],
    "Monthly GDP MoM": ["#DCBEFF", "#000000"],
    "Goods Trade Balance Non-EU": ["#800000", "#E6E6E6"],
    "15 Year Bond Yield": ["#000075", "#E6E6E6"],
    "Retail Sales Ex Gas and Autos MoM": ["#A9A9A9", "#000000"],
    "2 Year note Yield": ["#469990", "#E6E6E6"],
    "20 Year bond Yield": ["#FFE119", "#000000"],
    "Motorbike Sales": ["#4363D8", "#E6E6E6"],
    "Investment In Foreign Securities": ["#F58231", "#000000"],
    "10 Year TIPS Yield": ["#DCBEFF", "#000000"],
    "Loan Prime Rate 5Y": ["#800000", "#E6E6E6"],
    "3 Year note Yield": ["#000075", "#E6E6E6"],
    "2 Year FRN Yield": ["#A9A9A9", "#000000"],
    "Mid-month Core Inflation Rate MoM": ["#469990", "#E6E6E6"],
    "Mid-month Inflation Rate MoM": ["#FFE119", "#000000"],
    "Mid-month Inflation Rate YoY": ["#4363D8", "#E6E6E6"],
    "Mid-month Core Inflation Rate YoY": ["#F58231", "#000000"],
    "ANZ Roy Morgan Consumer Confidence Index": ["#DCBEFF", "#000000"],
    "Tokyo Core CPI": ["#800000", "#E6E6E6"],
    "Investment Lending For Homes": ["#000075", "#E6E6E6"],
    "MI Inflation Gauge MoM": ["#A9A9A9", "#000000"],
    "GDP External Demand Contribution": ["#469990", "#E6E6E6"],
    "Business Survey Index Large Manufacturing Firms": ["#FFE119", "#000000"],
    "Tankan Large Manufacturing Outlook": ["#4363D8", "#E6E6E6"],
    "Tankan Non-Manufacturing Outlook": ["#F58231", "#000000"],
    "Tankan Large All Industry Capex": ["#DCBEFF", "#000000"],
    "GDP Aggregate Demand QoQ": ["#800000", "#E6E6E6"],
    "GDP Aggregate Demand YoY": ["#000075", "#E6E6E6"],
    "5 Year TIPS Yield": ["#A9A9A9", "#000000"],
    "Grain Stocks Wheat": ["#469990", "#E6E6E6"],
    "Grain Stocks Soy": ["#FFE119", "#000000"],
    "Grain Stocks Corn": ["#4363D8", "#E6E6E6"],
    "Imports YoY": ["#F58231", "#000000"],
    "Exports YoY": ["#DCBEFF", "#000000"],
    "Job Quits": ["#800000", "#E6E6E6"],
    "CPI Median": ["#000075", "#E6E6E6"],
    "CPI Trimmed-Mean": ["#A9A9A9", "#000000"],
    "Net Long-term TIC Flows": ["#469990", "#E6E6E6"],
    "Philly Fed Employment": ["#FFE119", "#000000"],
    "Philly Fed CAPEX Index": ["#4363D8", "#E6E6E6"],
    "Philly Fed Business Conditions": ["#F58231", "#000000"],
    "Philly Fed Prices Paid": ["#DCBEFF", "#000000"],
    "Philly Fed New Orders": ["#800000", "#E6E6E6"],
    "Existing Home Sales MoM": ["#000075", "#E6E6E6"],
    "House Price Index YoY": ["#A9A9A9", "#000000"],
    "House Price Index MoM": ["#469990", "#E6E6E6"],
    "119-Day Bill Yield": ["#FFE119", "#000000"],
    "Plant Machinery Capital Expenditure": ["#4363D8", "#E6E6E6"],
    "Building Capital Expenditure": ["#F58231", "#000000"],
    "Net Lending To Individuals MoM": ["#DCBEFF", "#000000"],
    "ADP Employment Change": ["#800000", "#E6E6E6"],
    "Selling Price Expectations": ["#000075", "#E6E6E6"],
    "Trimmed Mean CPI QoQ": ["#A9A9A9", "#000000"],
    "Weighted Median CPI QoQ": ["#469990", "#E6E6E6"],
};

export const bidVolume = ["D173", "D174", "D175", "D176", "D177", "D178", "D222", "D223", "D224", "D225"];

export const bidOrders = ["D167", "D168", "D169", "D170", "D171", "D172", "D218", "D219", "D220", "D221"];

export const bidPrices = ["D179", "D180", "D181", "D182", "D183", "D184", "D226", "D227", "D228", "D229"];

export const askPrices = ["D197", "D198", "D199", "D200", "D201", "D202", "D238", "D239", "D240", "D241"];

export const askOrders = ["D185", "D186", "D187", "D188", "D189", "D190", "D230", "D231", "D232", "D233"];

export const askVolume = ["D191", "D192", "D193", "D194", "D195", "D196", "D234", "D235", "D236", "D237"];
