"use client";

import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Typography } from "@mui/material";
import Link from "next/link";
import { useErrorBoundary } from "react-error-boundary";
import Module from "../module/Module";
import styles from "./ModuleErrorFallback.module.scss";
import { useRecoilValue } from "recoil";
import { greyState } from "@/lib/store";

const ModuleErrorFallback = () => {
    const grey = useRecoilValue(greyState);
    const { resetBoundary } = useErrorBoundary();

    return (
        <Module noHeader>
            <div className={styles.fallback}>
                <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Box sx={{ height: "80%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: 50, marginLeft: "auto", marginRight: "auto" }} />
                        <Typography variant="h1">Something went wrong</Typography>
                        <Typography variant="body2">Please refresh the page or try again later </Typography>
                        <Box className={styles.actions}>
                            <Button variant="outlined" sx={{ mt: 2, "&:disabled": { color: grey, border: `1px solid ${grey}` } }} onClick={resetBoundary}>
                                Try again
                            </Button>
                        </Box>
                    </Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        If the problem persists, please contact us at&nbsp;
                        <Link href="mailto:sales@ortex.com" passHref>
                            <Typography variant="body1" component="span" sx={{ color: "primary.main", cursor: "pointer" }}>
                                support@ortex.com
                            </Typography>
                        </Link>
                    </Typography>
                </Box>
            </div>
        </Module>
    );
};

export default ModuleErrorFallback;
