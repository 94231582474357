import styles from "./SignUpForm.module.scss";
import { ChangeEvent, ChangeEventHandler, FormEventHandler, useContext, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { greyLighterState, textColorState, universesState, userState, themeState, darkerBackgroundColorState, ortexColorState, userDataState, codeAppliedState, subscriptionState, planState } from "@/lib/store";
import Link from "@mui/material/Link";
import Image from "next/image";
import { useRouter } from "next/router";
import { authenticate, register, sendUserDataToBackend, sendUserDataToBackendApple } from "@/controllers/auth";
import { getUserUniverses } from "@/controllers/universes";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";
import { Box, FormControlLabel, Grid, TextField, Button, InputAdornment, IconButton, Typography, Checkbox, useMediaQuery } from "@mui/material";
import { Apple, Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleIcon from "@mui/icons-material/Google";
import Divider from "@mui/material/Divider";
import NextLink from "next/link";
// import { verifyCaptcha } from "@/controllers/captcha";
import { toast } from "react-toastify";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import { classNames, generateNonce } from "@/lib/utils";
import { Context } from "@/lib/Context";
import { restartSubscription } from "@/controllers/subscription";

// declare global {
//     interface Window {
//         grecaptcha: any;
//     }
// }

const SignUpForm = () => {
    const theme = useRecoilValue(themeState);
    const greyLighter = useRecoilValue(greyLighterState);
    const textColor = useRecoilValue(textColorState);
    const ortexColor = useRecoilValue(ortexColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const setUniverses = useSetRecoilState(universesState);
    const setUser = useSetRecoilState(userState);

    const router = useRouter();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [googleLoading, setGoogleLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<boolean>(false);
    const [essentialEmails, setEssentialEmails] = useState<boolean>(false);
    const [marketingEmails, setMarketingEmails] = useState<boolean>(false);
    const [tosPolicy, setTosPolicy] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<input>("NONE");

    const isMobile = useMediaQuery("(max-width: 768px)");

    const { query } = useRouter();
    const userData = useRecoilValue(userDataState);
    const [promoCode, setPromoCode] = useState("");
    const setCodeApplied = useSetRecoilState(codeAppliedState);
    const setSubscription = useSetRecoilState(subscriptionState);
    const setPlan = useSetRecoilState(planState);

    // const siteKeyV3 = process.env.NEXT_PUBLIC_SITE_KEY_V3;
    const Inputs = ["FIRST_NAME", "LAST_NAME", "EMAIL", "PASSWORD", "NONE"] as const;
    type input = (typeof Inputs)[number];

    const checkField: ChangeEventHandler<HTMLInputElement> = (e) => {
        const { id, value } = e.target;
        switch (id) {
            case "firstName":
                setFirstName(value);
                if (!value) setFirstNameError("First name is required");
                else if (!/^[^!\u0022#¤%&$!¿¡?+*}{123456789/()={}@~#'\[\]`|]+$/.test(value)) setFirstNameError("No special characters accepted");
                else setFirstNameError("");
                break;
            case "lastName":
                setLastName(value);
                if (!value) setLastNameError("Last Name is required");
                else if (!/^[^!\u0022#¤%&$!¿¡?+*}{123456789/()={}@~#'\[\]`|]+$/.test(value)) setLastNameError("No special characters accepted");
                else setLastNameError("");
                break;
            case "email":
                setEmail(value);
                if (!value) setEmailError("Email is required");
                else if (!/^[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9\s!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?\.)+[a-zA-Z0-9\s](?:[a-zA-Z0-9\s-]*[a-zA-Z0-9\s])?$/.test(value)) setEmailError("Invalid email");
                else setEmailError("");
                break;
            case "password":
                setPassword(value);
                if (!value) setPasswordError("Password is required");
                else if (value.length < 8) setPasswordError("Password must be at least 8 characters");
                else setPasswordError("");
                break;
        }
    };

    const muiStyles = {
        autoCompleteStyle: {
            "& :-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 1000px ${darkerBackgroundColor} inset`,
                WebkitTextFillColor: textColor,
            },
            "& :-webkit-autofill:focus": {
                WebkitBoxShadow: `0 0 0 1000px ${darkerBackgroundColor} inset`,
                WebkitTextFillColor: textColor,
            },
            "& :-webkit-autofill:hover": {
                WebkitBoxShadow: `0 0 0 1000px ${darkerBackgroundColor} inset`,
                WebkitTextFillColor: textColor,
            },
        },
        textField: {
            "& p": {
                color: greyLighter,
            },
        },
        btnStyle2: {
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-start",
            alignItems: "center",
            minWidth: "360px",
            mx: "auto",
            mb: 2,
            color: textColor,
            textTransform: "initial",
        },
        buttonDisabled: {
            "&:disabled": {
                backgroundColor: "rgb(74, 196, 191, 0.4)",
                color: "#939598",
            },
        },
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (firstNameError || lastNameError || emailError || passwordError) {
            setErrors(true);
            setLoading(false);
            return;
        }

        // window.grecaptcha.ready(() => {
        //     window.grecaptcha.execute(siteKeyV3, { action: "homepage" })
        //         .then((token: string) => {
        //             return verifyCaptcha(token)
        //         })
        //         .then((res) => {
        //             if (res.success && (res.score ? res.score > 0.6 : true)) {
        register({
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            essential_emails: essentialEmails,
            marketing_emails: marketingEmails,
            tos_policy: tosPolicy,
        })
            .then((res) => {
                if (res["msg"]?.includes("successfully")) {
                    Promise.all([authenticate(), getUserUniverses()]).then(([user, universes]) => {
                        setUser(user);
                        setUniverses(universes);
                        window.Appcues?.identify(user.email, user);
                        if (promoCode?.toUpperCase() === "FEMALEINVEST") {
                            restartSubscription("FEMALEINVEST").then((res) => {
                                setLoading(true);
                                toast("Setting up your account...", { type: "success" });
                                setCodeApplied("FEMALEINVEST");
                                setSubscription(res);
                                setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === "annually"));

                                //using window.location to force a full reload.
                                setTimeout(() => {
                                    window.location.href = "/markets-overview?appcue=320b8564-b0a8-4871-b565-4235bd41685c";
                                }, 4500);
                                setTimeout(() => {
                                    toast("Welcome to ORTEX!", { type: "success" });
                                }, 1500);
                            });
                        } else {
                            router.replace("/markets-overview?message=welcome");
                        }
                    });

                    try {
                        (window as any).dataLayer.push({
                            event: "sign_up",
                            subscription_value: 5,
                            subscription_currency: "USD",
                        });
                        setLoading(false);
                    } catch (err) {
                        console.error(err);
                        setLoading(false);
                    }
                } else {
                    setErrors(true);
                    setLoading(false);
                    toast("There was a problem with your form submission. Please try again", { type: "error" });
                }
            })
            .catch(() => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
        //             } else {
        //                 toast("There was a problem with your form submission. Please try again", { type: "error" })
        //                 setLoading(false);
        //             }
        //         })
        // })
    };

    const onInvalid: FormEventHandler = (e) => {
        e.preventDefault();
        checkField(e as ChangeEvent<HTMLInputElement>);
        setErrors(true);
    };

    // useEffect(() => {
    //     document.querySelector("#captcha-script")?.remove();

    //     let elem: HTMLCollectionOf<Element> = document.getElementsByClassName("grecaptcha-badge");
    //     for (let j = 0; j < elem.length; j++) elem[j].remove();

    //     const scriptv3 = document.createElement("script");
    //     scriptv3.src = `https://www.google.com/recaptcha/api.js?render=${siteKeyV3}`;
    //     scriptv3.async = true;
    //     scriptv3.id = "captcha-script";
    //     document.body.appendChild(scriptv3);

    //     return () => elem[0].classList.add("invisible");
    // }, []);

    async function signUp() {
        await GoogleAuth.signOut();
        const user = await GoogleAuth.signIn();

        if (user) {
            sendUserDataToBackend(user).then((res) => {
                if (res.success) {
                    if (promoCode?.toUpperCase() === "FEMALEINVEST") {
                        Promise.all([authenticate(), getUserUniverses()]).then(([user, universes]) => {
                            setUser(user);
                            setUniverses(universes);
                            window.Appcues?.identify(user.email, user);

                            restartSubscription("FEMALEINVEST").then((res) => {
                                setLoading(true);
                                toast("Setting up your account...", { type: "success" });
                                setCodeApplied("FEMALEINVEST");
                                setSubscription(res);
                                setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === "annually"));

                                //using window.location to force a full reload.
                                setTimeout(() => {
                                    window.location.href = "/markets-overview?appcue=320b8564-b0a8-4871-b565-4235bd41685c";
                                }, 4500);
                                setTimeout(() => {
                                    toast("Welcome to ORTEX!", { type: "success" });
                                }, 1500);
                            });
                        });
                    } else {
                        router.replace("/markets-overview?message=welcome");
                    }
                } else {
                    toast("An error has occurred. Please try again later.", { type: "error" });
                }
            });
        }
    }

    let appleOptions: SignInWithAppleOptions = {
        clientId: "com.ortex.applications",
        redirectURI: "https://app.ortex.com",
        scopes: "email name",
        state: "User authentication request",
        nonce: generateNonce(),
    };

    async function appleSignUp() {
        await SignInWithApple.authorize(appleOptions)
            .then((result: SignInWithAppleResponse) => {
                console.log(result);
                if (result) {
                    sendUserDataToBackendApple(result).then((res) => {
                        if (res.success) {
                            if (promoCode?.toUpperCase() === "FEMALEINVEST") {
                                Promise.all([authenticate(), getUserUniverses()]).then(([user, universes]) => {
                                    setUser(user);
                                    setUniverses(universes);
                                    window.Appcues?.identify(user.email, user);

                                    restartSubscription("FEMALEINVEST").then((res) => {
                                        setLoading(true);
                                        toast("Setting up your account...", { type: "success" });
                                        setCodeApplied("FEMALEINVEST");
                                        setSubscription(res);
                                        setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === "annually"));

                                        //using window.location to force a full reload.
                                        setTimeout(() => {
                                            window.location.href = "/markets-overview?appcue=320b8564-b0a8-4871-b565-4235bd41685c";
                                        }, 4500);
                                        setTimeout(() => {
                                            toast("Welcome to ORTEX!", { type: "success" });
                                        }, 1500);
                                    });
                                });
                            } else {
                                router.replace("/markets-overview?message=welcome");
                            }
                        } else {
                            toast("An error has occurred. Please try again later.", { type: "error" });
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (query.hasOwnProperty("promo")) {
            query.promo ? sessionStorage.setItem("promoCode", query.promo as string) : sessionStorage.setItem("promoCode", "true");
        }
        setPromoCode(query.promo as string);
    }, [query.message, query.promo]);

    // useEffect(() => {
    //     setPromoCode(sessionStorage.getItem("promoCode"));
    // }, []);

    return (
        <Box component="div" className={styles.SignUpForm}>
            <Box
                className={styles["img-container"]}
                sx={{
                    paddingTop: 2,
                    marginLeft: 2,
                }}
            >
                <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo" width={150} height={30} priority layout="responsive" />
            </Box>
            <Box className={styles["main-container"]}>
                <Box>
                    {promoCode?.toUpperCase() === "FEMALEINVEST" ? (
                        <>
                            <h1 className={styles["title-font"]}>Welcome Female Investor!</h1>
                            <div style={{ textAlign: "center" }}>
                                <Typography variant="body1" style={{ marginTop: "20px", marginBottom: "10px" }}>
                                    Courtesy of
                                </Typography>
                                <div style={{ display: "inline-block", marginRight: "20px" }}>
                                    {" "}
                                    {/* Wrap the first image and apply marginRight */}
                                    <Image unoptimized src="/assets/FemaleInvest.png" alt="Female Invest Logo" width={150} height={39} />
                                </div>
                                <span style={{ fontSize: "30px" }}>&</span>
                                {/*<Typography variant="body1" style={{ marginTop: '10px', marginBottom: '5px' }}>&</Typography>*/}
                                <div style={{ display: "inline-block", marginLeft: "20px", marginBottom: "4.5px" }}>
                                    {" "}
                                    {/* Wrap the second image, adjust as needed */}
                                    <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo" width={150} height={30} />
                                </div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Typography variant="body1" style={{ marginTop: "10px", marginBottom: "5px" }}>
                                    You have free access to ORTEX for one year.
                                </Typography>
                                <Typography variant="body1" style={{ marginTop: "10px", marginBottom: "5px" }}>
                                    Just set up an account below and get access to all the financial data ORTEX offers.
                                </Typography>
                                <Typography variant="body1" style={{ marginTop: "10px", marginBottom: "5px", fontSize: "10px" }}>
                                    The offer is only valid for Female Invest members, your email address will be verified with Female Invest.
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <h1 className={styles["title-font"]}>Create an account</h1>
                    )}

                    <Box component="form" onSubmit={handleSubmit} onInvalid={onInvalid} className={styles["sign-up-form"]}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }} className={styles["grid-item"]}>
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    variant="standard"
                                    autoComplete="name"
                                    required
                                    fullWidth
                                    className={styles["text-field"]}
                                    sx={{ ...muiStyles.autoCompleteStyle, ...muiStyles.textField }}
                                    InputLabelProps={{
                                        style: { color: textColor, backgroundColor: "transparent", zIndex: 1 },
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            borderBottom: theme === "dark" && (!errors || !firstNameError) && isFocused !== "FIRST_NAME" ? `0.25px solid ${textColor}` : "",
                                            borderTop: 0,
                                        },
                                    }}
                                    onFocus={() => {
                                        setIsFocused("FIRST_NAME");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("NONE");
                                    }}
                                    value={firstName}
                                    onChange={checkField}
                                    error={errors && firstNameError !== ""}
                                    helperText={firstNameError}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ marginTop: "10px" }} className={styles["grid-item"]}>
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    variant="standard"
                                    autoComplete="family-name"
                                    type="text"
                                    required
                                    fullWidth
                                    className={styles["text-field"]}
                                    sx={{ ...muiStyles.autoCompleteStyle, ...muiStyles.textField }}
                                    InputLabelProps={{
                                        style: { color: textColor, backgroundColor: "transparent", zIndex: 1 },
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            borderBottom: theme === "dark" && (!errors || !lastNameError) && isFocused !== "LAST_NAME" ? `0.25px solid ${textColor}` : "",
                                            borderTop: 0,
                                        },
                                    }}
                                    onFocus={() => {
                                        setIsFocused("LAST_NAME");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("NONE");
                                    }}
                                    value={lastName}
                                    onChange={checkField}
                                    error={errors && lastNameError !== ""}
                                    helperText={lastNameError}
                                />
                            </Grid>
                            <Grid item xs={12} className={styles["grid-item"]}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    variant="standard"
                                    autoComplete="off"
                                    type="email"
                                    required
                                    fullWidth
                                    className={styles["text-field"]}
                                    sx={{ ...muiStyles.autoCompleteStyle, ...muiStyles.textField }}
                                    InputLabelProps={{
                                        style: { color: textColor, backgroundColor: "transparent" },
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            borderBottom: theme === "dark" && (!errors || !emailError) && isFocused !== "EMAIL" ? `0.25px solid ${textColor}` : "",
                                            borderTop: 0,
                                        },
                                    }}
                                    onFocus={() => {
                                        setIsFocused("EMAIL");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("NONE");
                                    }}
                                    value={email}
                                    onChange={checkField}
                                    error={errors && emailError !== ""}
                                    helperText={emailError}
                                />
                            </Grid>
                            <Grid item xs={12} className={styles["grid-item"]}>
                                <TextField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    variant="standard"
                                    autoComplete="new-password"
                                    required
                                    fullWidth
                                    className={styles["text-field"]}
                                    sx={{ ...muiStyles.autoCompleteStyle, ...muiStyles.textField }}
                                    InputLabelProps={{
                                        style: { color: textColor, backgroundColor: "transparent" },
                                    }}
                                    value={password}
                                    onChange={checkField}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    sx={{
                                                        color: textColor,
                                                    }}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            backgroundColor: "transparent",
                                            borderBottom: theme === "dark" && (!errors || !passwordError) && isFocused !== "PASSWORD" ? `0.25px solid ${textColor}` : "",
                                            borderTop: 0,
                                        },
                                    }}
                                    onFocus={() => {
                                        setIsFocused("PASSWORD");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("NONE");
                                    }}
                                    error={errors && passwordError !== ""}
                                    helperText={passwordError}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                height={"min-content"}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "start",
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox value="essentialEmails" checked={essentialEmails} onChange={(e) => setEssentialEmails(e.target.checked)} className={styles["check-box"]} />}
                                    label={<Typography variant="body1">I want to be sent emails about my account and service updates.</Typography>}
                                />
                                <FormControlLabel
                                    control={<Checkbox value="marketingEmails" checked={marketingEmails} onChange={(e) => setMarketingEmails(e.target.checked)} className={styles["check-box"]} />}
                                    label={<Typography variant="body1">I want to be informed occasionally by email about tips and exclusive offers from ORTEX.</Typography>}
                                />
                                <FormControlLabel
                                    control={<Checkbox value="tosPolicy" checked={tosPolicy} onChange={(e) => setTosPolicy(e.target.checked)} className={styles["check-box"]} />}
                                    label={
                                        <Typography variant="body1">
                                            I have read and agree to the{" "}
                                            <Link href="https://public.ortex.com/terms-and-conditions" target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                                                Terms of Service
                                            </Link>{" "}
                                            as well as the{" "}
                                            <Link href="https://public.ortex.com/privacypolicy" target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                                                Privacy Policy
                                            </Link>
                                            .
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!tosPolicy || (errors && (!!emailError || !!passwordError || !!firstNameError || !!lastNameError)) || loading}
                            fullWidth
                            sx={{
                                ...muiStyles.buttonDisabled,
                                mt: 1,
                                mb: 1,
                                textTransform: "initial",
                                color: textColor,
                            }}
                            className={styles["btn"]}
                            // data-sitekey={siteKeyV3}
                            // data-callback="onSubmit"
                            // data-action="submit"
                        >
                            {loading ? <LoadingSpinner size={20} borderWidth={2} /> : "Create account"}
                        </Button>
                        <Divider
                            sx={{
                                width: "100%",
                                borderColor: greyLighter,
                                "&::before, &::after": {
                                    borderColor: greyLighter,
                                },
                                display: {
                                    sm: "flex",
                                    xs: "none",
                                },
                                alignItems: "center",
                                mb: 1,
                            }}
                            className={styles["responsive-font"]}
                        >
                            or
                        </Divider>
                        <Button className={classNames(styles.socialBtns, styles.googleBtn)} onClick={signUp} style={{ color: textColor }}>
                            <span></span>
                            <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                                Continue with Google
                            </Typography>
                        </Button>
                        <Button className={classNames(styles.socialBtns, styles.appleBtn, theme !== "light" && styles.appleDarkTheme)} onClick={appleSignUp} style={{ color: textColor }}>
                            <span></span>
                            <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                                Continue with Apple
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Typography
                    variant="body1"
                    sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        bottom: 1,
                        left: 1,
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    Already have an account?{" "}
                    <NextLink href="/login" passHref>
                        <span className={styles["sign-in"]}>Sign In</span>
                    </NextLink>
                </Typography>
                <Typography variant="minorSubtitle" sx={{ marginBottom: 2, paddingX: "5%" }}>
                    IMPORTANT: The projections or other information generated by ORTEX’s tools regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of
                    future results. The above calculations do not take into consideration all costs, such as commissions and margin interest which may impact the results shown. It is the user’s sole responsibility to select the criteria to enter in
                    the ORTEX’s tools, or to choose among the pre-defined screens, and to evaluate the merits and risks associated with the use of the tools before making any investment decisions. ORTEX is not responsible for any losses that occur
                    from such investment decisions. All information is provided on an "as is," "as available" basis without warranty of any kind, and neither ORTEX nor its Service and Information Providers make any representations as to the
                    suitability of the information for any purpose, nor to its accuracy, timeliness, completeness or usefulness. Use of the service is at the user’s own risk. ORTEX reserves the right, in its sole discretion and without any
                    obligation, to modify, improve, discontinue or correct any errors or omissions in any portion of the site at any times. Your continued use of this service indicates your acceptance of the terms of our disclosures{" "}
                    <NextLink href="https://public.ortex.com/terms-and-conditions" passHref>
                        <span style={{ color: ortexColor, textDecoration: "none", cursor: "pointer" }}>Terms and Conditions</span>
                    </NextLink>
                </Typography>
            </Box>
        </Box>
    );
};

export default SignUpForm;
