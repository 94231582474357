"use strict";
import Highcharts, { Chart, error, fireEvent } from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsStock from "highcharts/modules/stock";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartspatternFill from "highcharts/modules/pattern-fill";
import Color from "./Color";

export const addAccessibility = (Highcharts, sonification: boolean = true, patternFill: boolean = true) => {
    if (typeof Highcharts === "object") {
        HighchartsExporting(Highcharts);
        HighchartsMore(Highcharts);
        HighchartsAccessibility(Highcharts);
        HighchartsStock(Highcharts);
        HighchartsExportData(Highcharts);
        patternFill && HighchartspatternFill(Highcharts);
        sonification && require("highcharts/modules/sonification")(Highcharts);
    }
};

export const sonifyPoint = {
    events: {
        click: function () {
            // Sonify the point when clicked
            this?.sonify({
                instruments: [
                    {
                        instrument: "triangleMajor",
                        instrumentMapping: {
                            volume: function (point) {
                                return point.color === "red" ? 0.8 : 0.2;
                            },
                            duration: 200,
                            pan: "x",
                            frequency: "y",
                        },
                        // Start at C5 note, end at C6
                        instrumentOptions: {
                            minFrequency: 520,
                            maxFrequency: 1050,
                        },
                    },
                ],
            });
        },
    },
};

export const nullEvent = {
    events: {
        click: () => { },
    },
};

export const chartHighContrastLightTheme = {
    colors: ["#434348", "#f45b5b", "#49a65e", "#5f98cf", "#708090", "#b68c51", "#397550", "#c0493d", "#4f4a7a", "#b381b3"],
    alternativeColors: ["#2b908f", "#90ee7e", "#f45b5b", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
    title: {
        style: {
            color: "#000",
        },
    },
    subtitle: {
        style: {
            color: "#5e5e5e",
        },
    },
};

export const chartHighContrastDarkTheme = {
    colors: ["#a6f0ff", "#e7934c", "#70d49e", "#e898a5", "#007faa", "#f9db72", "#f45b5b", "#1e824c", "#dadfe1", "#a0618b"],
    alternativeColors: ["#f45b5b", "#8085e9", "#8d4654", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee", "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
    title: {
        style: {
            color: "#ffffff",
        },
    },
    subtitle: {
        style: {
            color: "#d5d5d5",
        },
    },
};

export const internationalize = (internationalization: string) => {
    if (internationalization === "english" || internationalization === undefined) {
        Highcharts.setOptions({
            lang: {
                shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                shortWeekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
                noData: "No data available",
                loading: "Loading...",
                viewFullscreen: "View in full screen",
                exitFullscreen: "Exit from full screen",
                resetZoom: "Reset zoom",
                downloadCSV: "Download CSV",
                downloadJPEG: "Download JPEG image",
                downloadPDF: "Download PDF document",
                downloadPNG: "Download PNG image",
                downloadSVG: "Download SVG vector image",
                downloadXLS: "Download XLS",
                hideData: "Hide data table",
                mainBreadcrumb: "Main",
                printChart: "Print chart",
                resetZoomTitle: "Reset zoom level 1:1",
                viewData: "View data table",
            },
        });
    } else if (internationalization === "spanish") {
        Highcharts.setOptions({
            lang: {
                shortMonths: ["Ene", "Feb", "Mar", "Abr", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                shortWeekdays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                weekdays: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
                noData: "No hay informacion disponible",
                loading: "Cargando...",
                viewFullscreen: "Ver en pantalla completa",
                exitFullscreen: "Salir de pantalla completa",
                resetZoom: "Resetear zoom",
                downloadCSV: "Descargar CSV",
                downloadJPEG: "Descargar imagen JPEG",
                downloadPDF: "Descargar documento PDF",
                downloadPNG: "Descargar imagen PNG",
                downloadSVG: "Descargar imagen vector SVG ",
                downloadXLS: "Descargar XLS",
                hideData: "Esconder tabla de datos",
                mainBreadcrumb: "Principal",
                printChart: "Imprimir grafico",
                resetZoomTitle: "Resetear nivel de zoom 1:1",
                viewData: "Ver tabla de datos",
            },
        });
    }
};

export const checkTheme = (
    highContrast: boolean,
    theme: "light" | "dark",
    setOptions: any,
    options: any,
    cognitiveAccess: boolean | undefined = undefined,
    textColor?: string,
    backgroundColor?: string,
    rangeSelector: boolean = false,
    sonification: boolean = false
) => {
    let exporting;
    if (cognitiveAccess !== undefined) {
        exporting = {
            enabled: !!cognitiveAccess,
            menuItemDefinitions: {
                downloadCSV: {
                    text: "Download CSV",
                    onclick: function () {
                        const csvData = this.getCSV();
                        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                        const url = URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'chart-data.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    },
                },
            },
            scale: 1,
            sourceWidth: 1600,
            sourceHeight: 836,
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "downloadCSV", "downloadXLS", !!sonification ? "downloadMIDI" : "", !!sonification ? "playAsSound" : ""],
                    symbolStrokeWidth: 2,
                    symbolStroke: textColor,
                    theme: {
                        fill: backgroundColor,
                        color: textColor,
                        states: {
                            hover: { fill: backgroundColor },
                            select: { fill: backgroundColor },
                        },
                    },
                },
            },
        };
    }
    let rangeSelectorButtonTheme = {
        r: 3,
        fill: backgroundColor,
        style: {
            color: textColor,
            fontSize: "10px",
            textAlign: "center",
        },
        states: {
            hover: {
                fill: backgroundColor,
                style: { color: textColor },
            },
            select: {
                fill: backgroundColor,
                style: { color: textColor },
            },
        },
    };

    const updateYAxis = (yAxis: any) => {
        if (Array.isArray(yAxis)) {
            return [
                {
                    ...yAxis[0],
                    gridLineWidth: 0.5,
                    gridLineColor: theme === "light" ? "#e6e6e6" : "#1e2f3c",
                },
                ...yAxis.slice(1),
            ];
        } else {
            return {
                ...yAxis,
                gridLineWidth: 0.5,
                gridLineColor: theme === "light" ? "#e6e6e6" : "#1e2f3c",
            };
        }
    };

    if (highContrast) {
        if (theme === "light") {
            setOptions((prevState) => ({
                ...prevState,
                // yAxis: {
                //     ...options.yAxis,
                //     gridLineWidth: 0.5,
                //     gridLineColor: "#e6e6e6",
                // },
                yAxis: updateYAxis(options.yAxis),
                colors: chartHighContrastLightTheme.colors,
                title: {
                    ...prevState.title,
                    style: chartHighContrastLightTheme.title.style,
                },
                subtitle: chartHighContrastLightTheme.subtitle,
                exporting: cognitiveAccess !== undefined ? exporting : prevState.exporting,
                rangeSelector: {
                    ...prevState.rangeSelector,
                    buttonTheme: rangeSelector ? rangeSelectorButtonTheme : {},
                },
            }));
        } else if (theme === "dark") {
            setOptions((prevState) => ({
                ...prevState,
                // yAxis: {
                //     ...options.yAxis,
                //     gridLineWidth: 0.5,
                //     gridLineColor: "#1e2f3c",
                // },
                yAxis: updateYAxis(options.yAxis),
                colors: chartHighContrastDarkTheme.colors,
                title: {
                    ...prevState.title,
                    style: chartHighContrastDarkTheme.title.style,
                },
                subtitle: chartHighContrastDarkTheme.subtitle,
                exporting: cognitiveAccess !== undefined ? exporting : prevState.exporting,
                rangeSelector: {
                    ...prevState.rangeSelector,
                    buttonTheme: rangeSelector ? rangeSelectorButtonTheme : {},
                },
            }));
        }
    } else {
        if (theme === "light") {
            setOptions((prevState) => ({
                ...prevState,
                // yAxis: {
                //     ...options.yAxis,
                //     gridLineWidth: 0.5,
                //     gridLineColor: "#e6e6e6",
                // },
                yAxis: updateYAxis(options.yAxis),
                colors: [],
                title: {
                    ...prevState.title,
                    style: {},
                },
                subtitle: {},
                exporting: cognitiveAccess !== undefined ? exporting : prevState.exporting,
                rangeSelector: {
                    ...prevState.rangeSelector,
                    buttonTheme: rangeSelector ? rangeSelectorButtonTheme : {},
                },
            }));
        } else if (theme === "dark") {
            setOptions((prevState) => ({
                ...prevState,
                // yAxis: {
                //     ...options.yAxis,
                //     gridLineWidth: 0.5,
                //     gridLineColor: "#1e2f3c",
                // },
                yAxis: updateYAxis(options.yAxis),
                colors: [],
                title: {
                    ...prevState.title,
                    style: {},
                },
                subtitle: {},
                exporting: cognitiveAccess !== undefined ? exporting : prevState.exporting,
                rangeSelector: {
                    ...prevState.rangeSelector,
                    buttonTheme: rangeSelector ? rangeSelectorButtonTheme : {},
                },
            }));
        }
    }
};

export const sonifyChart = (chart: Chart, duration: number = 5000) => {
    var sdInstruments = [
        {
            instrument: "sineMajor",
            instrumentMapping: {
                duration: 200,
                frequency: "y",
                volume: 0.7,
                pan: -1,
            },
            instrumentOptions: {
                minFrequency: 220,
                maxFrequency: 1900,
            },
        },
    ];
    var nyInstruments = [
        {
            instrument: "triangleMajor",
            instrumentMapping: {
                duration: 200,
                frequency: "y",
                volume: 0.6,
                pan: 1,
            },
            instrumentOptions: {
                minFrequency: 220,
                maxFrequency: 1900,
            },
        },
    ];
    // @ts-ignore
    chart.sonify({
        duration: duration,
        order: "simultaneous",
        pointPlayTime: "x",
        seriesOptions: [
            {
                id: "sd",
                instruments: sdInstruments,
            },
            {
                id: "ny",
                instruments: nyInstruments,
            },
        ],
    });
};

function insertItem(item, collection) {
    const indexOption = item.options.index,
        length = collection.length;
    let i;
    for (
        // Internal item (navigator) should always be pushed to the end
        i = item.options.isInternal ? length : 0;
        i < length + 1;
        i++
    ) {
        if (
            // No index option, reached the end of the collection,
            // equivalent to pushing
            !collection[i] ||
            // Handle index option, the element to insert has lower index
            (Highcharts.isNumber(indexOption) && indexOption < Highcharts.pick(collection[i].options.index, collection[i]._i)) ||
            // Insert the new item before other internal items
            // (navigator)
            collection[i].options.isInternal
        ) {
            collection.splice(i, 0, item);
            break;
        }
    }
    return i;
}

export const bindAxes = function () {
    const series = this,
        seriesOptions = series.options,
        chart = series.chart;
    let axisOptions;
    fireEvent(this, "bindAxes", null, function () {
        // repeat for xAxis and yAxis
        (series.axisTypes || []).forEach(function (coll) {
            // loop through the chart's axis objects
            chart[coll]?.forEach(function (axis) {
                axisOptions = axis.options;
                // apply if the series xAxis or yAxis option mathches
                // the number of the axis, or if undefined, use the
                // first axis
                if (Highcharts.pick(seriesOptions[coll], 0) === axis.index || (typeof seriesOptions[coll] !== "undefined" && seriesOptions[coll] === axisOptions.id)) {
                    // register this series in the axis.series lookup

                    insertItem(series, axis.series);
                    // set this series.xAxis or series.yAxis reference
                    /**
                     * Read only. The unique xAxis object associated
                     * with the series.
                     *
                     * @name Highcharts.Series#xAxis
                     * @type {Highcharts.Axis}
                     */
                    /**
                     * Read only. The unique yAxis object associated
                     * with the series.
                     *
                     * @name Highcharts.Series#yAxis
                     * @type {Highcharts.Axis}
                     */
                    series[coll] = axis;
                    // mark dirty for redraw
                    axis.isDirty = true;
                }
            });
            // The series needs an X and an Y axis
            if (!series[coll] && series.optionalAxis !== coll) {
                error(18, true, chart);
            }
        });
    });
    fireEvent(this, "afterBindAxes");
};


export const getCSVData = (chart) => {
    const csvData = chart.getCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'chart-data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const defaultHCTheme = {
    xAxis: {
        labels: {
            style: {
                color: "#666666",
                fontSize: "11px",
            },
        },
        lineWidth: 0.5,
        lineColor: "rgb(204, 214, 235)",
        tickColor: "rgb(204, 214, 235)",
    },
    yAxis: {
        labels: {
            style: {
                color: "#666666",
                fontSize: "11px",
            },
        },
    },
    plotOptions: {
        line: {
            lineWidth: 2,
        },
    },
    navigator: {
        xAxis: {
            gridLineWidth: 0,
            labels: {
                style: {
                    color: "grey",
                    textOutline: "none",
                },
            },
        },
    },
};
if (typeof Highcharts === "object") {
    Highcharts.setOptions(defaultHCTheme);
}

export default defaultHCTheme;
