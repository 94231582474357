import axios from "axios";
import { Stock } from "@/models/stock";

export const getStockInfoOnClient = async (stockIdOrExchangeId: string, ticker?: string) => {
    try {
        const res = await axios.get<Stock>(`/API/v2/${ticker ? "s" : "id"}/${stockIdOrExchangeId}/${ticker ? `${ticker}/` : ""}`);

        if (res.data.currency === "GBP") {
            res.data.high_52_week = res.data.high_52_week * 100;
            res.data.low_52_week = res.data.low_52_week * 100;
            res.data.last_close = res.data.last_close * 100;
            res.data.previous_close = res.data.previous_close * 100;
        }
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockInfoOnServer = async (stockIdOrExchangeId: string, ticker?: string, trend?: boolean): Promise<Stock> => {
    try {
        const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/API/v2/${ticker ? "s" : "id"}/${stockIdOrExchangeId}/${ticker ? `${ticker}/` : ""}${trend ? "?trend" : ""}`);
        const stock: Stock = await res.json();
        if (stock.currency === "GBP") {
            stock.high_52_week = stock.high_52_week * 100;
            stock.low_52_week = stock.low_52_week * 100;
            stock.last_close = stock.last_close * 100;
            stock.previous_close = stock.previous_close * 100;
        }
        return stock;
    } catch (err) {
        throw err;
    }
};

export const getStockInfoByTicker = async (ticker: string): Promise<Stock> => {
    try {
        const res = await axios.get(`/API/v2/s/US/${ticker}/`);

        if (res.data.currency === "GBP") {
            res.data.high_52_week = res.data.high_52_week * 100;
            res.data.low_52_week = res.data.low_52_week * 100;
            res.data.last_close = res.data.last_close * 100;
            res.data.previous_close = res.data.previous_close * 100;
        }
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockLogo = async (stockId: number) => {
    try {
        const res = await axios.get<{ url: string; mimeType: string; }>(`/API/v2/id/${stockId}/logo`);

        return res.data.url;
    } catch (err) {
        throw err;
    }
};

export const getMissingLogo = async (security_id: number) => {
    try {
        if (!security_id) return;

        const res = await axios.get(`/interface/api/security/${security_id}/logo`);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockCommentary = async (exchange: string, stock: string) => {
    try {
        const res = await axios.get(`/API/v2/s/${exchange}/${stock}/stock_commentary`);

        return res.data.commentary;
    } catch (err) {
        throw err;
    }
};
