import { authenticate } from "@/controllers/auth";
import { restartSubscription } from "@/controllers/subscription";
import { getUserUniverses } from "@/controllers/universes";
import { codeAppliedState, customViewsState, planState, showAppSubscriberModalState, subscriptionOfferingsState, subscriptionState, universesState, userState } from "@/lib/store";
import { isOrtexApp } from "@/lib/utils";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import useOpenSubscriptionForm from "./useOpenSubscriptionForm";

const useGetUserData = (isAuthenticated = true) => {
    const { pathname, replace } = useRouter();
    const setUniverses = useSetRecoilState(universesState);
    const setUser = useSetRecoilState(userState);
    const [loading, setLoading] = useState(true);
    const setCodeApplied = useSetRecoilState(codeAppliedState);
    const setSubscription = useSetRecoilState(subscriptionState);
    const setPlan = useSetRecoilState(planState);
    const setCustomViews = useSetRecoilState(customViewsState);
    const setShowAppSubscriberModal = useSetRecoilState(showAppSubscriberModalState);
    const offerings = useRecoilValue(subscriptionOfferingsState);
    const { openSubscriptionForm } = useOpenSubscriptionForm();

    const fetchData = useMemo(() => {
        if (!isAuthenticated) return;

        return async () => {
            try {
                const [user, universes] = await Promise.all([authenticate(), getUserUniverses()]);
                if (user.app_subscriber_only) {
                    const restartSubs = await restartSubscription("APPDISC");

                    setCodeApplied("APPDISC");
                    setSubscription(restartSubs);
                    setPlan(restartSubs.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === "annually"));
                    setShowAppSubscriberModal(true);
                }
                if (user.overdue && (!isOrtexApp() || offerings)) {
                    openSubscriptionForm();
                    toast("Your last payment failed. Please update your card details to continue to use ORTEX. We will not charge you for previous overdue invoices.", { type: "error" });
                }

                window.Appcues?.identify(user.email, user);
                setUniverses(universes);
                setUser(user);

                if (user.settings.module_settings) {
                    if (user.settings.module_settings["custom_views->tabs"] && user.settings.module_settings["custom_views->tabs"].length > 0) {
                        setCustomViews(user.settings.module_settings["custom_views->tabs"]);
                    } else {
                        setCustomViews([{ id: uuidv4(), name: "Default Custom View", layout: [], mobileLayout: [], modules: [], lastUpdated: dayjs().format("DD MMM YY") }]);
                    }
                }

                const cookie = document.cookie.split("subscriber=")[1]?.split(";")[0];
                if (["/login", "/login-legacy", "/cognito-login"].includes(pathname) && user.email !== "visitor@ortex.com") replace("/");
                else if (user.email === "visitor@ortex.com" && (pathname === "/" || (cookie === "1" && !["/iframes/", "/register", "/cognito-login", "/auth-redirect"].includes(pathname)))) {
                    replace("/login");
                } else setLoading(false);
            } catch (error) {
                setUser(null);
                if (pathname.includes("/login")) setLoading(false);
                else replace("/login");
            }
        };
    }, [isAuthenticated, isOrtexApp(), offerings]);

    useEffect(() => {
        if (fetchData) {
            fetchData();
        }
    }, [fetchData]);

    return { userLoading: loading };
};

export default useGetUserData;
