
const Meter = (props) => {
    let { title } = props;
    let { value = 0, minValue = 0, maxValue = 100, size = 170, strokeWidth = 10 } = props;

    let center = size / 2;
    let r = center - strokeWidth;
    let c = 2 * r * Math.PI;
    let a = c * (270 / 360);
    let percentage = (value - minValue) / (maxValue - minValue);
    let offset = c - percentage * a;

    const getColor = value => {
        if (value <= 20) return "#ef4438";
        else if (value <= 40) return "#F1592A";
        else if (value <= 60) return "#F4BC1A";
        else if (value <= 80) return "#4ac4bf";
        else return "#31aba6";
    };
    return (
        <div style={{ display: "flex", justifyContent: "center", }} >
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" strokeWidth={strokeWidth} aria-label="meter" >
                <circle role="presentation" cx={center} cy={center} r={r} stroke={"#31aba6"} strokeOpacity={0.2} strokeDasharray={`${a} ${c}`} strokeLinecap="round" transform={`rotate(135 ${center} ${center})`} aria-label="meter" />
                <circle role="presentation" cx={center} cy={center} r={r} stroke={getColor(value)} strokeDasharray={c} strokeDashoffset={offset} strokeLinecap="round" transform={`rotate(135 ${center} ${center})`} aria-label="meter" />
                <text role="presentation" x={center} y={center + 10} fontWeight="bold" fontFamily="ui-rounded, system-ui" fontSize={24} textAnchor="middle" fill={getColor(value)}>
                    {Math.round(value)}
                </text>
                <text role="presentation" x={center} y={center - 25} fontFamily="ui-rounded, system-ui" fontSize={20} textAnchor="middle" fill={getColor(value)} fillOpacity={0.85}>
                    {title}
                </text>
            </svg>
        </div>
    );
};

export default Meter;
