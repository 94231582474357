import { OPRAAgreement, Settings, User, Login } from "@/models/auth";
import { Universe } from "@/models/universe";
import axios from "axios";

export const register = async (data: { email: string; password: string; first_name: string; last_name: string; essential_emails: boolean; marketing_emails: boolean; tos_policy: boolean }) => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) return Promise.resolve({ msg: "User successfully created", error: "" });
        const res = await axios.post<{ msg?: string; error: string; error_msg?: string }>("API/v2/user/create/", data);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const login = async (username: string, password: string) => {
    try {
        const res = await axios.post<Login>("/account/external_auth", { username, password });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const authenticate = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve<User>({
                access_level: 99,
                user_id: 123456,
                email: "manuel.sabarros@ortex.com",
                first_name: "Front",
                last_name: "End",
                app_subscriber_only: false,
                settings: {
                    module_settings: {
                        // "Sonification->/settings": {
                        //     value: true
                        // },
                        // "HighContrast->/settings": {
                        //     value: true
                        // }
                        "CognitiveAccess->/settings": {
                            value: true,
                        },
                        // "datagrid->saved-filters": {
                        //     "stock-peers": {
                        //         customFilterModel: {
                        //             items: [{ columnField: "exchange", id: 1724064165171, operatorValue: "contains", value: ["AMEX", "NYSE", "Nasdaq"] }],
                        //         },
                        //     },
                        // },
                        /* "tickerTape->tickertape-settings": {
                        
                    }, */
                        //     "screener->screening": {
                        //         columns: [
                        //             "stock",
                        //             "ticker",
                        //             "id",
                        //             "isocode",
                        //             "general_mkt_cap_usd",
                        //             "general_price_close",
                        //             "general_volume_1d",
                        //             "industry",
                        //             "a_return_potential",
                        //             "price_change_3m",
                        //             "price_change_week",
                        //             "short_dtc_last",
                        //             "short_exchange_rep_date",
                        //             "short_exchange_set_date",
                        //             "short_exchange_si",
                        //             "short_onloan_percentile_1y",
                        //             "vr_ev/ebitda_value",
                        //             "vr_pe_value",
                        //         ],
                        //     },
                        // "custom_views->tabs": [
                        //     {
                        //         id: "b5e902b0-e865-4ef5-a706-2755fb1f388e",
                        //         name: "New Custom View",
                        //         layout: [
                        //             {
                        //                 w: 4,
                        //                 h: 1,
                        //                 x: 0,
                        //                 y: 0,
                        //                 i: "d262229c-5ced-4377-8df7-e844272fd35a",
                        //                 minW: 2,
                        //                 moved: false,
                        //                 static: false,
                        //                 isDraggable: true,
                        //             },
                        //         ],
                        //         modules: [
                        //             {
                        //                 i: "d262229c-5ced-4377-8df7-e844272fd35a",
                        //                 module: "stock-overview",
                        //                 props: {
                        //                     stock: {
                        //                         stock_id: 27270,
                        //                         ticker: "AMC",
                        //                         type: 1,
                        //                         name: "AMC Entertainment Holdings",
                        //                         isin: "US00165C1045",
                        //                         exchangesymbol: "NYSE",
                        //                         exchange_tz: "America/New_York",
                        //                         exchange_th: "0400-0930,0930-1600,1600-2000",
                        //                         currency: "USD",
                        //                         sector: ["Communication Services", "Media and Entertainment", "Entertainment", "Movies and Entertainment"],
                        //                         mkt_cap_local: 3023400480,
                        //                         mkt_cap_usd: 3023400480,
                        //                         mkt_cap_eut: 3111381433.968,
                        //                         volume_3m_this_exchange: 40374472,
                        //                         volume_3m: 40374472.369231,
                        //                         volume_3m_eur: 524525639.362482,
                        //                         volume_3m_usd: 509693627.858229,
                        //                         volume_3m_local: 509693627.858229,
                        //                         outstanding_shares: 516820595,
                        //                         free_floating_shares: 99.7139,
                        //                         other_listings: [
                        //                             {
                        //                                 id: 288631,
                        //                                 exchange: "BOVESPA",
                        //                                 ticker: "A2MC34",
                        //                             },
                        //                             {
                        //                                 id: 247417,
                        //                                 exchange: "DB",
                        //                                 ticker: "AH9",
                        //                             },
                        //                             {
                        //                                 id: 292561,
                        //                                 exchange: "NYSE",
                        //                                 ticker: "APE",
                        //                             },
                        //                             {
                        //                                 id: 255198,
                        //                                 exchange: "UNQ",
                        //                                 ticker: "AMC ENTERT H",
                        //                             },
                        //                         ],
                        //                         high_52_week: 45.95,
                        //                         low_52_week: 5.47,
                        //                         last_close: 6.04,
                        //                         previous_close: 5.85,
                        //                         initialStock: true,
                        //                     },
                        //                 },
                        //                 linkedModules: [],
                        //             },
                        //         ],
                        //         mobileLayout: [
                        //             {
                        //                 i: "d262229c-5ced-4377-8df7-e844272fd35a",
                        //                 x: 1,
                        //                 y: 0,
                        //                 w: 1,
                        //                 h: 1,
                        //                 minW: 1,
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         id: "a0adda12-60de-4574-b1f7-2f6610be30d7",
                        //         name: "New Custom View",
                        //         layout: [],
                        //         modules: [],
                        //     },
                        // ],
                    },
                    agreements: [{ code: "OPRA" }],
                },
                is_staff: true,
                is_superuser: false,
                //restriction_info: {
                //    error_message: "no subscription",
                //    error_code: 1,
                //   action: "modal",
                //   api_call: "/API/v2/user/new_subscription",
                //},
                subscription_plan: "ORTEX Advanced",
                // overdue: true,

                ortex_tandc: "agreed",
                ortex_essential_emails: "not_agreed",
                ortex_marketing_emails: "not_agreed",
            });
            return res;
        }
        const res = await axios.get<User>("/API/v2/user");

        if (!res.data.email) throw new Error("No user identified.");
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const sendUserDataToBackend = async (data: any) => {
    try {
        const res = await axios.post("/users/auth/google2", data);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const sendUserDataToBackendApple = async (data: any) => {
    try {
        const res = await axios.post("/users/auth/apple", data);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const saveSettings = async (moduleId: string, modulePage: string, data: any) => {
    const body = { module_settings: { [`${moduleId}->${modulePage}`]: data } };
    try {
        const res = await axios.post("/API/v2/user/", body);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const logout = async () => {
    try {
        const res = await axios.get("/logout");
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const resetPasword = async (email: string) => {
    try {
        const res = await axios.post("/password_reset", { email: email });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const updateUserSettings = async (settings: Settings) => {
    try {
        const res = await axios.post<Universe>("/API/v2/user", settings);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const setUserAgreements = async (body: OPRAAgreement) => {
    try {
        const res = await axios.post<string>("/API/v2/set_user_agreements", body);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const deleteAccount = async (code: string) => {
    try {
        const res = await axios.delete("/interface/api/user/gdpr/", {
            params: {
                code: code,
            },
        });
        return res;
    } catch (err) {
        throw err;
    }
};

export const setUserTermsAndConditions = async (preferences: { ortex_tandc: boolean; essential_emails: boolean; marketing_emails: boolean }) => {
    try {
        const res = await axios.post("/interface/api/user/agreements/", {
            preferences,
        });
        return res;
    } catch (err) {
        throw err;
    }
};
