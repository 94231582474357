import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, Switch, useMediaQuery } from "@mui/material";
import Link from "next/link";
import styles from "./UserMenu.module.scss";
import { forwardRef, MouseEvent, useContext, useEffect, useState } from "react";
import { logout } from "@/controllers/auth";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { textColorState, themeState, userDataState, userState, dividerState, paymentModalOpenState } from "@/lib/store";
import { Settings } from "@/models/auth";
import { updateUserSettings } from "@/controllers/auth";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import NotificationsIcon from "@mui/icons-material/Notifications";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { StatusBar, Style } from "@capacitor/status-bar";
import { isUndefined } from "lodash";
import LoginIcon from "@mui/icons-material/Login";
import { Context } from "@/lib/Context";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Capacitor } from "@capacitor/core";
import PaymentDrawer from "../payment-draweer/PaymentDrawer";
import { signOutFromBackend } from "../cognito-login/CognitoLogin";
import { signOut } from "aws-amplify/auth";
import { isOrtexApp } from "@/lib/utils";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";

type Anchor = "right";
let themeTimeout: NodeJS.Timeout;

interface PropsTypes {
    firstName: string;
    lastName: string;
    isVisitor: boolean;
}

interface ListItem {
    href: string;
    primary: string;
    itemButtonClick?: () => void;
    listItemIcon: JSX.Element;
    condition?: boolean;
    handleClose: boolean;
    desktopOnly?: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#337f79",
        },
        "&.Mui-checked": {
            color: "#fff",
        },
    },
}));

function rgbToHex(a) {
    a = a.replace(/[^\d,]/g, "").split(",");
    return ((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1);
}

const upgradeButtonText = {
    "ORTEX Free": "Upgrade",
    "ORTEX Basic": "Get Advanced",
    "ORTEX Visitor": "Create free account",
};

const UserMenu = forwardRef<HTMLDivElement, PropsTypes>(({ firstName, lastName, isVisitor }, ref) => {
    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);
    const userData = useRecoilValue(userDataState);
    const [user, setUser] = useRecoilState(userState);
    const dividerColor = useRecoilValue(dividerState);

    const setPaymentModalOpen = useSetRecoilState(paymentModalOpenState);

    const { openSubscriptionForm } = useOpenSubscriptionForm();

    const isMobile = useMediaQuery("(max-width: 768px)");

    const [state, setState] = useState({
        right: false,
    });
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(theme === "dark");
    }, [theme]);

    const doLogin = () => {
        window.location.href = `${window.location.origin}/login`;
        toggleDrawer(anchor, false);
    };

    /* const doLogout = () => {
        logout().finally(() => (window.location.href = `${window.location.origin}/login`));
        toggleDrawer(anchor, false);
    }; */

    const doLogout = async () => {
        try {
            const vertexUser = localStorage.getItem("vertexUser");
            const ortexUser = localStorage.getItem("ortexUser");

            console.log("signing out from backend test", vertexUser, ortexUser);

            if (vertexUser && ortexUser) {
                console.log("signing out from backend", user);
                await signOutFromBackend(vertexUser, ortexUser);
                await signOut();
            } else {
                logout().finally(() => (window.location.href = `${window.location.origin}/login`));
            }

            toggleDrawer(anchor, false);
        } catch (error) {
            console.error("Error signing out user", error);
        }
    };

    const setStatusBarStyle = async (theme: "light" | "dark") => {
        const choosenStyle = theme === "light" ? Style.Light : Style.Dark;
        await StatusBar.setStyle({ style: choosenStyle }).catch((err) => {
            return;
        });
    };

    useEffect(() => {
        setStatusBarStyle(theme);
    }, [theme]);

    useEffect(() => {
        const lightBackground = isUndefined(user.settings.whiteMedium) ? "f7f7f7" : rgbToHex(user.settings.whiteMedium);
    }, [user.settings]);

    const openHelp = () => {
        toggleDrawer(anchor, false);
        window.Appcues.show("bccb6a4f-3beb-4641-8258-44cc8c659f38");
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (event && event.type === "keydown" && ((event as KeyboardEvent).key === "Tab" || (event as KeyboardEvent).key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const onThemeChange = () => {
        const theme = checked ? "light" : "dark";
        const lightBackground = isUndefined(user.settings.whiteMedium) ? "f7f7f7" : rgbToHex(user.settings.whiteMedium);
        localStorage.setItem("theme", theme);
        setStatusBarStyle(theme);
        const settings: Settings = { ...user.settings, theme };
        setUser({ ...user, settings });
        if (process.env.NEXT_PUBLIC_VERCEL_ENV) {
            clearTimeout(themeTimeout);
            themeTimeout = setTimeout(() => updateUserSettings(settings), 1000);
        }
        setChecked(!checked);
    };

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const anchor = "right";

    const handleUpgrade = () => {
        if (isOrtexApp()) {
            setOpenDrawer(true);
        } else {
            setPaymentModalOpen(true);
            openSubscriptionForm();
        }
    };

    const listItems: ListItem[] = [
        {
            href: user?.subscription_plan === "ORTEX Visitor" ? "/register" : "",
            primary: upgradeButtonText[user.subscription_plan],
            listItemIcon: (
                <ListItemIcon>
                    <WorkspacePremiumIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            itemButtonClick:
                user?.subscription_plan === "ORTEX Visitor"
                    ? null
                    : () => {
                          handleClose();
                          setTimeout(handleUpgrade, 100);
                      },
            condition: ["ORTEX Free", "ORTEX Basic", "ORTEX Visitor"].includes(user?.subscription_plan) && isMobile,
            handleClose: true,
        },
        {
            href: "/portfolio-admin",
            primary: "Edit Porfolios",
            listItemIcon: (
                <ListItemIcon>
                    <BusinessCenterIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            handleClose: true,
            condition: user.subscription_plan === "ORTEX Visitor",
        },
        {
            href: "/video-tutorials",
            primary: "Video Tutorials",
            listItemIcon: (
                <ListItemIcon>
                    <VideoLibraryIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            handleClose: true,
        },
        {
            href: "",
            primary: "Help",
            itemButtonClick: () => openHelp(),
            listItemIcon: (
                <ListItemIcon>
                    <HelpCenterIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            handleClose: true,
        },
        {
            href: "/admin",
            primary: "Admin",
            listItemIcon: (
                <ListItemIcon>
                    <AdminPanelSettingsIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            condition: Boolean(userData && (userData?.is_staff || userData?.is_superuser)),
            handleClose: true,
        },
        {
            href: "/alerts",
            primary: "Alerts",
            listItemIcon: (
                <ListItemIcon>
                    <NotificationsIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            handleClose: true,
        },
        {
            href: "/settings",
            primary: "Settings",
            listItemIcon: (
                <ListItemIcon>
                    <SettingsIcon style={{ color: textColor }} />
                </ListItemIcon>
            ),
            handleClose: true,
        },

        {
            href: "",
            primary: "Theme switch",
            itemButtonClick: () => {
                onThemeChange();
            },
            listItemIcon: (
                <>
                    <ListItemIcon className={styles.switch}>
                        <AntSwitch checked={checked} />
                    </ListItemIcon>
                </>
            ),
            handleClose: false,
        },
        {
            href: "/",
            primary: isVisitor ? "Login" : "Logout",
            itemButtonClick: () => {
                isVisitor ? doLogin() : doLogout();
            },
            listItemIcon: <ListItemIcon>{isVisitor ? <LoginIcon style={{ color: textColor }} /> : <LogoutIcon style={{ color: textColor }} />}</ListItemIcon>,
            handleClose: true,
        },
    ];

    return (
        <div>
            <Box sx={{ alignSelf: "center" }}>
                {firstName && lastName ? (
                    <Button id="yes" aria-describedby={id} onClick={handleClick} sx={{ paddingY: 0, marginBottom: 0.4 }} className={styles["btn-user"]}>
                        <div style={{ color: textColor }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.5rem" }} className={styles.userName}>
                                {firstName[0] + lastName[0]}
                                <ExpandMoreIcon />
                            </Box>
                        </div>
                    </Button>
                ) : (
                    <Button id="no" aria-describedby={id} onClick={handleClick} sx={{ paddingY: 0.5, marginBottom: 0.4 }}>
                        <div style={{ color: textColor }} className={styles.userName}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <AccountBoxIcon fontSize="large" />
                                <ExpandMoreIcon />
                            </Box>
                        </div>
                    </Button>
                )}
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ p: 2, paddingBottom: 0 }}>{firstName + " " + lastName} </Typography>
                <Typography variant="caption" sx={{ padding: 2 }}>
                    {user.subscription_plan}
                </Typography>
                {listItems
                    .filter((item) => (isOrtexApp() ? !item.desktopOnly : true))
                    .map((listItem, index) => {
                        if ((["Settings", "Admin", "Edit Porfolios"].includes(listItem.primary) && user.subscription_plan === "ORTEX Visitor") || listItem.condition === false) {
                            return null;
                        } else {
                            return (
                                <div key={index}>
                                    <List>
                                        <ListItem
                                            disablePadding
                                            onClick={() => {
                                                listItem.handleClose && setTimeout(handleClose, 200);
                                            }}
                                        >
                                            {listItem.itemButtonClick ? (
                                                <ListItemButton onClick={listItem?.itemButtonClick}>
                                                    {listItem.listItemIcon}
                                                    <ListItemText primary={listItem.primary} />
                                                </ListItemButton>
                                            ) : (
                                                <Link href={listItem.href} passHref>
                                                    <ListItemButton onClick={listItem?.itemButtonClick}>
                                                        {listItem.listItemIcon}
                                                        <ListItemText primary={listItem.primary} />
                                                    </ListItemButton>
                                                </Link>
                                            )}
                                        </ListItem>
                                    </List>
                                    {index !== listItems.length - 1 && <Divider sx={{ backgroundColor: dividerColor }} />}
                                </div>
                            );
                        }
                    })}
            </Popover>
            <PaymentDrawer open={openDrawer} setOpen={setOpenDrawer} />
        </div>
    );
});

export default UserMenu;
