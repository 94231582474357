import { restartSubscription } from "@/controllers/subscription";
import { isOrtexApp } from "@/lib/utils";
import { useCallback } from "react";
import useHandleAppUpgrade from "./useHandleAppUpgrade";
import { useSetRecoilState } from "recoil";
import { paymentModalOpenState, planState, subscriptionState } from "@/lib/store";

const useOpenSubscriptionForm = () => {
    const { handleAppUpgrade } = useHandleAppUpgrade();
    const setSubscription = useSetRecoilState(subscriptionState);
    const setPaymentModalOpen = useSetRecoilState(paymentModalOpenState);
    const setPlan = useSetRecoilState(planState);

    const openSubscriptionForm = useCallback(
        (shouldOpen = true) => {
            if (!shouldOpen) return;
            if (isOrtexApp()) {
                handleAppUpgrade();
            } else {
                setSubscription(null);
                setPaymentModalOpen(true);
                restartSubscription().then((res) => {
                    setSubscription(res);
                    setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === "annually"));
                });
            }
        },
        [handleAppUpgrade]
    );
    return { openSubscriptionForm };
};

export default useOpenSubscriptionForm;
