import { Dialog, DialogContent, IconButton, useMediaQuery } from "@mui/material";
import { PaymentFlow } from "../payment/Payment";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { textColorState } from "@/lib/store";

const AppSubscriberModal = ({ open, setOpen }) => {
    const textColor = useRecoilValue(textColorState);
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery("(max-width:768px)");

    useEffect(() => {
        setTimeout(() => {
            setOpenModal(open);
        }, 1500);
    }, [open]);

    return (
        <Dialog
            maxWidth="xl"
            PaperProps={{
                style: {
                    width: !isMobile && "85vw",
                    overflowX: "hidden",
                },
            }}
            fullScreen={isMobile}
            open={openModal}
            aria-labelledby="responsive-dialog-title"
        >
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: textColor,
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <PaymentFlow setIsOpen={setOpen} isAppSubscriber />
            </DialogContent>
        </Dialog>
    );
};

export default AppSubscriberModal;
