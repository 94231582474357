import { FC, useContext } from "react";
import { ModuleContext } from "@/lib/Context";
import { Tooltip, LinearProgress } from "@mui/material";

interface PropsTypes {
    max?: number;
    percentage: number;
    tooltip?: string;
    className?: string;
    gradientColor?: boolean;
}

const ProgressBar: FC<PropsTypes> = ({ max = 100, percentage, tooltip, className, gradientColor }) => {
    const { obfuscated } = useContext(ModuleContext);
    const maxValue = 100; // Material UI LinearProgress Max Value
    return (
        <>
            <Tooltip title={obfuscated || className ? undefined : tooltip} arrow>
                <LinearProgress
                    variant="determinate"
                    value={className ? 0 : (Math.abs(percentage) * maxValue) / max}
                    color={gradientColor ? (percentage < 40 ? "error" : percentage > 40 && percentage < 60 ? "inherit" : "primary") : percentage > 0 ? "success" : "error"}
                    sx={{
                        width: "100%",
                        height: 8,
                        "& .MuiLinearProgress-bar": gradientColor && {
                            backgroundColor: percentage < 40 ? "error" : percentage > 40 && percentage < 60 ? "#f4bc1a" : "primary",
                        },
                    }}
                    className={className}
                />
            </Tooltip>
        </>
    );
};

export default ProgressBar;
