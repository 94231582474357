import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, Badge, Tooltip, Box, Alert, Button, AlertTitle, Grow } from "@mui/material";
import { BsBellFill } from "react-icons/bs";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { textColorState, notificationsListState, ortexColorState } from "@/lib/store";
import { notificationsViewed } from "@/controllers/notifications";
import styles from "./Notifications.module.scss";

let viewedNotifications: NodeJS.Timeout;
let readNotifications: NodeJS.Timeout;

const animationTime = 3500;
const animationCooldown = 15000;

export default function Notifications() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [notificationsList, setNotificationsList] = useRecoilState(notificationsListState);
    const [showReadNotifications, setShowReadNotifications] = useState(false);
    const [iconAnimation, setIconAnimation] = useState(false);
    const ortexColor = useRecoilValue(ortexColorState);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);

        const currentNotifications = notificationsList.filter((n) => !n.viewed);

        currentNotifications.map((n) => {
            notificationsViewed(n.notification_id)
                .then((res) => {
                    console.log(res);
                })
                .then(() => {
                    viewedNotifications = setTimeout(() => {
                        setNotificationsList((notificationsList) =>
                            notificationsList.map((n) => {
                                return { ...n, viewed: true };
                            })
                        );
                    }, 300);
                });
        });
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "notifications-popover" : undefined;

    useEffect(() => {
        if (!open) {
            readNotifications = setTimeout(() => {
                setShowReadNotifications(false);
            }, 300);
        }

        return () => {
            clearTimeout(readNotifications);
        };
    }, [open]);

    useEffect(() => {
        let timeoutId;

        const checkUnreadNotifications = () => {
            const unreadPriorityNotifications = notificationsList.filter((n) => !n.viewed && n.notification_priority === "1").length;
            if (unreadPriorityNotifications) {
                setIconAnimation(true);
                timeoutId = setTimeout(() => {
                    setIconAnimation(false);
                    timeoutId = setTimeout(() => {
                        checkUnreadNotifications();
                    }, animationCooldown);
                }, animationTime * 3);
            } else {
                setIconAnimation(false);
            }
        };

        checkUnreadNotifications();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [notificationsList]);

    const textColor = useRecoilValue(textColorState);

    const IconColor = (alertType: string) => {
        switch (alertType) {
            case "Info":
                return "info";
            case "Warning":
                return "warning";
            case "Alert":
                return "error";
            case "Payment":
                return "success";
        }
    };

    return (
        <div className={styles.notifications_wrapper}>
            <Tooltip
                arrow
                title="Notifications"
                PopperProps={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, +6],
                            },
                        },
                    ],
                }}
            >
                <IconButton aria-describedby={popoverId} onClick={handleClick} size="small">
                    <Badge
                        badgeContent={notificationsList.filter((n) => !n.viewed).length}
                        color={notificationsList.filter((n) => n?.notification_priority === "1").length ? "error" : "primary"}
                        className={iconAnimation ? styles.badgeAnimation : null}
                    >
                        <BsBellFill color="action" style={{ color: textColor }} className={iconAnimation ? styles.bellAnimation : null} />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box className={styles.popupContent} sx={{ backgroundColor: "transparent" }}>
                    {showReadNotifications
                        ? notificationsList
                              .filter((n) => n.viewed)
                              .sort((a, b) => (Number(a.notification_priority) > Number(b.notification_priority) ? 1 : -1))
                              .map((notification, i) => (
                                  <Grow in={open} key={notification.notification_id} style={{ transformOrigin: "0 0 0" }} {...(open ? { timeout: 500 * (i + 1) } : {})}>
                                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                                          <Alert variant="standard" severity={IconColor(notification.notification_type)}>
                                              <div className={styles.notification_date}>
                                                  <Typography variant="subtitle2">{dayjs(notification.notification_start_time).format("DD/MM/YYYY")}</Typography>
                                              </div>
                                              <AlertTitle>
                                                  <Typography variant="h2">{notification.notification_title}</Typography>
                                              </AlertTitle>
                                              <Typography variant="body2">{notification.notification_message}</Typography>
                                          </Alert>
                                      </div>
                                  </Grow>
                              ))
                        : notificationsList
                              .filter((n) => !n.viewed)
                              .sort((a, b) => (Number(a.notification_priority) > Number(b.notification_priority) ? 1 : -1))
                              .map((notification, i) => (
                                  <Grow in={open} key={notification.notification_id} style={{ transformOrigin: "0 0 0" }} {...(open ? { timeout: 500 * (i + 1) } : {})}>
                                      <div style={{ marginTop: 5, marginBottom: 5 }}>
                                          <Alert variant="standard" severity={IconColor(notification.notification_type)}>
                                              <div className={styles.notification_date}>
                                                  <Typography variant="caption">{dayjs(notification.notification_start_time).format("DD/MM/YYYY")}</Typography>
                                              </div>
                                              <AlertTitle>
                                                  <Typography variant="h2">{notification.notification_title}</Typography>
                                              </AlertTitle>
                                              <Typography variant="body2">{notification.notification_message}</Typography>
                                          </Alert>
                                      </div>
                                  </Grow>
                              ))}
                    {!showReadNotifications && !notificationsList.filter((n) => !n.viewed).length && (
                        <Alert severity="success" sx={{ minWidth: "200px" }}>
                            No new notifications
                        </Alert>
                    )}
                    {showReadNotifications && !notificationsList.filter((n) => n.viewed).length && (
                        <Alert severity="success" sx={{ minWidth: "200px" }}>
                            No read notifications
                        </Alert>
                    )}
                    <Button
                        variant="text"
                        size="small"
                        className={styles.readNotificationsButton}
                        sx={[
                            {
                                color: textColor,
                                "&:hover": {
                                    color: ortexColor,
                                },
                            },
                        ]}
                        // sx={{ color: textColor }}
                        onClick={() => setShowReadNotifications(!showReadNotifications)}
                    >
                        <Typography variant="overline">{showReadNotifications ? "Hide" : "See"} read notifications</Typography>
                    </Button>
                </Box>

                {/* <Divider sx={{ backgroundColor: dividerColor }} /> */}
            </Popover>
        </div>
    );
}
