const sections = [
    { icon: "fak fa-markets-overview", text: "Market Overview", title: "Market Overview", value: "/market-overview" },
    { icon: "fak fa-my-universe", text: "My Universe", title: "My Universe", value: "/my-universe" },
    { icon: "fak fa-portfolio", text: "Portfolio", title: "Portfolio", value: "/portfolio" },
    { icon: "fak fa-charts", text: "Charts", title: "Charts", value: "/charts" },
    { icon: "fal fa-newspaper", text: "News", title: "News", value: "/news" },
    { icon: "fal fa-calendar-alt", text: "Full event and earnings calendar", title: "Events", value: "/events" },
    { icon: "fal fa-chart-line-down", text: "Shorts", title: "Shorts", value: "/short-interest" },
    { icon: "fal fa-user-tie", text: "Analysts", title: "Analysts", value: "/analysts" },
    { icon: "fal fa-list-ol", text: "Holdings", title: "Holdings", value: "/holdings" },
    { icon: "fal fa-users", text: "Insiders", title: "Insiders", value: "/insiders" },
    { icon: "fal fa-globe-europe", text: "Macro", title: "Macro", value: "/macro" },
    { icon: "fak fa-ipos-deals", text: "IPO's + Deals", title: "IPO's + Deals", value: "/ipo-deals" },
    { icon: "fak fa-stock-trading-signals", text: "Trading Signals", title: "Trading Signals", value: "/trading-signals" },
    { icon: "fak fa-stock-scores", text: "Stock Scores", title: "Stock Scores", value: "/stock-scores" },
    { icon: "fak fa-custom-views", text: "Custom Views", title: "Custom Views", value: "/custom-views" },
    { icon: "fal fa-chart-pie-alt", text: "Dividend", title: "Dividend", value: "/dividend" },
    { icon: "fak fa-stock-stamps", text: "Stock Stamps", title: "Stock Stamps", value: "/stock-stamps" },
    { icon: "fak fa-screening", text: "Screening", title: "Screening", value: "/screening" },
    { icon: "fak fa-stock-options", text: "Stock Options", title: "Options", value: "/options" },
    { icon: "fak fa-crypto-currencies", text: "Criptocurrencies", title: "Cryptocurrencies", value: "/cryptocurrencies" },
    { icon: "fak fa-etfs", text: "ETF's", title: "ETF's", value: "/etfs" },
    { icon: "fal fa-university", text: "Market Indexes", title: "Indexes", value: "/indexes" },
    { icon: "fak fa-commodities", text: "Commodities", title: "Commodities", value: "/commodities" },
    { icon: "fal fa-coins", text: "Currencies", title: "Currencies", value: "/currencies" },
];

export default sections;
