import { classNames } from "@/lib/utils";
import styles from "./BrandLoading.module.scss";
import { useRecoilValue } from "recoil";
import { blackState, darkerBackgroundColorState, themeState, userDataState } from "@/lib/store";
import { isUndefined } from "lodash";

const BrandLoading = (props) => {
    const black = useRecoilValue(blackState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const theme = useRecoilValue(themeState);

    return (
        <div
            className={classNames(styles[(props.pathname.includes("/s/") || props.pathname.includes("/id")) && "stock-page"], styles.BrandLoading)}
            style={{ backgroundColor: isUndefined(theme) ? "#FFF" : props.stock && theme === "dark" ? black : darkerBackgroundColor }}
        >
            <div className={styles.loader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className={styles["icon-container"]}>
                    <i aria-hidden className={"fak fa-ortex-logo"} />
                </span>

                <span className={styles["icon-container"]}>
                    <i aria-hidden className={"fak fa-ortex-logo"} />
                </span>
            </div>
        </div>
    );
};

export default BrandLoading;
