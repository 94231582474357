import NextLink from "next/link";
import Image from "next/image";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./CognitoLogin.module.scss";

import { Authenticator, Theme, ThemeProvider, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { darkDefaultState, dividerState, ortexColorState, textColorState, themeState } from "@/lib/store";

import React, { useEffect, useState } from "react";
import { signIn } from "aws-amplify/auth";
import { SignInInput, SignInOutput } from "@aws-amplify/auth";
import axios from "axios";
import { classNames } from "@/lib/utils";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import SocialAuthButtons from "../social-auth-buttons/SocialAuthButtons";
import { Divider } from "@mui/material";
import { isOrtexApp } from "@/lib/utils";

let reloadTimeout: NodeJS.Timeout;

export async function signOutFromBackend(vertexUser, ortexUser) {
    try {
        const vertexUrl = `${process.env.NEXT_PUBLIC_API_URL_VERTEX}/logout`;
        const ortexUrl = `${process.env.NEXT_PUBLIC_API_URL}/logout`;

        const ortexPost = axios.post(ortexUrl, ortexUser, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: null,
        });
        const vertexPost = axios.post(vertexUrl, vertexUser, {
            withCredentials: true,
            xsrfCookieName: "vertex_csrftoken",
            headers: {
                "Content-Type": "application/json",
            },
            params: null,
        });

        const [ortexResponse, vertexResponse] = await Promise.all([ortexPost, vertexPost]);
    } catch (error) {
        console.error("Error signing out from backend:", error);
    }
}

function CustomAuthenticator({ register = false }) {
    const { reload } = useRouter();
    const { route } = useAuthenticator((context) => [context.route]);
    const dividerColor = useRecoilValue(dividerState);

    const services = {
        async handleSignIn(input: SignInInput): Promise<SignInOutput> {
            let { username, password, options } = input;
            const customUsername = username.toLowerCase();

            try {
                const { isSignedIn, nextStep } = await signIn({
                    username: customUsername,
                    password,
                    options: {
                        ...options,
                        authFlowType: "USER_PASSWORD_AUTH",
                    },
                });

                return { isSignedIn, nextStep };
            } catch (error) {
                toast.error("The username or password you entered is incorrect. Please use Apple or Google to sign in, or choose 'Forgot Password' to reset it.");

                reloadTimeout = setTimeout(() => {
                    reload();
                }, 3000);
            }
        },
    };

    return (
        <Box>
            <Authenticator socialProviders={isOrtexApp() ? [] : ["google", "apple"]} services={services} initialState={register ? "signUp" : "signIn"} signUpAttributes={["given_name", "family_name", "email"]} />

            {route === "signIn" && isOrtexApp() && (
                <>
                    <Divider flexItem sx={{ width: "300px", mx: "auto", mb: 5, backgroundColor: dividerColor }} />
                    <SocialAuthButtons />
                </>
            )}
        </Box>
    );
}

interface CognitoProps {
    register?: boolean;
}

const CognitoLogin = ({ register }: CognitoProps) => {
    const darkDefaultColor = useRecoilValue(darkDefaultState);
    const ortexColor = useRecoilValue(ortexColorState);
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);

    const [vertexUser, setVertexUser] = useState(null);

    useEffect(() => {
        setVertexUser(localStorage.getItem("vertexUser"));
    }, []);

    const { tokens } = useTheme();
    const authTheme: Theme = {
        name: "Auth Theme",
        tokens: {
            components: {
                authenticator: {
                    router: {
                        borderWidth: "0",
                        backgroundColor: "transparent",
                    },
                    form: {
                        padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
                    },
                },
                button: {
                    primary: {
                        backgroundColor: ortexColor,
                        _hover: {
                            backgroundColor: "rgb(15, 82, 74)",
                        },
                    },
                    link: {
                        color: ortexColor,
                    },
                },
                fieldcontrol: {
                    _focus: {
                        boxShadow: `0 0 0 2px ${ortexColor}`,
                    },
                },
                tabs: {
                    item: {
                        color: tokens.colors.neutral["80"],
                        _active: {
                            borderColor: ortexColor,
                            color: ortexColor,
                        },
                    },
                },
            },
        },
        overrides: [
            {
                colorMode: "light",
                tokens: {
                    colors: {
                        border: {
                            primary: { value: ortexColor },
                        },
                    },
                },
            },
            {
                colorMode: "dark",
                tokens: {
                    colors: {
                        font: {
                            primary: { value: textColor },
                            secondary: { value: textColor },
                            tertiary: { value: "grey" },
                        },
                    },
                },
            },
        ],
    };

    return (
        <Box className={classNames(styles.SignInForm)}>
            {/* <Box className={classNames(styles.SignInForm, vertexUser ? "" : styles.Loading)}> */}
            <style>
                {`
            .amplify-divider::after {
                background-color: ${theme === "dark" && darkDefaultColor};
            }
            .federated-sign-in-container {
                .amplify-button:hover {
                    .amplify-text {
                        color: black;
                    }
                }
            }
            `}
            </style>
            <Box
                className={styles["img-container"]}
                sx={{
                    paddingTop: 2,
                    marginLeft: 2,
                }}
            >
                <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo" width={150} height={30} priority layout="responsive" />
            </Box>
            <Box className={styles.authContainer}>
                <ThemeProvider theme={authTheme} colorMode={theme}>
                    <CustomAuthenticator register={register} />
                </ThemeProvider>
            </Box>
            <Box className={styles.TC}>
                <Typography variant="minorSubtitle" sx={{ marginBottom: 2 }}>
                    IMPORTANT: The projections or other information generated by ORTEX’s tools regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of
                    future results. The above calculations do not take into consideration all costs, such as commissions and margin interest which may impact the results shown. It is the user’s sole responsibility to select the criteria to enter in
                    the ORTEX’s tools, or to choose among the pre-defined screens, and to evaluate the merits and risks associated with the use of the tools before making any investment decisions. ORTEX is not responsible for any losses that occur
                    from such investment decisions. All information is provided on an "as is," "as available" basis without warranty of any kind, and neither ORTEX nor its Service and Information Providers make any representations as to the
                    suitability of the information for any purpose, nor to its accuracy, timeliness, completeness or usefulness. Use of the service is at the user’s own risk. ORTEX reserves the right, in its sole discretion and without any
                    obligation, to modify, improve, discontinue or correct any errors or omissions in any portion of the site at any times. Your continued use of this service indicates your acceptance of the terms of our disclosures{" "}
                    <NextLink href="https://public.ortex.com/terms-and-conditions" passHref>
                        <span style={{ color: ortexColor, textDecoration: "none", cursor: "pointer" }}>Terms and Conditions</span>
                    </NextLink>
                    .
                </Typography>
            </Box>
        </Box>
    );
};

export default CognitoLogin;
