import { AnalsystsLastWeekHeaders, AnalsystsTargetPriceHeaders, AnalystsLastWeek, AnalystsTargetPrice } from "@/models/analysts";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";

export const getAnalystsRecent = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id, signal } = params;

    const url = next_page_url || `/API/v2/${universe_id}/analyst/list/recent_changes?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<AnalystsTargetPrice, AnalsystsTargetPriceHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getAnalystsLastWeek = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id, signal } = params;

    const url = next_page_url || `/API/v2/${universe_id}/analyst/list/consensus_changes?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<AnalystsLastWeek, AnalsystsLastWeekHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getAnalystsTargetPrice = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id, signal, data } = params;
    const url = next_page_url || `/API/v2/${universe_id}/analyst/list/tp_diff?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data };

    try {
        const res = await axios.get<List<AnalystsTargetPrice, AnalsystsTargetPriceHeaders>>(url, {
            params: { ...defaultParams },
            signal,
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};
