import { useState, useRef, useEffect } from "react";
// import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { getMacroEventsByMonth } from "@/controllers/events";
import { Box, Chip, Tooltip, styled, useMediaQuery } from "@mui/material";
import styles from "./MacroEventsCalendar.module.scss";
import { useRecoilValue } from "recoil";
import { dividerState, positiveState, textColorState, themeState } from "@/lib/store";
import { classNames } from "@/lib/utils";
import { DayCalendarSkeleton, StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const currentDate = dayjs.utc();

const StyledStaticDatePicker = styled((props: StaticDatePickerProps<Dayjs>) => <StaticDatePicker {...props} />)(() => ({
    ".MuiCalendarOrClockPicker-root > div": {
        width: "100%",
    },

    ".MuiCalendarPicker-root": {
        width: "100%",
    },

    ".MuiPickerStaticWrapper-content": {
        backgroundColor: "transparent",
    },

    ".MuiDayPicker-weekDayLabel": {
        width: "calc(100% / 7)",
    },

    ".MuiYearCalendar-root": {
        width: "100%",
    },

    ".PrivatePickersYear-root": {
        flexBasis: "calc(100% / 4)",
    },
}));

const MacroEventsCalendar = ({ countries, setEventsList }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedDay, setHighlightedDay] = useState(currentDate);
    const [events, setEvents] = useState([]);
    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const theme = useRecoilValue(themeState);
    const controller = useRef<AbortController>();
    const isNotMobile = useMediaQuery("(min-width:768px)");

    function ServerDay(props: PickersDayProps<Dayjs>) {
        const { day, outsideCurrentMonth, ...other } = props;

        const eventsPerDay = events.filter((event) => dayjs.utc(event[0]).isSame(dayjs.utc(day), "day"));

        const color = highlightedDay.isSame(dayjs.utc(day), "day") && theme !== "dark" && dividerColor;
        const backgroundColor = highlightedDay.isSame(dayjs.utc(day), "day") && "#006edc";

        return (
            <Box
                className={styles.calendarDay}
                sx={{
                    backgroundColor,
                    color,
                }}
                onClick={() => setHighlightedDay(dayjs.utc(day))}
            >
                <p className={outsideCurrentMonth ? styles.outsideCurrentMonth : undefined}>{dayjs.utc(day).format("D")}</p>
                {eventsPerDay.length !== 0 &&
                    !["Su", "Sa"].includes(day.format("dd")) &&
                    !outsideCurrentMonth &&
                    (isNotMobile ? (
                        <Chip className={styles.eventsChip} sx={{ color }} size="small" label={`${eventsPerDay.length} Events`} variant="outlined" />
                    ) : (
                        <Tooltip title={`${eventsPerDay.length} Events`}>
                            <Chip className={styles.eventsChip} sx={{ color }} size="small" label={`${eventsPerDay.length}`} variant="outlined" />
                        </Tooltip>
                    ))}
            </Box>
        );
    }

    const handleMonthChange = (month) => {
        controller.current?.abort();
        controller.current = new AbortController();
        setIsLoading(true);
        getMacroEventsByMonth({ start: 0, length: 2000, month_of_year: month.format("MMYYYY"), countries, signal: controller.current.signal }).then((res) => {
            setIsLoading(false);
            if (res) {
                setEvents(res.rows);
            }
        });

        if (dayjs.utc(month).isSame(dayjs.utc(currentDate), "month")) {
            setHighlightedDay(currentDate);
        } else {
            let date = dayjs.utc(month).startOf("month");

            if (date.day() === 6) {
                date = date.add(2, "day");
            } else if (date.day() === 0) {
                date = date.add(1, "day");
            }

            setHighlightedDay(date);
        }
    };

    useEffect(() => {
        const dayEvents = events
            .filter((event) => dayjs.utc(event[0]).isSame(dayjs.utc(highlightedDay), "day"))
            .map((e) => {
                return {
                    date: dayjs.utc(highlightedDay).format("DD-MM"),
                    eventName: e[1],
                    country: e[7],
                    type: e[8],
                };
            });
        dayEvents && setEventsList(dayEvents);
    }, [events, highlightedDay]);

    useEffect(() => {
        handleMonthChange(currentDate);
    }, [countries]);

    return (
        <div className={classNames(styles.MacroEventsCalendar, styles[theme])}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <StyledStaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={currentDate}
                    onChange={(newValue) => null}
                    className={classNames(styles.calendar, styles[theme])}
                    loading={isLoading}
                    sx={{
                        border: "1px solid blue",
                        width: "100%",
                        ".MuiDateCalendar-root": {
                            width: "100%",
                        },
                        ".MuiDayCalendar-header": {
                            justifyContent: "space-around",
                        },
                    }}
                    onMonthChange={handleMonthChange}
                    onYearChange={handleMonthChange}
                    renderLoading={() => (
                        <DayCalendarSkeleton
                            sx={{
                                width: "100%",

                                "& .MuiDayCalendarSkeleton-week": {
                                    justifyContent: "space-around",

                                    ".MuiDayCalendarSkeleton-daySkeleton": {
                                        width: "calc(100% / 7) !important",
                                        borderRadius: "0 !important",
                                    },
                                },
                            }}
                        />
                    )}
                    showDaysOutsideCurrentMonth
                    dayOfWeekFormatter={(day) => day}
                    slots={{ day: (DayProps) => <ServerDay {...DayProps} /> }}
                />
            </LocalizationProvider>
        </div>
    );
};

export default MacroEventsCalendar;
