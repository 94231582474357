"use client";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "./ErrorFallback.module.scss";
import { Box, Button, Typography } from "@mui/material";
import Link from "next/link";
import { useErrorBoundary } from "react-error-boundary";

interface Props {
    isErrorPage?: boolean;
}

const ErrorFallback = ({ isErrorPage }: Props) => {
    const { resetBoundary } = useErrorBoundary();

    return (
        <div className={styles.fallback}>
            <Box className={styles.container}>
                <ErrorOutlineIcon style={{ fontSize: 150 }} />
                <Typography variant="h4" sx={{ mt: 4 }}>
                    Something went wrong
                </Typography>
                <Typography variant="body1">Please refresh the page or try again later</Typography>
                <Box className={styles.actions}>
                    {!isErrorPage && (
                        <Button variant="outlined" sx={{ mt: 2 }} onClick={resetBoundary}>
                            Try again
                        </Button>
                    )}
                    <Button variant="outlined" sx={{ mt: 2, ml: !isErrorPage && 1 }}>
                        <Link href="/" passHref>
                            <Typography variant="body1" component="span" sx={{ color: "primary.main", fontSize: "inherit" }}>
                                Go home
                            </Typography>
                        </Link>
                    </Button>
                </Box>
            </Box>
            <Typography variant="body1">
                If the problem persists, please contact us at&nbsp;
                <Link href="mailto:sales@ortex.com" passHref>
                    <Typography variant="body1" component="span" sx={{ color: "primary.main", cursor: "pointer" }}>
                        support@ortex.com
                    </Typography>
                </Link>
            </Typography>
        </div>
    );
};

export default ErrorFallback;
