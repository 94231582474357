import { useRecoilValue } from "recoil";
import styles from "./StockheaderInfo.module.scss";
import { themeState, textColorState } from "@/lib/store";
import Image from "next/image";
import { Chip, Typography } from "@mui/material";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Context } from "@/lib/Context";
import { Stock } from "@/models/stock";
import { classNames, priceDiffPercentage } from "@/lib/utils";
import { getMissingLogo } from "@/controllers/stock";
import dayjs from "dayjs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import noBackgroundStocks from "@/models/noBackgroundStocks.json";

const GB_CURRENCIES = ["GBP", "GBX"];

const StockHeaderInfo: FC<{ stock: Stock }> = ({ stock }) => {
    const { obookWebSocket, ortexWebSocket } = useContext(Context);

    const [imgError, setImgError] = useState(false);
    const [price, setPrice] = useState(undefined);
    const [session, setSession] = useState<string | null>("Market Closed");
    const [blankImg, setBlankImg] = useState<boolean>(false);

    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);

    const isLiveFeed = stock?.livePriceFeed;

    const [GBPIdentifier, GBXIdentifier] = !isLiveFeed ? [`${stock?.isin}GBP`, `${stock?.isin}GBX`] : [];

    const [isGBCurrency, setIsGBCurrency] = useState(GB_CURRENCIES.includes(stock?.currency));

    useEffect(() => {
        setIsGBCurrency(GB_CURRENCIES.includes(stock?.currency));
    }, [stock.currency]);

    const websocket = isLiveFeed === "NA" ? obookWebSocket : isLiveFeed === "EU" ? ortexWebSocket : null;

    const tsMessage = isLiveFeed === "NA" ? { type: "last_trades_history_w_ticker", identifier: stock.ticker } : isLiveFeed === "EU" ? { type: "last_trades_history_w_ticker", identifier: `${stock.isin}${stock.currency}` } : null;
    const priceMessage = isLiveFeed === "NA" ? { type: "subscribe", identifier: stock.ticker } : isLiveFeed === "EU" ? { type: "subscribe", identifier: `${stock.isin}${stock.currency}` } : null;

    const diffPercentage = isFinite(parseFloat(price)) ? priceDiffPercentage(stock.last_close, parseFloat(price)) : NaN;

    const closeDifference = Number(price) - stock.last_close;
    const isNegative = closeDifference < 0;
    const sign = isNegative ? "" : "+";

    const sendMessage = useCallback(() => {
        if (isLiveFeed === "EU" && GB_CURRENCIES.includes(stock?.currency)) {
            websocket.send(JSON.stringify({ type: "last_trades_history_w_ticker", identifier: GBPIdentifier }));
            websocket.send(JSON.stringify({ type: "last_trades_history_w_ticker", identifier: GBXIdentifier }));

            websocket.send(JSON.stringify({ type: "subscribe", identifier: GBPIdentifier }));
            websocket.send(JSON.stringify({ type: "subscribe", identifier: GBXIdentifier }));
        } else if (isLiveFeed === "NA") {
            websocket.send(JSON.stringify(tsMessage));
            websocket.send(JSON.stringify(priceMessage));
        }
    }, [stock, isLiveFeed, websocket, isGBCurrency]);

    const updateData = useCallback(
        ({ data }: MessageEvent) => {
            const { ISIN, LT, T, "ts-last": tsLast, ticker } = JSON.parse(data);
            const identifiers = [stock.ticker, stock.isin + stock.currency, GBPIdentifier, GBXIdentifier];

            if (T?.i && identifiers.includes(T.i)) {
                const newPrice = T.C.c.toFixed?.(2);

                if (!price || Math.abs(parseFloat(price) - newPrice) > 0.01) {
                    setPrice(newPrice);
                }
            } else if (tsLast && ISIN === stock.isin) {
                const newPrice = tsLast[1]?.toFixed?.(2);

                if (!price || Math.abs(parseFloat(price) - newPrice) > 0.01) {
                    setPrice(newPrice);
                }
            } else if (LT?.[LT.length - 1] && identifiers.includes(ticker)) {
                const newPrice = LT[LT.length - 1][0].c.toFixed?.(2);

                if (!price || Math.abs(parseFloat(price) - newPrice) > 0.01) {
                    setPrice(newPrice);
                }
            }
        },
        [stock.stock_id, websocket, isGBCurrency]
    );

    useEffect(() => {
        setPrice(undefined);

        if (isLiveFeed && websocket) {
            websocket.readyState === 1 ? sendMessage() : websocket.addEventListener("open", sendMessage);
            websocket.addEventListener("message", updateData);
        }

        return () => {
            if (isLiveFeed && websocket) {
                websocket.removeEventListener("open", sendMessage);
                websocket.removeEventListener("message", updateData);
                if (websocket.readyState === WebSocket.OPEN) {
                    if (isGBCurrency) {
                        websocket.send(JSON.stringify({ ...priceMessage, type: "unsubscribe", identifier: GBPIdentifier }));
                        websocket.send(JSON.stringify({ ...priceMessage, type: "unsubscribe", identifier: GBXIdentifier }));
                    } else websocket.send(JSON.stringify({ ...priceMessage, type: "unsubscribe", identifier: priceMessage.identifier }));
                }
            }
        };
    }, [stock.stock_id, isLiveFeed, websocket]);

    useEffect(() => {
        ReactTooltip.rebuild();

        return () => setImgError(false);
    }, []);

    const handleImgError = (security_id: number) => {
        setImgError((state) => (state = true));
        if (security_id) getMissingLogo(security_id);
    };

    const handleImgLoad = (event) => {
        const width = event.target.naturalWidth;
        setBlankImg(width <= 1 ? true : false);
    };

    useEffect(() => {
        if (stock.exchange_tz && stock.exchange_th) {
            const currentDay = dayjs().tz(stock.exchange_tz.trimEnd());
            const trading_day = dayjs(stock.trading_day).tz(stock.exchange_tz.trimEnd()).utc().format("DD");
            const trading_day_next = dayjs(stock.trading_day_next).tz(stock.exchange_tz.trimEnd()).utc().format("DD");
            const currentTime: number = parseInt(`${currentDay.get("hour")}${currentDay.format("mm")}`);
            let exchangeTime: number[] = stock.exchange_th
                ?.split(",")
                .join("-")
                .split("-")
                .map((item) => {
                    return parseInt(item);
                });
            if (!stock.trading_day && !stock.trading_day_next) setSession(null);
            else if (currentDay.format("DD") === trading_day || currentDay.format("DD") === trading_day_next) {
                if (exchangeTime.length === 2) {
                    if (currentTime >= exchangeTime[0] && currentTime < exchangeTime[1]) {
                        setSession("Main session");
                    }
                } else {
                    if (currentTime >= exchangeTime[0] && currentTime < exchangeTime[1]) {
                        setSession("Premarket");
                    } else if (currentTime >= exchangeTime[2] && currentTime < exchangeTime[3]) {
                        setSession("Main session");
                    } else if (currentTime >= exchangeTime[4] && currentTime < exchangeTime[5]) {
                        setSession("After hours");
                    } else {
                        setSession("Market Closed");
                    }
                }
            } else {
                setSession("Market Closed");
            }
        } else {
            setSession(null);
        }
    }, [stock]);

    return (
        <div className={styles.StockLinksStock}>
            <div className={styles.StockLinksLogo} style={{ backgroundColor: theme === "dark" && !blankImg && !noBackgroundStocks.includes(stock.ticker) && !noBackgroundStocks.includes(stock.name) ? "#c0c0c0" : "transparent", borderRadius: "4px" }}>
                {!imgError && (
                    <Image
                        unoptimized
                        src={stock.security_id ? `https://logo.ortex.com/${stock.security_id}r.png` : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}
                        alt={`logo`}
                        layout="fill"
                        objectFit="contain"
                        onError={() => handleImgError(stock.security_id)}
                        onLoad={handleImgLoad}
                    />
                )}
            </div>

            {session !== null && <Chip size="small" variant="outlined" label={<Typography variant="captionSessionChip">{session}</Typography>} sx={{ mx: 2 }} icon={<AccessTimeIcon sx={{ fill: textColor }} />} />}

            <div className={styles.StockLinkInfo}>
                <span className={classNames(styles.StockLinkInfoHeader, diffPercentage < 0 ? "negative" : "positive")}>{price || "N/A"}</span>
                <span className={classNames(styles.StockLinkInfoSubHeader, diffPercentage < 0 ? "negative" : "positive")}>
                    {isFinite(closeDifference) ? sign + closeDifference.toFixed(2) : "N/A"} ({isFinite(diffPercentage) ? diffPercentage.toFixed(2) : "N/A"}%)
                </span>
            </div>
        </div>
    );
};
export default StockHeaderInfo;
