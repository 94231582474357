import { NotificationsMeasure, NotificationsTemplate } from "@/models/notifications";
import { ListQueryParams } from "@/models/table";
import axios from "axios";

export const getNotificationsTemplates = async () => {
    try {
        const res = await axios.get<NotificationsTemplate[]>("/API/v2/notifications/templates");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsMeasures = async (family) => {
    try {
        const res = await axios.get<NotificationsMeasure[]>(`/API/v2/notifications/measures_references?family=${family}`, {
            params: {
                authid: undefined,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getNotificationsSubscriptions = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve([
                {
                    subsId: 1,
                    userId: 368211,
                    template_code_name: "short_measure_stock",
                    triggerData: {
                        stock_id: 21830,
                        alert_rules: {
                            change_pct: 2,
                            over_days: 65,
                        },
                        short_measure_reference_id: "8",
                    },
                    triggerDescription: "New subscription test",
                    trigger_wait_hours: 0,
                    active_status: true,
                    alertDestination: "System",
                    dateCreated: 1666287798000,
                },
                {
                    subsId: 34,
                    userId: 368211,
                    template_code_name: "short_measure_universe",
                    triggerData: {
                        alert_rules: {
                            change_pct: 2,
                            over_days: 32,
                        },
                        universe_id: 16,
                        universe_average: false,
                        short_measure_reference_id: "8",
                    },
                    triggerDescription: "Universe subscription",
                    trigger_wait_hours: 0,
                    active_status: true,
                    alertDestination: "Email",
                    dateCreated: 1666287798000,
                },
            ]);
            return res;
        }
        const res = await axios.get("/API/v2/notifications/subscriptions", {
            params: {
                authid: undefined,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getAlertsDestinations = async () => {
    try {
        const res = await axios.get(`/interface/api/event_alerts/destinations`);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const updateNotificationSubscription = async (subscription: any) => {
    try {
        const res = await axios.put("/API/v2/notifications/subscriptions", subscription, {
            params: {
                authid: undefined,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const createNotificationSubscription = async (subscription: any) => {
    try {
        const res = await axios.post("/API/v2/notifications/subscriptions", subscription, {
            params: {
                authid: undefined,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getUserAlerts = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url } = params;

    const url = next_page_url || `/API/v2/notifications/useralerts/list?&dgformat`;
    const defaultParams = next_page_url ? {} : { length: page_size, page, ...filter_options, authid: undefined };

    try {
        const res = await axios.get(url, {
            params: {
                ...defaultParams,
            },
        });
        {
            if (res.data.obfuscated) {
                delete res.data.obfuscated;
            }
            return res.data;
        }
    } catch (err) {
        throw err;
    }
};

export const getUserAlert = async (id: number) => {
    try {
        const res = await axios.get(`/API/v2/notifications/useralerts/${id}`);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getNotifications = async (page, stock) => {
    const params = stock && page ? { page, tradingitem_id: stock } : stock ? { tradingitem_id: stock } : page ? { page } : {};

    try {
        const res = await axios.get("/interface/api/notifications", {
            params,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const notificationsViewed = async (id: number) => {
    try {
        const res = await axios.post(`/interface/api/notifications/${id}`);

        return res;
    } catch (err) {
        throw err;
    }
};
