import styles from "./PaymentPlans.module.scss";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { backgroundColorState, darkerBackgroundColorState, greyLighterState, lighterBackgroundColorState, textColorState, themeState, userDataState } from "@/lib/store";
import { Plan } from "@/models/subscription";
import { plansFeatures } from "@/lib/vars";
import { Button, ButtonGroup, Card, CardActionArea, CardContent, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import VerifiedIcon from "@mui/icons-material/Verified";
import AddIcon from "@mui/icons-material/Add";
import { useUpdateEffect } from "react-use";

interface PropsTypes {
    interval: "annually" | "monthly";
    plan: Plan;
    plans: Plan[];
    setInterval: Dispatch<SetStateAction<"annually" | "monthly">>;
    setPlan: Dispatch<SetStateAction<Plan>>;
    setStep: Dispatch<SetStateAction<number>>;
    onClose: (close: boolean) => void;
}

const PaymentPlans: FC<PropsTypes> = ({ interval, plan: selectedPlan, plans, setInterval, setPlan, setStep, onClose }) => {
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const backgroundColor = useRecoilValue(backgroundColorState);

    const userData = useRecoilValue(userDataState);
    const textColor = useRecoilValue(textColorState);
    const greyLighter = useRecoilValue(greyLighterState);
    const theme = useRecoilValue(themeState);
    const grey = theme === "dark" ? "#939598" : "#808080";

    useUpdateEffect(() => setPlan(plans.find((plan) => plan.product_name === selectedPlan?.product_name && plan.plan_interval === interval)), [interval]);

    return (
        <Box className={styles.PaymentPlansPage}>
            <style jsx>{`
                button {
                    color: ${textColor};
                }
            `}</style>
            <ButtonGroup>
                <Button fullWidth={true} onClick={() => setInterval("annually")} variant={interval === "annually" ? "contained" : "outlined"} sx={{ textTransform: "initial", color: textColor, fontSize: "16px" }}>
                    Yearly
                </Button>
                <Button fullWidth={true} onClick={() => setInterval("monthly")} variant={interval === "annually" ? "outlined" : "contained"} sx={{ textTransform: "initial", color: textColor, fontSize: "16px" }}>
                    Monthly
                </Button>
            </ButtonGroup>

            <Box className={styles["plan-selector"]}>
                {plans
                    .filter((plan) => plan.plan_interval === interval)
                    .map((plan) => {
                        const disabled = userData?.subscription_plan === "ORTEX Basic" && plan.product_name === "ORTEX Basic";
                        return (
                            <Card
                                key={plan.plan_id}
                                sx={{
                                    border: plan.plan_id === selectedPlan?.plan_id ? `2px solid #31aba6` : `2px solid ${theme === "light" ? lighterBackgroundColor : backgroundColor}`,
                                    "&:hover": { backgroundColor: darkerBackgroundColor, borderColor: plan.plan_id !== selectedPlan?.plan_id && darkerBackgroundColor, cursor: "pointer" },
                                    padding: 1,
                                }}
                                elevation={3}
                                className={styles.card}
                                onClick={() => setPlan(plan)}
                            >
                                <CardContent sx={{ height: "100%" }}>
                                    <Box>
                                        <Typography variant="body1" component="span" className={styles.name}>
                                            <Typography variant="h1" component="span">
                                                {plan.product_name}
                                            </Typography>
                                            {disabled && (
                                                <Typography variant="body1" component="span" sx={{ color: grey }}>
                                                    Your current plan
                                                </Typography>
                                            )}
                                        </Typography>

                                        <Box className={styles.features}>
                                            {plan.product_name.includes("Advanced") && (
                                                <Typography variant="body1" component="span">
                                                    Everything in Basic
                                                    <Typography variant="body1" component="span" style={{ marginLeft: 5 }}>
                                                        <AddIcon color={"primary"} fontSize="medium" />
                                                    </Typography>
                                                </Typography>
                                            )}
                                            {(plansFeatures[plan.product_name] || plansFeatures["ORTEX Basics"]).map((feature) => (
                                                <Typography variant="body1" component="span" key={feature.text}>
                                                    <VerifiedIcon color={"primary"} fontSize="small" sx={{ marginRight: "5px" }} />
                                                    {feature.text}
                                                </Typography>
                                            ))}
                                        </Box>
                                        <Divider className={styles.line} sx={{ borderColor: greyLighter }} />

                                        <Typography variant="body1" component="span" sx={{ fontSize: "22px" }}>
                                            {plan.currency_symbol} {interval === "annually" ? Math.round(plan.plan_price / 12) : plan.plan_price}
                                            <Typography variant="body1" component="span" sx={{ color: grey, fontSize: "14px", alignSelf: "flex-end " }}>
                                                /month
                                            </Typography>
                                        </Typography>

                                        <Typography variant="body1" component="span" sx={{ color: grey, fontSize: "14px" }}>
                                            {interval === "annually" ? `Paid anually at ${plan.currency_symbol} ${plan.plan_price}` : "\u00A0"}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
            </Box>
            {userData?.subscription_plan === "ORTEX Free" && (
                <Box className={styles["free-account"]}>
                    <Typography variant="body1" component="p">
                        Or continue with your{" "}
                        <Typography variant="body1" component="span" onClick={() => onClose(false)}>
                            free account
                        </Typography>
                    </Typography>
                </Box>
            )}
            <Button className={styles.btn} variant="contained" sx={{ textTransform: "initial" }} onClick={() => setStep(2)}>
                Checkout
            </Button>
        </Box>
    );
};

export default PaymentPlans;
