import axios from "axios";
import { TopBarInstrument } from "@/models/instruments";

export const getTopBarValues = async () => {
    try {
    const res = await axios.get<{ [key: string]: TopBarInstrument }>("/API/v2/top_bar_values")
        
            return res.data;
        }
        catch(err) {
            throw err;
        };
};
